import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Map } from 'immutable';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EMPTY_ROW, renderCell, TableRowDropDown } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import {
  SubTasksList,
  getRowHeightStagesView,
  calculateInnerCellHeight,
  TaskNameContainer,
} from '@tradetrax/web-common/lib/Stages';
import {
  DragNDropController,
  DraggableArea,
  rowOrderMemo,
  defaultRowRenderer,
} from '@tradetrax/web-common/lib/Stages/DragNDrop';
import { emptyState, getPredecessorsString, mapStagesViewModel } from '../TemplateDetailsContext';
import { TaskDependencies } from '../../TaskDependencies';
import { TaskDurationInput } from '../../../Job/TaskDurationInput';
import {
  InnerTasksList,
  InnerDurationList,
  InnerPredecessorsList,
  InnerPreconstructionList,
  InnerMultiFamilyList,
  InnerDropDownActions,
} from './RowsContent';
import { getWidth } from '../TemplateTasks';
import { MultiFamilyHeaderIcon } from '@tradetrax/web-common/lib/Popover/MultiFamily.icons.popover';
import { EventHandlers } from '../EventHandlers.shared';

const hasMultiFamilyTasks = template => {
  return template.get('tasks').some(t => t.get('isMultiFamily'));
};

export function TemplateStages({ context }) {
  const { state, controller, modal, tableRef } = context;
  const { template, expandedRows } = state.toObject();
  const [editingRow, setEditingRow] = useState(null);
  const [defaultEditField, setDefaultEditField] = useState(null);
  const viewModel = React.useMemo(() => mapStagesViewModel(template), [template]);
  const { controller: dndController } = DragNDropController(
    tableRef,
    controller.updateTaskOrder,
    controller,
    viewModel
  );
  const totalCount = template === emptyState.get('template') ? template.get('tasksTotalCount') : viewModel.size;
  const isEmpty = totalCount === 0;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const durationWidth = getWidth(windowWidth, 80, 160, 200);
  const preConstructionWidth = getWidth(windowWidth, 80, 160, 200);
  const multiFamilyWidth = getWidth(windowWidth, 80, 160, 200);
  const dependencieWidth = getWidth(windowWidth, 80, 160, 250);
  const taskNameWidth = getWidth(windowWidth, 280, 380, 400);
  const fixRows = React.useMemo(() => rowOrderMemo(controller), [controller]);
  const onUse = template.get('onUse');
  const isLocked = onUse && hasMultiFamilyTasks(template);
  const { onPreConstructionChange, onMultiFamilyChange } = EventHandlers({ controller });

  const onClickDependencies = taskOrStage =>
    modal.open(TaskDependencies, { controller, taskOrStage, tasks: template.get('tasks'), template });

  useEffect(() => {
    if (editingRow !== null) {
      const onClick = e => {
        const { target } = e;
        if (target.tagName === 'INPUT') return;

        let element = target;
        while (element) {
          const { classList } = element;
          if (classList.contains('react-datepicker')) return;
          if (classList.contains('rbt-menu')) return;
          element = element.parentElement;
        }
        cancelEdit();
      };

      window.addEventListener('click', onClick);
      return () => window.removeEventListener('click', onClick);
    }
  }, [setEditingRow, setDefaultEditField, editingRow]);

  const cancelEdit = () => {
    setEditingRow(null);
    setDefaultEditField(null);
  };

  const onEditFieldClick = (index, field) => e => {
    e.preventDefault();
    e.stopPropagation();
    setEditingRow(index);
    setDefaultEditField(field);
  };

  useEffect(() => {
    if (editingRow === null || defaultEditField === null) return;

    const [row] = window.document.getElementsByClassName('editing-row');
    if (!row) return;

    let [element] = row.getElementsByClassName(`edit-${defaultEditField}`);
    if (!element) return;

    if (element.tagName !== 'INPUT') {
      element = element.getElementsByTagName('input')[0];
    }

    if (element && element.tagName === 'INPUT') {
      element.focus();
    }
  }, [editingRow, defaultEditField]);

  const onToggleRow = (rowData, index) => controller.toggleRow('stages', rowData, index);

  const getIsExpanded = data => {
    const isStage = data.get('isStage');
    const id = isStage ? data.get('_id') : String(data.get('id'));
    return !!expandedRows.getIn(['stages', id]);
  };

  const getRowHeight = ({ index }) => getRowHeightStagesView(index, viewModel, expandedRows);

  const getInnerCellHeight = task => calculateInnerCellHeight(task, expandedRows);

  const getRowClassName = ({ index }) =>
    cn({
      loading: !viewModel.get(index),
      'editing-row': index === editingRow,
      'is-stage': viewModel.getIn([index, 'isStage']),
      'is-collapsed': !getIsExpanded(viewModel.get(index) || Map({ id: -1 })),
    });

  fixRows(template, viewModel);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <InfiniteLoader isRowLoaded={({ index }) => !!viewModel.get(index)} loadMoreRows={() => {}} rowCount={totalCount}>
        {({ onRowsRendered, registerChild }) => (
          <TableContainer id="table-container" className={cn({ 'd-none': isEmpty })}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  ref={ref => {
                    tableRef.current = ref;
                    registerChild(ref);
                  }}
                  rowRenderer={defaultRowRenderer}
                  onRowsRendered={onRowsRendered}
                  className="trx-table ml-auto-column-4"
                  headerHeight={40}
                  width={width}
                  height={height}
                  onRowClick={({ index }) => index !== editingRow && cancelEdit()}
                  overscanRowCount={2}
                  rowHeight={getRowHeight}
                  estimatedRowSize={80}
                  rowCount={totalCount}
                  rowGetter={({ index }) => viewModel.get(index) || EMPTY_ROW}
                  rowClassName={getRowClassName}
                >
                  <Column width={32} label="" dataKey="" />

                  <Column
                    label=""
                    dataKey="rowIndex"
                    width={48}
                    style={{ marginTop: '18px' }}
                    className={cn('h-100 pt-3 drag-icon', { disabled: isLocked })}
                    cellRenderer={renderCell(({ rowData }) => (
                      <DraggableArea controller={dndController} task={rowData} disabled={isLocked} />
                    ))}
                  />

                  <Column
                    label="Name"
                    dataKey="name"
                    className="h-100 mt-4 pt-3 bg-transparent"
                    width={taskNameWidth}
                    cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                      const subTasks = rowData.get('children');
                      const isStage = rowData.get('isStage');
                      const isExpanded = getIsExpanded(rowData);
                      const icon = isExpanded ? 'chevron-up' : 'chevron-down';
                      const canExpand = subTasks.size > 0 || isStage;
                      const cellWidth = taskNameWidth;
                      return (
                        <>
                          <div className={cn('d-flex flex-row align-items-center mb-3', { 'ml-3 pl-1': !canExpand })}>
                            {canExpand && (
                              <FontAwesomeIcon
                                icon={icon}
                                className="mr-2"
                                onClick={() => onToggleRow(rowData, rowIndex)}
                              />
                            )}
                            <TaskNameContainer
                              text={cellData}
                              width={cellWidth}
                              isPreConstruction={rowData.get('isPreConstruction')}
                              isMultiFamily={rowData.get('isMultiFamily')}
                            />
                          </div>
                          {isExpanded &&
                            (isStage ? (
                              <InnerTasksList
                                rowData={rowData}
                                controller={controller}
                                dndController={dndController}
                                tableRef={tableRef}
                                rowIndex={rowIndex}
                                expandedRows={expandedRows.get('stages')}
                                getCellHeight={getInnerCellHeight}
                                width={cellWidth}
                                isLocked={isLocked}
                              />
                            ) : (
                              <SubTasksList
                                subTasks={subTasks}
                                rowData={rowData}
                                controller={controller}
                                isTemplate
                                isLocked={isLocked}
                              />
                            ))}
                        </>
                      );
                    })}
                  />

                  <Column
                    label="Duration"
                    dataKey="duration"
                    className="h-100 mt-4 pt-3"
                    width={durationWidth}
                    cellRenderer={renderCell(({ cellData, rowIndex, rowData }) => {
                      const task = rowData.set('durationDays', rowData.get('duration'));
                      const isStage = rowData.get('isStage');
                      const isExpanded = getIsExpanded(rowData);
                      if (rowIndex === editingRow) {
                        return (
                          <div className="mb-3">
                            {isStage ? '' : <TaskDurationInput controller={controller} task={task} />}
                            {isStage && isExpanded && (
                              <InnerDurationList
                                tasks={rowData.get('tasks')}
                                getCellHeight={getInnerCellHeight}
                                controller={controller}
                              />
                            )}
                          </div>
                        );
                      }
                      return (
                        <>
                          {isStage ? (
                            <span className="d-block pr-4 mb-4">&nbsp;</span>
                          ) : (
                            <span
                              onClick={isLocked ? null : onEditFieldClick(rowIndex, 'durationDays')}
                              className={cn('d-block pr-4 mb-4', { editable: !isLocked, 'text-muted': isLocked })}
                            >
                              {cellData}d
                            </span>
                          )}
                          {isStage && isExpanded && (
                            <InnerDurationList
                              tasks={rowData.get('tasks')}
                              getCellHeight={getInnerCellHeight}
                              controller={controller}
                              isLocked={isLocked}
                            />
                          )}
                        </>
                      );
                    })}
                  />

                  <Column
                    label="Dep."
                    dataKey="predecessors"
                    className="h-100 mt-4 pt-3"
                    width={dependencieWidth}
                    cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                      const predecessorsString = getPredecessorsString(cellData, template.get('tasks'));
                      const isStage = rowData.get('isStage');
                      const isExpanded = getIsExpanded(rowData);
                      // TRX-7524
                      return (
                        <>
                          <div className="mb-4">
                            {isStage ? (
                              <>&nbsp;</>
                            ) : (
                              <>
                                <span
                                  className={isLocked ? 'text-muted' : 'editable'}
                                  onClick={isLocked ? null : () => onClickDependencies(rowData)}
                                >
                                  {predecessorsString}
                                  <FontAwesomeIcon
                                    icon={predecessorsString ? 'pen' : 'pen-to-square'}
                                    className={predecessorsString ? 'ml-1' : ''}
                                  />
                                </span>
                                {rowData?.get('missingReference') && (
                                  <div className="text-danger" style={{ marginLeft: '-10px' }}>
                                    Missing
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          {isStage && isExpanded ? (
                            <InnerPredecessorsList
                              tasks={rowData.get('tasks')}
                              template={template}
                              getCellHeight={getInnerCellHeight}
                              onClickDependencies={onClickDependencies}
                              isLocked={isLocked}
                            />
                          ) : null}
                        </>
                      );
                    })}
                  />

                  <Column
                    label="Pre-Construction"
                    dataKey="isPreConstruction"
                    className="h-100 mt-4 pt-3"
                    width={preConstructionWidth}
                    cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                      const isStage = rowData.get('isStage');
                      const isExpanded = getIsExpanded(rowData);
                      const isPreConstruction = !!rowData.get('isPreConstruction');

                      return (
                        <>
                          <div className="mb-4">
                            {isStage ? (
                              <>&nbsp;</>
                            ) : (
                              <input
                                type="checkbox"
                                checked={isPreConstruction}
                                className="cursor-pointer"
                                disabled={isLocked}
                                onChange={onPreConstructionChange(rowData)}
                              />
                            )}
                          </div>
                          {isStage && isExpanded ? (
                            <InnerPreconstructionList
                              tasks={rowData.get('tasks')}
                              template={template}
                              getCellHeight={getInnerCellHeight}
                              controller={controller}
                              isLocked={isLocked}
                            />
                          ) : null}
                        </>
                      );
                    })}
                  />
                  <Column
                    label={
                      <>
                        Multi-Family
                        <MultiFamilyHeaderIcon />
                      </>
                    }
                    dataKey="isMultiFamily"
                    className="h-100 mt-4 pt-3"
                    width={multiFamilyWidth}
                    cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                      const isStage = rowData.get('isStage');
                      const isExpanded = getIsExpanded(rowData);
                      const isMultiFamily = !!rowData.get('isMultiFamily');

                      return (
                        <>
                          <div className="mb-4">
                            {isStage ? (
                              <>&nbsp;</>
                            ) : (
                              <input
                                type="checkbox"
                                checked={isMultiFamily}
                                disabled={isLocked || onUse}
                                className="cursor-pointer"
                                onChange={onMultiFamilyChange({ rowData, tasks: viewModel, modal })}
                              />
                            )}
                          </div>
                          {isStage && isExpanded ? (
                            <InnerMultiFamilyList
                              tasks={rowData.get('tasks')}
                              allTasks={template.get('tasks')}
                              template={template}
                              getCellHeight={getInnerCellHeight}
                              controller={controller}
                              modal={modal}
                              isLocked={isLocked || onUse}
                            />
                          ) : null}
                        </>
                      );
                    })}
                  />

                  <Column
                    label=""
                    dataKey=""
                    className="h-100 mt-4 pt-3"
                    width={32}
                    cellRenderer={renderCell(({ rowData, rowIndex }) => {
                      const isStage = rowData.get('isStage');
                      const isExpanded = getIsExpanded(rowData);
                      return (
                        <>
                          <div className="mb-3">
                            <TableRowDropDown
                              remove={!isStage}
                              removeStage={isStage}
                              disabled={isLocked}
                              onSelect={() => controller.deleteTemplateRow(rowData)}
                            />
                          </div>
                          {isStage && isExpanded && (
                            <InnerDropDownActions
                              tasks={rowData.get('tasks')}
                              getCellHeight={getInnerCellHeight}
                              onSelect={task => controller.deleteTask(task)}
                              isLocked={isLocked}
                            />
                          )}
                        </>
                      );
                    })}
                  />

                  <Column width={32} label="" dataKey="" />
                </Table>
              )}
            </AutoSizer>
          </TableContainer>
        )}
      </InfiniteLoader>
      {isEmpty && (
        <EmptyStateCenterContainer>
          <EmptyState
            icon="wrench"
            title="No Tasks or Stages Added"
            body="When you add a Task or a Stage to this Template it will appear here."
          />
        </EmptyStateCenterContainer>
      )}
    </>
  );
}

const TableContainer = styled.div`
  flex: 1 1 auto;
  border-bottom: solid 20px transparent;
  overflow-y: hidden;
`;
