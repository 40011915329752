import React from 'react';
import cn from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MultiFamilyIcon = ({
  task,
  isMultiFamily,
  icon = 'house-building',
  className = 'text-secondary ml-2 small',
}) => {
  if (task) {
    isMultiFamily = task.get('isMultiFamily');
  }

  if (isMultiFamily !== true) return null;

  return (
    <PopoverMultiFamilyIcon icon={icon} className={className}>
      A Multi-Family Task is a single Task
      <br />
      that lives in several Jobs and it
      <br />
      shares the exact same information.
    </PopoverMultiFamilyIcon>
  );
};

export const MultiFamilyHeaderIcon = ({ icon = 'square-info' }) => (
  <MultiFamilyIcon className="text-primary ml-2 small" isMultiFamily={true} icon={icon} />
);

const PopoverMultiFamilyIcon = ({ icon, children, className = 'ml-2 small' }) => {
  const popover = (
    <Popover className="trx-popover">
      <Popover.Content>{children}</Popover.Content>
    </Popover>
  );

  const isDefaultIcon = icon === 'house-building';

  return (
    <OverlayTrigger placement="bottom" overlay={popover} open className="cursor-pointer">
      {isDefaultIcon ? (
        <svg
          className={cn(className, 'cursor-pointer')}
          style={{ overflow: 'visible' }}
          width="18"
          height="12"
          viewBox="0 0 18 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.8512 3.44441L14.0335 0.119907C13.931 0.0135378 13.8111 0 13.7067 0C13.6022 0 13.4843 0.0290097 13.3934 0.104435L9.59311 3.44441C9.54476 3.48696 9.50608 3.53144 9.479 3.57979C9.61438 3.63394 9.73622 3.71324 9.84453 3.81574L10.3764 4.27989H10.3822V4.28376L12.761 6.35698V5.7149C12.761 5.45188 12.9737 5.23914 13.2367 5.23914H14.1882C14.4532 5.23914 14.6659 5.45188 14.6659 5.7149V7.02421C14.6659 7.35298 14.9309 7.61987 15.2616 7.61987H16.0932C16.1145 7.61987 16.1377 7.61794 16.159 7.616C16.1783 7.61794 16.1957 7.61987 16.2131 7.61987H16.451C16.7798 7.61987 17.0467 7.35298 17.0467 7.02421V5.35712C17.0467 5.34358 17.0467 5.33004 17.0447 5.3165V4.27989H17.5224C17.7912 4.27989 18.0001 4.07102 18.0001 3.8022C18.0001 3.66875 17.9556 3.54885 17.8512 3.44441Z"
            fill="#333333"
          />
          <path
            d="M5.23767 5.7149V6.36665L8.16765 3.78866L8.17732 3.78093L8.18892 3.77126C8.28756 3.69003 8.39973 3.62427 8.52157 3.57979C8.49836 3.53144 8.46548 3.48696 8.42293 3.44441L4.6072 0.119907C4.50276 0.0135378 4.38479 0 4.28035 0C4.17592 0 4.05601 0.0290097 3.96512 0.104435L0.166785 3.44441C0.0468787 3.54885 -0.0130746 3.66875 0.00239724 3.8022C0.00239724 4.07102 0.226739 4.27989 0.48009 4.27989H0.955848L0.944244 6.66255C0.944244 6.7051 0.948112 6.74378 0.95198 6.78439V7.02421C0.95198 7.35298 1.21887 7.61987 1.54765 7.61987H1.78552C1.80293 7.61987 1.8184 7.60633 1.83581 7.61794C1.85515 7.60633 1.87642 7.61987 1.8977 7.61987H2.73898C3.06775 7.61987 3.33271 7.35298 3.33271 7.02421V5.7149C3.33271 5.45188 3.54544 5.23914 3.8104 5.23914H4.76192C5.02687 5.23914 5.23767 5.45188 5.23767 5.7149Z"
            fill="#333333"
          />
          <path
            d="M12.6856 7.31624L10.3765 5.3049L9.32052 4.38433C9.21609 4.27989 9.09811 4.26442 8.99368 4.26442C8.88924 4.26442 8.77127 4.29536 8.68038 4.36886L7.61862 5.30297L5.31912 7.32397L4.88011 7.71077C4.76214 7.8152 4.70025 7.93318 4.71572 8.06855C4.71572 8.33544 4.94006 8.54625 5.19341 8.54625H5.66917L5.65757 10.9289C5.65757 10.9695 5.66144 11.0082 5.6653 11.0488V11.2906C5.6653 11.6193 5.93219 11.8862 6.26097 11.8862H6.49885C6.51045 11.8862 6.52206 11.8785 6.53366 11.8785C6.53753 11.8785 6.54333 11.8804 6.54913 11.8843C6.55493 11.8804 6.56074 11.8785 6.56847 11.8785C6.58201 11.8785 6.59555 11.8862 6.61102 11.8862H7.4523C7.78108 11.8862 8.04796 11.6193 8.04796 11.2906V9.98126C8.04796 9.7163 8.2607 9.50356 8.52372 9.50356H9.47524C9.74019 9.50356 9.95293 9.7163 9.95293 9.98126V11.2906C9.95293 11.6193 10.2179 11.8862 10.5467 11.8862H11.3802C11.4015 11.8862 11.4247 11.8843 11.446 11.8824C11.4653 11.8843 11.4827 11.8862 11.5001 11.8862H11.738C12.0668 11.8862 12.3337 11.6193 12.3337 11.2906V9.62347C12.3337 9.60993 12.3337 9.59446 12.3317 9.58092V8.54625H12.8094C13.0782 8.54625 13.2871 8.33544 13.2871 8.06855C13.2871 7.93318 13.2407 7.8152 13.1382 7.71077L12.6856 7.31624Z"
            fill="#333333"
          />
        </svg>
      ) : (
        <FontAwesomeIcon icon={icon} className={cn(className, 'cursor-pointer')} />
      )}
    </OverlayTrigger>
  );
};
