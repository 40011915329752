import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import styled from 'styled-components';
import { colors } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OverdueUpdateMsgOverlay = ({ task }) => {
  const isUpdReqFromOverdueConfirm = task.getIn(['changeRequest', 'isOverdueTask']);

  if (!isUpdReqFromOverdueConfirm) return null;

  const popover = (
    <Popover className="trx-popover" style={{ width: '248px' }}>
      <Popover.Content className="text-dark">Update Request from an Overdue Task Confirmation</Popover.Content>
    </Popover>
  );

  return (
    <div className="d-flex my-1 flex-row small align-items-center text-secondary">
      <OverlayTrigger placement="bottom" overlay={popover}>
        <IconWrapper>
          <FontAwesomeIcon icon="calendar-exclamation" className="cursor-pointer" />
        </IconWrapper>
      </OverlayTrigger>
    </div>
  );
};

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray};
  padding: 6px;
  border-radius: 50%;
  margin-right: 6px;
`;
