import React from 'react';
import moment from 'moment';
import { fromJS, List, Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TasksGraph, DateUtil, AffectedList } from '@tradetrax/tasks-util';
import { buildersService } from 'services';
import {
  markAsSideEffect,
  markAsSync,
  ConfirmDialog,
  CustomDialog,
  OKDialog,
  EMPTY_JOB_FEED,
} from '@tradetrax/web-common';
import { JobStatus, StagesModel } from '@tradetrax/job-util';
import { AddTaskModal } from '../AddTaskModal';
import { StageScheduleImpactModal } from '../ScheduleImpactModal';
import { emptyExpandedState, emptyFeedCount } from './JobDetails.shared';
import { LIST_VIEW, GRID_VIEW } from './JobDetailsContext';
import {
  trxToISO,
  trxFullToISO,
  formatTrx,
  formatISO,
  mongoToISO,
  NOT_STARTED,
  IN_PROGRESS,
  OVERDUE_START,
  OVERDUE_FINISH,
  mongoToTrx,
  plural,
  calculateDiffDays,
  isMissedTaskDate,
  setCheckedInStatus,
} from '@tradetrax/web-common/lib/utils';
import { TargetCycleTimeModal } from './JobHeader/TargetCycleTimeModal';
import { TasksAffectedModal } from 'app/workflows/TasksAffected';
import { TaskUpdateRequestModal } from '@tradetrax/web-common/lib/ToDo/TaskUpdateRequestModal';
import { ATTACHMENTS_JOB_VIEW } from '@tradetrax/web-common/lib/Photos/AlbumController';
import { jobTaskFilter } from '@tradetrax/web-common/lib/Filters/Filter.collections';
import { mapStagesViewModel } from '@tradetrax/web-common/lib/Stages';

export * from './JobDetails.RealTime.actions';

const isKey = task => task.get('isKeyFinish') || task.get('isKeyStart');

/**
 *
 * @param {*} jobId
 */
export async function readJob(jobId, isRealTimeRefersh = false) {
  try {
    const { user } = this.appState.toObject();
    const userId = user.get('_id');
    const query = { includeStageTasks: true };
    const [job, tasks, stages] = await Promise.all([
      buildersService
        .readJob({}, { params: { jobId } })
        .then(fromJS)
        .then(data => data.update('tasks', tasks => tasks.map(task => setCheckedInStatus(isTaskReady(task)))))
        .then(data => data.update('tasks', setRowIndex)),
      buildersService
        .readJobTasks({}, { params: { jobId }, query })
        .then(fromJS)
        .then(tasks => tasks.map(isTaskReady))
        .then(tasks => tasks.map(setCheckedInStatus))
        .then(setRowIndex),
      buildersService.viewJobStages({}, { params: { jobId } }).then(fromJS),
    ]);

    const currentJobId = this.state.getIn(['job', '_id']);
    const isSameJob = currentJobId === jobId;

    const taskTypes = getTaskTypes(this.state, tasks, !isSameJob);

    return state => {
      const expandedRows = isRealTimeRefersh
        ? state.get('expandedRows')
        : getInitialExpandedRows(this.scheduleView.id, tasks, stages);

      setTimeout(() => this.loaderRef.current?.resetLoadMoreRowsCache(true), 1);
      return internallFiltertasks(
        state.merge({
          stages,
          tasks,
          taskTypes,
          expandedRows,
          job: mapSupersAndSchedulers(job, tasks),
          isLoading: false,
          filteredTasks: tasks,
        }),
        this.filterState,
        userId
      );
    };
  } catch (error) {
    const hasPermission = error.httpCode !== 404;
    return state => state.set('hasPermission', hasPermission);
  }
}

function getTaskTypes(state, tasks, forceUpdate = true) {
  return state.get('taskTypes').size && !forceUpdate
    ? state.get('taskTypes')
    : tasks.filter(
        (task, i, self) => !task.get('isStageTask') && i === self.findIndex(t => t.get('name') === task.get('name'))
      );
}

function getInitialExpandedRows(scheduleViewId, tasks, stages) {
  if (scheduleViewId !== 'stages-expanded') return emptyExpandedState;

  const viewModel = mapStagesViewModel(fromJS({ tasks, stages }));
  const expanded = viewModel.reduce((prev, current) => {
    if (current.get('isStage')) return prev.set(current.get('_id'), true);
    return prev;
  }, Map());

  return emptyExpandedState.set('stages', expanded);
}

function internallFiltertasks(state, filterState, userId) {
  const isFiltering = filterState.get('isFiltering');
  const tasks = state.get('tasks');

  if (!isFiltering) {
    return state.set('filteredTasks', tasks);
  }

  return state.set('filteredTasks', jobTaskFilter(filterState, tasks, userId));
}

markAsSync(filterTasks);
export function filterTasks(state, userId) {
  return internallFiltertasks(state, this.filterState, userId);
}

markAsSideEffect(readTask);
export async function readTask(jobId, taskId) {
  const id = parseInt(taskId, 10);
  const tasks = this.state.get('tasks');
  const taskIndex = tasks.findIndex(task => task.get('id') === id);

  if (taskIndex >= 0) {
    buildersService.readJobTaskDetail({}, { params: { jobId, taskId } }).then(this.refreshTaskStatus(taskIndex));
  }
}

markAsSync(setTab);
export function setTab(state, tab) {
  window.location.hash = tab;
  if (tab === 'feed') {
    this.feedLoaderRef.current?.resetLoadMoreRowsCache(true);
    state = state.set('feed', EMPTY_JOB_FEED).set('jobFeedCount', emptyFeedCount);
  }
  return state.set('tab', tab);
}

export async function updateJob(jobId, field, value, title) {
  const payload = { [field]: value };
  const setStateJob = field === 'targetCycleTime' ? ['job', 'statusData', field] : ['job', field];
  const titleWithOutLastPeriod = title?.replace(/\.$/, '');

  return buildersService
    .updateJob(payload, { params: { jobId } })
    .then(() => state => state.setIn(setStateJob, value))
    .catch(err => {
      this.alert.add({
        message: `There was a problem updating the ${titleWithOutLastPeriod}. Please try again.`,
        variant: 'danger',
      });
      throw err;
    });
}

markAsSync(toggleScheduleView);
export function toggleScheduleView(state, option) {
  if (this.scheduleView.id === option.id) return state;

  this.appController.selectScheduleView(option.id);

  if (!option.id.startsWith('stages-')) return state;

  // NOTE: if this become a little slow, we will need to move this `viewModel` to the `jobDetailsContext` file.
  const { filteredTasks: tasks, stages, expandedRows } = state.toObject();
  const viewModel = mapStagesViewModel(fromJS({ tasks, stages }));

  if (option.id === 'stages-collapsed') {
    const indexes = expandedRows
      .get('stages')
      .map((expanded, id) => {
        if (expanded) {
          return viewModel.findIndex(stage => stage.get('_id') === id);
        } else return null;
      })
      .filter(index => index !== null);

    this.controller.updateTable(indexes);
    return state.set('expandedRows', emptyExpandedState);
  } else if (option.id === 'stages-expanded') {
    const expanded = viewModel.reduce((prev, current) => {
      if (current.get('isStage')) return prev.set(current.get('_id'), true);
      return prev;
    }, Map());

    const indexes = stages.map((v, k) => viewModel.findIndex(stage => stage.get('_id') === k));
    this.controller.updateTable(indexes);
    return state.set('expandedRows', emptyExpandedState.set('stages', expanded));
  }

  return state;
}

markAsSync(toggleAttachmentsView);
export function toggleAttachmentsView(state, type) {
  this.attachmentsRef.current?.toggleView(type);
  const view = state.get('attachmentsView') === LIST_VIEW ? GRID_VIEW : LIST_VIEW;
  localStorage.setItem(ATTACHMENTS_JOB_VIEW, view);
  return state.set('attachmentsView', view);
}

/**
 * Helper functions
 */

function isTaskReady(task) {
  const hasDates = task.get('isStageTask') || (!!task.get('durationDays') && !!task.get('startDate'));
  const isReady = (hasDates && !!task.getIn(['assigneeAccount', 'companyId'])) || task.get('isStageTask');
  return task.set('isReady', isReady).set('hasDatesSet', hasDates);
}

function setRowIndex(tasks) {
  return tasks.map((task, index) => task.set('rowIndex', index + 1));
}

function mapSupersAndSchedulers(job, tasks) {
  const mapUsers = field =>
    tasks
      .reduce((memo, task) => {
        const user = task.get(field);
        if (!user || memo.find(u => u.get('_id') === user.get('_id'))) return memo;
        return memo.push(user);
      }, List())
      .map(u => ({ name: u.get('name'), status: u.get('status'), initials: u.get('initials') }))
      .sort();

  return job.set('supers', mapUsers('userSuper')).set('schedulers', mapUsers('userScheduler'));
}

markAsSideEffect(updateTable);
export function updateTable(rowIndexes) {
  setTimeout(() => {
    rowIndexes.forEach(index => this.schedulerRef.current.recomputeRowHeights(index));
    this.schedulerRef.current.forceUpdateGrid();
  }, 50);
}

markAsSync(toggleRowDnD);
export function toggleRowDnD(state, index, stageId) {
  const path = ['expandedRows', 'stages', stageId];
  const isExpanded = !!state.getIn(path);

  this.controller.updateTable([index]);

  return isExpanded ? state.deleteIn(path) : state.setIn(path, true);
}

markAsSync(toggleRow);
export function toggleRow(state, view, row, index) {
  const id = row.get('isStage') ? row.get('_id') : String(row.get('id'));
  const path = ['expandedRows', view, id];
  const isExpanded = !!state.getIn(path);
  const newState = isExpanded ? state.deleteIn(path) : state.setIn(path, true);

  this.controller.updateTable([index]);

  return newState;
}

markAsSideEffect(openAddTaskModal);
export function openAddTaskModal({ rowIndex, jobContext, stage = null }) {
  const stages = stage ? List() : this.state.get('stages');
  const job = this.state.get('job');
  const isReleased = job.get('released');

  const addTask = form => {
    const [stageForm] = form.stage || [];
    const stageId = stageForm?._id;
    const index = rowIndex || rowIndex === 0 ? 0 : stages.findIndex(stage => stage.get('_id') === stageId);
    if (isReleased) {
      this.controller.addTaskAfterRTC(form, index);
    } else this.controller.addTask(form, index);
  };

  const stageAux = stage ? [stage.toJS()] : null;
  this.modal.open(AddTaskModal, { stage: stageAux, stages, isReleased, job, jobContext, addTask });
}

markAsSideEffect(addTask);
export function addTask(form, rowIndex) {
  const { task: tasks, stage: stages, duration } = form;
  const [task] = tasks;
  const jobId = this.state.getIn(['job', '_id']);
  const payload = {
    gtlTaskId: task._id,
    name: task.name,
    trade: task.trade,
    duration: parseInt(duration, 10),
  };

  const children = task.children;
  if (children?.length) payload.children = children;
  if (stages?.length) payload.stageId = stages[0]._id;

  return buildersService
    .addTask(payload, { params: { jobId } })
    .then(({ tasks }) => {
      const newTasksAdded = mapTasksNewAdded(tasks);
      this.controller.dispatch([
        state => {
          const taskTypes = getTaskTypes(state, newTasksAdded);
          return state
            .set('tasks', newTasksAdded)
            .setIn(['job', 'tasks'], newTasksAdded)
            .set('taskTypes', taskTypes);
        },
      ]);
      this.addAlert('Task successfully added to this Job.');
      if (!isNaN(rowIndex) && rowIndex >= 0) {
        this.schedulerRef.current.recomputeRowHeights(rowIndex);
        this.schedulerRef.current.forceUpdateGrid();
      }
    })
    .catch(() => {
      this.addAlert('There was a problem adding this Task to the Job. Please try again.', 'danger');
    });
}

/**
 * Add task after RTC
 */
markAsSideEffect(addTaskAfterRTC);
export function addTaskAfterRTC(form, rowIndex) {
  const job = this.state.get('job');
  const jobId = job.get('_id');
  const { task: tasks, stage: stages, duration } = form;
  const [task] = tasks;
  const children = task.children;
  const payload = {
    gtlTaskId: task._id,
    name: task.name,
    trade: task.trade,
    duration: parseInt(duration, 10),
    startDate: formatISO(form.startDate),
    assigneeAccountId: form.assignee[0].get('subAccountId'),
  };
  if (form.super?.length) payload.userSuperId = form.super[0].get('_id');
  if (form.scheduler?.length) payload.userSchedulerId = form.scheduler[0].get('_id');
  if (children?.length) payload.children = children;
  if (stages?.length) payload.stageId = stages[0]._id;

  return buildersService
    .addTask(payload, { params: { jobId } })
    .then(({ tasks }) => {
      const tasksAux = mapTasksNewAdded(tasks);
      const tasksGraph = getTasksGraph(this.state);

      const { statusData, endDate } = getJobStatusData(job, tasksGraph);
      this.controller.dispatch([
        state => {
          const taskTypes = getTaskTypes(state, tasksAux);
          return state
            .set('tasks', tasksAux)
            .set('taskTypes', taskTypes)
            .update('job', job =>
              job
                .set('expectedFinishDate', endDate ? formatTrx(endDate) : job.get('expectedFinishDate'))
                .set('statusData', fromJS(statusData))
                .set('tasks', tasksAux)
            );
        },
      ]);
      this.addAlert('Task successfully added to this Job and released to construction.');
      if (!isNaN(rowIndex) && rowIndex >= 0) {
        this.schedulerRef.current.recomputeRowHeights(rowIndex);
        this.schedulerRef.current.forceUpdateGrid();
      }
    })
    .catch(error => {
      this.addAlert('There was a problem adding this Task to the Job. Please try again.', 'danger');
    });
}

function mapTasksNewAdded(tasks) {
  const allTasks = fromJS(tasks);
  const [stageTasks, regularTasks] = [
    allTasks.filter(task => task.get('isStageTask')),
    allTasks.filter(task => !task.get('isStageTask')),
  ];
  const tasksAux = regularTasks
    .sortBy(task => task.get('order'))
    .map((task, index) => task.set('rowIndex', index + 1))
    .map(isTaskReady);
  return tasksAux.concat(stageTasks);
}

markAsSync(updateTaskPreConstruction);
export function updateTaskPreConstruction(state, { task, isPreConstruction }) {
  const tasks = state.get('tasks');
  const taskId = task.get('id');
  const taskIndex = tasks.indexOf(task);
  const jobId = state.getIn(['job', '_id']);

  buildersService
    .updateTask({ isPreConstruction }, { params: { jobId, taskId } })
    .then(updatedTask => {
      const refresh = this.refreshTask(taskIndex);
      return refresh(updatedTask);
    })
    .catch(() => {
      this.controller.dispatch([state => state.setIn(['tasks', taskIndex], task)]);
      this.addAlert("This Task could not be marked as 'Pre-Construction'. Please try again.", 'danger');
    });

  return state
    .setIn(['tasks', taskIndex, 'isPreConstruction'], isPreConstruction)
    .setIn(['job', 'tasks', taskIndex, 'isPreConstruction'], isPreConstruction);
}

markAsSync(assignTask);
export function assignTask(state, { task, subId }) {
  const tasks = state.get('tasks');
  const { account, subs } = this.appState.toObject();
  const sub = subs.find(s => s.get('subAccountId') === subId);
  const subName = sub ? sub.get('name') : account.get('name');
  const taskId = task.get('id');
  const taskIndex = tasks.indexOf(task);
  const jobId = state.getIn(['job', '_id']);

  buildersService
    .updateTask({ assigneeAccountId: subId }, { params: { jobId, taskId } })
    .then(updatedTask => {
      if (task.getIn(['changeRequest', 'activeForCurrentUser'])) {
        this.addAlert('Task has been successfully reassigned and update request removed.', 'success');
      }
      const refresh = this.refreshTask(taskIndex);
      return refresh(updatedTask);
    })
    .catch(() => {
      this.controller.dispatch([state => state.setIn(['tasks', taskIndex], task)]);
      this.addAlert('There was a problem assigning this Task. Please try again.', 'danger');
    });

  return state
    .updateIn(['tasks', taskIndex], task =>
      task
        .setIn(['assigneeAccount', 'company'], subName)
        .setIn(['assigneeAccount', 'companyId'], subId)
        .update(isTaskReady)
    )
    .updateIn(['job', 'tasks', taskIndex], task => task.merge(fromJS(task)).update(isTaskReady));
}

markAsSync(updateTaskScheduler);
export function updateTaskScheduler(state, { task, user }) {
  const tasks = state.get('tasks');
  const job = state.get('job');
  const jobId = job.get('_id');
  const taskId = task.get('id');
  const taskIndex = tasks.indexOf(task);
  const userSchedulerId = user ? user.get('_id') : null;
  const name = user ? user.get('firstName') + ' ' + user.get('lastName') : null;

  buildersService
    .updateTask({ userSchedulerId }, { params: { jobId, taskId } })
    .then(this.refreshTask(taskIndex))
    .catch(() => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
      this.controller.dispatch([state => state.set('tasks', tasks).set('job', job)]);
    });

  const updatedTasks = tasks.update(taskIndex, task => task.set('userScheduler', user ? user.set('name', name) : user));
  const updatedJob = mapSupersAndSchedulers(job, updatedTasks);
  return state.set('tasks', updatedTasks).set('job', updatedJob);
}

markAsSync(updateTaskSuper);
export function updateTaskSuper(state, { task, user }) {
  const tasks = state.get('tasks');
  const job = state.get('job');
  const jobId = job.get('_id');
  const taskId = task.get('id');
  const taskIndex = tasks.indexOf(task);
  const userSuperId = user ? user.get('_id') : null;
  const name = user ? user.get('firstName') + ' ' + user.get('lastName') : null;

  buildersService
    .updateTask({ userSuperId }, { params: { jobId, taskId } })
    .then(this.refreshTask(taskIndex))
    .catch(() => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
      this.controller.dispatch([state => state.set('tasks', tasks).set('job', job)]);
    });

  const updatedTasks = tasks.update(taskIndex, task => task.set('userSuper', user ? user.set('name', name) : user));
  const updatedJob = mapSupersAndSchedulers(job, updatedTasks);
  return state.set('tasks', updatedTasks).set('job', updatedJob);
}

markAsSideEffect(updateStageDuration);
export async function updateStageDuration(stage, durationString) {
  const { job, tasks, stages } = this.state.toObject();
  const duration = parseInt(durationString, 10);
  if (job.get('status') === IN_PROGRESS) {
    const { isAccept } = await showScheduleImpact.call(this, { duration, job, stage });
    if (!isAccept) return;
  }
  const stageIndex = this.state.get('stages').findIndex(item => item.get('_id') === stage.get('_id'));

  const tasksGraph = getTasksGraph(this.state);
  const { impactedTasksAndDates } = tasksGraph.setDuration(stage.get('stageTaskId'), duration);
  const { statusData, endDate } = getJobStatusData(job, tasksGraph);
  const updatedStage = impactedTasksAndDates.get(stage.get('stageTaskId'));
  buildersService
    .updateJobStageTask({ duration }, { params: { jobId: job.get('_id'), taskId: stage.get('stageTaskId') } })
    .then(() => {
      this.controller.dispatch([
        state =>
          state
            .update('job', job =>
              job.set('expectedFinishDate', endDate ? formatTrx(endDate) : null).set('statusData', fromJS(statusData))
            )
            .update('tasks', tasks => updateMultipleDates(tasks, tasksGraph.calculateAllSuccessorDates()))
            .updateIn(['stages', stageIndex], stage => stage.merge(fromJS(updatedStage))),
      ]);
    })
    .catch(error => {
      this.addAlert('There was a problem updating this Stage. Please try again.', 'danger');
      this.controller.dispatch([state => state.set('stages', stages).set('tasks', tasks)]);
    });
}

markAsSideEffect(updateTaskDuration);
export async function updateTaskDuration({ task, duration }) {
  if (task.get('isStage')) {
    return this.controller.updateStageDuration(task, duration);
  }
  const job = this.state.get('job');
  const status = job.get('status');
  const isJobInProgress = status === IN_PROGRESS;
  const tasksGraph = getTasksGraph(this.state);
  const taskId = task.get('id');

  if (isJobInProgress) {
    const { diffDays } = isJobExpFinishDateImpacted({
      currentExpFinishDate: job.get('expectedFinishDate'),
      task,
      duration,
      tasksGraph,
    });
    const durationInt = parseInt(duration, 10);
    const { impactedTasksAndDates, affectedTaskMap } = tasksGraph.clone().setDuration(taskId, durationInt);
    const props = { isDuration: true, diffDays, impactedTasksAndDates, affectedTaskMap };
    const { isAccept, tasksHeld, isDueWeather = false, taskIdRootCause, builderRootCause } = await this.modal.open(
      TasksAffectedModal,
      {
        task,
        tasksGraph,
        ...props,
      }
    );
    if (!isAccept) return false;

    this.controller.doUpdateTaskDuration(
      taskId,
      duration,
      tasksGraph,
      tasksHeld,
      isDueWeather,
      taskIdRootCause,
      builderRootCause
    );
  } else this.controller.doUpdateTaskDuration(taskId, duration, tasksGraph);
}

function taskGraphUpdateTasksHeldPredecessors(taskId, tasksHeld, tasksGraph) {
  const completeHoldTasksIds = tasksHeld.filter(taskHeld => !taskHeld.holdDate).map(taskHeld => taskHeld.taskIdToHold);
  const task = tasksGraph.getTask(taskId);
  const tasksToModifyPredecessorsSet = new Set(task.successors.map(task => task.taskId));
  const tasksToModifyPredecessors = completeHoldTasksIds.filter(taskId => tasksToModifyPredecessorsSet.has(taskId));
  tasksGraph.deletePredecessorFromTasks(taskId, tasksToModifyPredecessors);
  tasksHeld.forEach(taskHeld => {
    if (!!taskHeld.holdDate) {
      tasksGraph.setStartDate(taskHeld.taskIdToHold, taskHeld.holdDate);
    }
  });
}

markAsSideEffect(doUpdateTaskDuration);
export function doUpdateTaskDuration(
  taskId,
  duration,
  tasksGraph,
  tasksHeld,
  reasons,
  taskIdRootCause,
  builderRootCause
) {
  const { job, tasks } = this.state.toObject();
  const { settings, _id: jobId, status } = job.toObject();
  const task = tasks.find(task => task.get('id') === taskId);
  const dateUtils = new DateUtil(settings.toJS());
  const isJobInProgress = status === IN_PROGRESS;
  const durationInt = parseInt(duration, 10);
  const taskIndex = tasks.indexOf(task);
  const query = { taskIdRootCause, builderRootCause };
  if (tasksHeld?.size) {
    query.tasksToModify = JSON.stringify(tasksHeld.toJS());
    taskGraphUpdateTasksHeldPredecessors(taskId, tasksHeld.toJS(), tasksGraph);
  }
  if (reasons) query.reasons = ['weather'];

  const { impactedTasksAndDates } = tasksGraph.setDuration(taskId, durationInt);
  const impactedTask = impactedTasksAndDates.get(taskId);
  const newEndDate = impactedTask ? impactedTask.endDate : '';
  const daysBehind =
    newEndDate && isJobInProgress
      ? dateUtils.calculateDifference(task.get('expectedFinishDate'), newEndDate) + task.get('daysBehind')
      : task.get('daysBehind');
  const { statusData, endDate } = getJobStatusData(job, tasksGraph);
  const jobFinishDate = endDate ? formatTrx(endDate) : null;

  this.controller.dispatch([
    state => {
      return state
        .update('stages', stages => stages.map(removeTasksHeldFromPredecessors(taskId, tasksHeld, 'stageTaskId')))
        .update('job', job => job.set('expectedFinishDate', jobFinishDate).set('statusData', fromJS(statusData)))
        .update('tasks', tasks =>
          updateMultipleDates(tasks, tasksGraph.calculateAllSuccessorDates())
            .map(removeTasksHeldFromPredecessors(taskId, tasksHeld))
            .update(taskIndex, task =>
              task
                .set('durationDays', duration)
                .set('daysBehind', daysBehind)
                .update(isTaskReady)
            )
        )
        .withMutations(updateStagesDuration(tasksGraph));
    },
  ]);

  buildersService
    .updateTask({ duration: durationInt }, { params: { jobId, taskId }, query })
    .then(this.refreshTask(taskIndex))
    .catch(() => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
      this.controller.dispatch([currentState => currentState.set('job', job).set('tasks', tasks)]);
    });

  //todo: remove the `return true;`
  return true;
}

markAsSideEffect(updateTaskOrStageStartDate);
export async function updateTaskOrStageStartDate({ date, rowData, isStage }) {
  if (isStage) {
    const job = this.state.get('job');
    if (job.get('status') === IN_PROGRESS) {
      const { isAccept } = await showScheduleImpact.call(this, { date, job, stage: rowData, isStartDate: true });
      if (!isAccept) return;
    }
    const hasPredecessors = !!rowData.get('predecessors').size;
    if (hasPredecessors) this.controller.updateStageStartDateAndClearPredecessors({ date, stage: rowData });
    else this.controller.updateStageStartDate({ date, stage: rowData });
  } else this.controller.updateTaskStartDate({ date, task: rowData });
}

markAsSideEffect(updateTaskOrStageEndDate);
export async function updateTaskOrStageEndDate({ date, rowData, isStage }) {
  if (isStage) {
    const job = this.state.get('job');
    if (job.get('status') === IN_PROGRESS) {
      const { isAccept } = await showScheduleImpact.call(this, { date, job, stage: rowData });
      if (!isAccept) return;
    }
    this.controller.updateStageEndDate({ date, stage: rowData });
  } else this.controller.updateEndDateAndDuration({ date, task: rowData });
}

markAsSideEffect(updateRawStartDate);
export async function updateRawStartDate({ date, rowData, isStage }) {
  const isValidDateFormat = format => moment(date, format).format(format) === date;
  if (!isValidDateFormat('MM/DD/YYYY')) return;
  if (isStage) {
    const job = this.state.get('job');
    if (job.get('status') === IN_PROGRESS) {
      const { isAccept } = await showScheduleImpact.call(this, {
        date: new Date(date),
        job,
        stage: rowData,
        isStartDate: true,
      });
      if (!isAccept) return;
    }
    const hasPredecessors = !!rowData.get('predecessors').size;
    if (hasPredecessors) this.controller.updateStageStartDateAndClearPredecessors({ date, stage: rowData });
    else this.controller.updateStageStartDate({ date, stage: rowData });
  } else this.controller.updateTaskStartDate({ date, task: rowData });
}

async function showScheduleImpact({ date, duration, job, stage, isStartDate = false }) {
  const hasPredecessors = !!stage.get('predecessors').size;
  const currentExpFinishDate = job.get('expectedFinishDate');
  const tasksGraph = getTasksGraph(this.state);
  const props = { currentExpFinishDate, task: stage, tasksGraph };
  if (duration) props.duration = duration;
  else if (isStartDate) props.taskDate = date;
  else props.taskEndDate = formatISO(date);

  const { diffDays } = isJobExpFinishDateImpacted({ ...props });
  const { isAccept } = await this.modal.open(StageScheduleImpactModal, {
    jobDelay: diffDays,
    hasPredecessors,
    isStartDate,
    stage,
  });

  return { isAccept };
}

markAsSideEffect(updateTaskStartDate);
export async function updateTaskStartDate({ task, date }) {
  if (!date || !task) return;

  const job = this.state.get('job');
  const tasks = this.state.get('tasks');
  const status = job.get('status');
  const isJobInProgress = status === IN_PROGRESS;
  const currentExpFinishDate = job.get('expectedFinishDate');
  const tasksGraph = getTasksGraph(this.state);
  const { diffDays } = isJobExpFinishDateImpacted({
    currentExpFinishDate,
    task,
    taskDate: date,
    tasksGraph,
  });
  const taskId = task.get('id');
  const hasPredecessors = !!task.get('predecessors').size;
  if (isJobInProgress) {
    const props = {
      diffDays,
      isStartDate: true,
      date,
    };
    const { isAccept, tasksHeld, isDueWeather = false, taskIdRootCause, builderRootCause } = await this.modal.open(
      TasksAffectedModal,
      {
        task,
        tasksGraph,
        ...props,
      }
    );
    const reasons = isDueWeather ? ['weather'] : [];

    if (isAccept) {
      if (tasksHeld?.size) taskGraphUpdateTasksHeldPredecessors(taskId, tasksHeld.toJS(), tasksGraph);
      if (hasPredecessors)
        this.controller.updateTaskAndJobDatesAndClearPredecessors({
          tasks,
          taskId,
          date,
          tasksGraph,
          tasksHeld,
          taskIdRootCause,
          builderRootCause,
        });
      else
        this.controller.updateDate({ date, taskId, tasksGraph, tasksHeld, reasons, taskIdRootCause, builderRootCause });
    }
  } else {
    if (hasPredecessors) {
      const { isAccept } = await this.modal.open(ConfirmDialog, {
        title: (
          <>
            <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
            Removal Alert
          </>
        ),
        message:
          'Manually changing the Exp. Start will also result in all Predecessor relationships being removed. Do you confirm the start date change?',
      });
      if (isAccept) {
        this.controller.updateTaskAndJobDatesAndClearPredecessors({ tasks, taskId, date, tasksGraph });
      }
    } else {
      this.controller.updateDate({ date, taskId, tasksGraph });
    }
  }
}

// TODO: Aqui tenemos que actualizar los start y end date de los stages
markAsSideEffect(updateDate);
export function updateDate({ date, taskId, tasksGraph, tasksHeld, reasons, taskIdRootCause, builderRootCause }) {
  const { job, tasks } = this.state.toObject();
  const task = tasks.find(task => task.get('id') === taskId);
  const jobId = task.getIn(['job', 'id']);
  const dateString = trxToISO(date);
  // eslint-disable-next-line no-unused-vars
  const { daysBehind, updatedJob } = UpdateJobDateFromNewTaskStartDate({
    task,
    dateString,
    tasksGraph,
    job,
  });

  const taskIndex = this.state.get('tasks').indexOf(task);
  if (taskIndex < 0) return;

  this.controller.dispatch([
    state => {
      return state
        .set('job', updatedJob)
        .update('tasks', tasks =>
          updateMultipleDates(tasks, tasksGraph.calculateAllSuccessorDates())
            .map(removeTasksHeldFromPredecessors(taskId, tasksHeld))
            .update(taskIndex, task => task.set('daysBehind', daysBehind).update(isTaskReady))
        )
        .withMutations(updateStagesDuration(tasksGraph));
    },
  ]);

  const query = { taskIdRootCause, builderRootCause };
  if (tasksHeld?.size) query.tasksToModify = JSON.stringify(tasksHeld.toJS());
  if (reasons) query.reasons = reasons;

  buildersService
    .updateTask({ startDate: dateString }, { params: { jobId, taskId }, query })
    .then(this.refreshTask(taskIndex))
    .catch(() => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
      this.controller.dispatch([currentState => currentState.set('job', job).set('tasks', tasks)]);
    });
}

// TODO: pinkus checkout this
function updateStagesDuration(tasksGraph, stages) {
  return state => {
    const job = state.get('job').toJS();
    const sortedStages = stages || state.get('stages').toJS();
    const stagesModel = new StagesModel({ job, sortedStages });
    const startDate = tasksGraph.getStartDate();
    const endDate = tasksGraph.getEndDate();
    const tasks = state
      .get('tasks')
      .filter(t => !t.get('isStageTask'))
      .map(t => t.set('endDate', t.get('expectedFinishDate')))
      .toJS();

    try {
      tasks.forEach(task => stagesModel.updateStageDates(task));
      stagesModel.updateTargetDatesFromJob({ startDate, endDate });
      state.set('stages', fromJS(stagesModel.getStages()));
    } catch (er) {
      console.error(er);
    }
  };
}

markAsSync(updateTaskAndJobDatesAndClearPredecessors);
export function updateTaskAndJobDatesAndClearPredecessors(
  state,
  { tasks, taskId, date, tasksGraph, tasksHeld, taskIdRootCause, builderRootCause }
) {
  const task = tasks.find(task => task.get('id') === taskId);
  const job = state.get('job');
  const jobId = job.get('_id');
  const startDate = trxToISO(date);
  const predecessors = [];
  const taskIndex = tasks.indexOf(task);

  tasksGraph.setPredecessors(taskId, []);
  const { daysBehind, updatedJob } = UpdateJobDateFromNewTaskStartDate({
    task,
    dateString: startDate,
    tasksGraph,
    job,
  });

  const newState = state
    .set('job', updatedJob)
    .update('tasks', tasks =>
      updateMultipleDates(tasks, tasksGraph.calculateAllSuccessorDates())
        .map(removeTasksHeldFromPredecessors(taskId, tasksHeld))
        .update(taskIndex, task =>
          task
            .set('predecessors', fromJS(predecessors))
            .set('daysBehind', daysBehind)
            .update(isTaskReady)
        )
    )
    .withMutations(updateStagesDuration(tasksGraph));

  const query = { taskIdRootCause, builderRootCause };
  if (tasksHeld?.size) query.tasksToModify = JSON.stringify(tasksHeld.toJS());

  buildersService
    .updateTask({ startDate, predecessors, missingReference: false }, { params: { jobId, taskId }, query })
    .then(this.refreshTask(taskIndex))
    .then(() => {
      this.addAlert('Predecessors for this task successfully removed.', 'success');
    })
    .catch(() => {
      this.addAlert(
        'There was a problem updating the exp. start date and removing the predecessors of this task. Please try again.',
        'danger'
      );
      this.controller.dispatch([
        currentState => currentState.set('job', state.get('job')).set('tasks', state.get('tasks')),
      ]);
    });

  return newState;
}

function UpdateJobDateFromNewTaskStartDate({ task, dateString, tasksGraph, job }) {
  const taskId = task.get('id');
  const { impactedTasksAndDates } = tasksGraph.setStartDate(taskId, dateString);
  const { statusData, startDate, endDate } = getJobStatusData(job, tasksGraph);
  const dateUtils = new DateUtil(job.get('settings').toJS());
  const daysBehind =
    task.get('startDate') && job.get('status') === IN_PROGRESS
      ? dateUtils.calculateDifference(task.get('startDate'), dateString) + task.get('daysBehind')
      : task.get('daysBehind');
  const updatedJob = job
    .set('startDate', formatTrx(startDate))
    .set('expectedFinishDate', formatTrx(endDate))
    .set('statusData', fromJS(statusData));
  return { daysBehind, impactedTasksAndDates, updatedJob, startDate, endDate };
}

markAsSideEffect(updateRawEndDate);
export async function updateRawEndDate({ date, rowData, isStage }) {
  const isValidDateFormat = format => moment(date, format).format(format) === date;
  if (!isValidDateFormat('MM/DD/YYYY')) return;

  if (isStage) {
    const job = this.state.get('job');
    if (job.get('status') === IN_PROGRESS) {
      const { isAccept } = await showScheduleImpact.call(this, { date: new Date(date), job, stage: rowData });
      if (!isAccept) return;
    }
    this.controller.updateStageEndDate({ date, stage: rowData });
  } else this.controller.updateEndDateAndDuration({ date, task: rowData });
}

markAsSideEffect(updateEndDateAndDuration);
export async function updateEndDateAndDuration({ date, task }) {
  if (!date || !task || moment(date).isBefore(formatISO(task.get('startDate')))) return;
  const job = this.state.get('job');

  const status = job.get('status');
  const isJobInProgress = status === IN_PROGRESS;
  const endDate = formatTrx(date);
  const tasksGraph = getTasksGraph(this.state);
  const { diffDays } = isJobExpFinishDateImpacted({
    currentExpFinishDate: job.get('expectedFinishDate'),
    task,
    taskEndDate: formatISO(endDate),
    tasksGraph,
  });

  if (isJobInProgress) {
    const props = { diffDays, date, isEndDate: true };
    const { isAccept, tasksHeld, isDueWeather, taskIdRootCause, builderRootCause } = await this.modal.open(
      TasksAffectedModal,
      {
        task,
        tasksGraph,
        ...props,
      }
    );
    if (!isAccept) return;
    const reasons = isDueWeather ? ['weather'] : [];

    this.controller.updateTaskEndDateAndJobEndDate(
      task.get('id'),
      date,
      tasksGraph,
      tasksHeld,
      reasons,
      taskIdRootCause,
      builderRootCause
    );
  } else this.controller.updateTaskEndDateAndJobEndDate(task.get('id'), date, tasksGraph);
}

markAsSideEffect(openUpdateRequestModal);
export async function openUpdateRequestModal(task) {
  const { form, isAccept } = await this.modal.open(TaskUpdateRequestModal, {
    task,
    canProposeFinish: true,
    isBuilder: true,
  });

  if (!isAccept) return;

  const updateStateTask = response => {
    const taskIndex = this.state.get('tasks').indexOf(task);
    this.alert.success({ message: 'Update request successfully sent.' });
    this.controller.dispatch([
      state => state.setIn(['tasks', taskIndex, 'changeRequest'], response.get('changeRequest')),
    ]);
  };
  const { startDate, finishDate } = form;
  try {
    if (startDate) await sendStartDateRequest(task, form, updateStateTask);
    if (finishDate) await sendFinishDateRequest(task, form, updateStateTask);
  } catch (error) {
    this.alert.error({ message: 'There was a problem sending this update request. Please try again.' });
  }
}

function sendStartDateRequest(task, { startDate, reasons }, updateStateTask) {
  const newStartDate = formatISO(startDate);
  const reasonsArray = reasons ? ['weather'] : [];
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);

  return buildersService
    .startDateSendRequestBuilder({ newStartDate, reasons: reasonsArray }, { params: { jobId, taskId } })
    .then(fromJS)
    .then(updateStateTask)
    .catch(error => {
      throw error;
    });
}

function sendFinishDateRequest(task, { finishDate, reasons }, updateStateTask) {
  const newEndDate = formatISO(finishDate);
  const reasonsArray = reasons ? ['weather'] : [];
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  return buildersService
    .endDateSendRequestBuilder({ newEndDate, reasons: reasonsArray }, { params: { jobId, taskId } })
    .then(fromJS)
    .then(updateStateTask)
    .catch(error => {
      throw error;
    });
}

markAsSideEffect(openModalTCT);
export async function openModalTCT({ status, job }) {
  const jobId = job.get('_id');
  const state = this.state;
  const isJobStarted = status === 'in-progress' && job.get('status') === 'not-started';
  const isJobCompleted = status === 'completed' && job.get('status') === 'not-started';

  if (isJobStarted || isJobCompleted) {
    const { targetCycleTimeValue } = await this.modal.open(TargetCycleTimeModal, { job, status, state });
    if (targetCycleTimeValue)
      await this.controller.updateJob(jobId, 'targetCycleTime', parseInt(targetCycleTimeValue), 'Target Cycle Time');
    else return;
    this.controller.updateJobStatus({ status, targetCycleTime: targetCycleTimeValue });
  } else {
    const targetCycleTimeValue = job.getIn(['statusData', 'targetCycleTime']);
    await this.controller.updateJob(jobId, 'targetCycleTime', parseInt(targetCycleTimeValue), 'Target Cycle Time');
    this.controller.updateJobStatus({ status, targetCycleTime: targetCycleTimeValue });
  }
}

markAsSync(updateTaskEndDateAndJobEndDate);
export function updateTaskEndDateAndJobEndDate(
  state,
  taskId,
  date,
  tasksGraph,
  tasksHeld,
  reasons,
  taskIdRootCause,
  builderRootCause
) {
  const job = state.get('job');
  const tasks = state.get('tasks');
  const task = tasks.find(task => task.get('id') === taskId);
  const jobId = job.get('_id');
  const dateString = trxToISO(date);
  const taskIndex = tasks.indexOf(task);

  const query = { taskIdRootCause, builderRootCause };
  if (tasksHeld?.size) {
    query.tasksToModify = JSON.stringify(tasksHeld.toJS());
    // tasksGraph.deletePredecessorsMultipleTasks(tasksHeld.toJS());
    taskGraphUpdateTasksHeldPredecessors(taskId, tasksHeld.toJS(), tasksGraph);
  }

  tasksGraph.setEndDate(taskId, dateString);
  const { statusData, endDate } = getJobStatusData(job, tasksGraph);

  if (reasons?.length > 0) query.reasons = reasons;

  buildersService
    .updateTask({ endDate: dateString }, { params: { jobId, taskId }, query })
    .then(this.refreshTask(taskIndex))
    .catch(() => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
      this.controller.dispatch([state => state.set('tasks', tasks).set('job', job)]);
    });

  return state
    .set('job', job.set('expectedFinishDate', formatTrx(endDate)).set('statusData', fromJS(statusData)))
    .update('tasks', tasks =>
      updateMultipleDates(tasks, tasksGraph.calculateAllSuccessorDates()).map(
        removeTasksHeldFromPredecessors(taskId, tasksHeld)
      )
    )
    .withMutations(updateStagesDuration(tasksGraph))
    .update('stages', stages => stages.map(removeTasksHeldFromPredecessors(taskId, tasksHeld, 'stageTaskId')));
}

// TODO: deprecate this method, stages are calculated now
markAsSync(updateStageStartDate);
export function updateStageStartDate(state, { stage, date }) {
  if (!date) return state;

  const job = state.get('job');
  const tasks = state.get('tasks');
  const tasksGraph = getTasksGraph(state);
  const taskStage = tasks.find(task => task.get('isStageTask') && task.get('stageId') === stage.get('_id'));

  const startDate = formatISO(date);
  const jobId = job.get('_id');
  const stageIndex = state.get('stages').findIndex(el => el.get('_id') === stage.get('_id'));

  const { impactedTasksAndDates, updatedJob } = UpdateJobDateFromNewTaskStartDate({
    task: taskStage,
    dateString: startDate,
    tasksGraph,
    job,
  });
  const updatedStage = impactedTasksAndDates.get(stage.get('stageTaskId'));
  const taskIndex = tasks.findIndex(task => task.get('id') === stage.get('stageTaskId'));

  buildersService
    .updateJobStageTask({ startDate }, { params: { jobId, taskId: stage.get('stageTaskId') } })
    .catch(error => {
      this.addAlert('There was a problem updating this Stage. Please try again.', 'danger');
      this.controller.dispatch([
        currentState =>
          currentState
            .set('job', state.get('job'))
            .set('tasks', state.get('tasks'))
            .set('stages', state.get('stages')),
      ]);
    });

  return state
    .set('job', updatedJob.setIn(['tasks', taskIndex, 'startDate'], new Date(startDate).toISOString()))
    .update('tasks', tasks => updateMultipleDates(tasks, tasksGraph.calculateAllSuccessorDates()))
    .updateIn(['stages', stageIndex], stage => stage.merge(fromJS(updatedStage)));
}

// TODO: depreacte this method, stages are calculated now
markAsSideEffect(updateStageStartDateAndClearPredecessors);
export async function updateStageStartDateAndClearPredecessors({ stage, date }) {
  const { isAccept } = await this.modal.open(ConfirmDialog, {
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
        Removal Alert
      </>
    ),
    message:
      'Manually changing the Exp. Start will also result in all Predecessor relationships being removed. Do you confirm the start date change?',
  });
  if (!isAccept) return;

  const job = this.state.get('job');
  const startDate = formatISO(date);
  const tasks = this.state.get('tasks');
  const stages = this.state.get('stages');
  const taskStage = tasks.find(task => task.get('isStageTask') && task.get('stageId') === stage.get('_id'));
  const taskIndex = tasks.indexOf(taskStage);
  const stageIndex = this.state.get('stages').findIndex(el => el.get('_id') === stage.get('_id'));
  const tasksGraph = getTasksGraph(this.state);

  tasksGraph.setPredecessors(taskStage.get('id'), []);
  const { impactedTasksAndDates, updatedJob } = UpdateJobDateFromNewTaskStartDate({
    task: taskStage,
    dateString: startDate,
    tasksGraph,
    job,
  });
  const updatedStage = impactedTasksAndDates.get(stage.get('stageTaskId'));

  this.controller.dispatch([
    state =>
      state
        .set('job', updatedJob)
        .update('tasks', tasks =>
          updateMultipleDates(tasks, tasksGraph.calculateAllSuccessorDates()).setIn(
            [taskIndex, 'predecessors'],
            fromJS([])
          )
        )
        .updateIn(['stages', stageIndex], stage => stage.merge(fromJS(updatedStage))),
  ]);

  buildersService
    .updateJobStageTask({ startDate }, { params: { jobId: job.get('_id'), taskId: stage.get('stageTaskId') } })
    .then(response => {
      this.addAlert('Predecessors for this Stage successfully removed.', 'success');
    })
    .catch(() => {
      this.addAlert(
        'There was a problem updating the exp. start date and removing the predecessors of this Stage. Please try again.',
        'danger'
      );
      this.controller.dispatch([
        currentState =>
          currentState
            .set('job', job)
            .set('tasks', tasks)
            .set('stages', stages),
      ]);
    });
}

markAsSync(updateStageEndDate);
export function updateStageEndDate(state, { stage, date }) {
  if (!date) return state;

  const { job, tasks, stages } = state.toObject();
  const tasksGraph = getTasksGraph(state);
  const taskStage = tasks.find(task => task.get('isStageTask') && task.get('stageId') === stage.get('_id'));

  const jobId = job.get('_id');
  const endDate = formatISO(date);
  const stageIndex = stages.findIndex(el => el.get('_id') === stage.get('_id'));

  const { impactedTasksAndDates } = tasksGraph.setEndDate(taskStage.get('id'), endDate);
  const { statusData, endDate: jobEndDate } = getJobStatusData(job, tasksGraph);
  const updatedStage = impactedTasksAndDates.get(stage.get('stageTaskId'));

  buildersService.updateJobStageTask({ endDate }, { params: { jobId, taskId: stage.get('stageTaskId') } }).catch(() => {
    this.addAlert('There was a problem updating this Stage. Please try again.', 'danger');
    this.controller.dispatch([
      currentState =>
        currentState
          .set('job', job)
          .set('tasks', tasks)
          .set('stages', stages),
    ]);
  });

  return state
    .update('job', job =>
      job.set('expectedFinishDate', jobEndDate ? formatTrx(jobEndDate) : null).set('statusData', fromJS(statusData))
    )
    .update('tasks', tasks => updateMultipleDates(tasks, tasksGraph.calculateAllSuccessorDates()))
    .updateIn(['stages', stageIndex], stage => stage.merge(fromJS(updatedStage))); // TODO fix index
}

markAsSync(updateStatus);
export function updateStatus(state, { task, status }) {
  const job = state.get('job');
  const jobId = job.get('_id');
  const taskId = task.get('id');
  const tasks = state.get('tasks');
  const stages = state.get('stages');
  const taskIndex = tasks.indexOf(task);
  const updatedStages = isKey(task) && getStagesWithStatusImpact({ task, stages: state.get('stages'), job, status });

  buildersService
    .updateTask({ status }, { params: { jobId, taskId } })
    .then(this.refreshTask(taskIndex))
    .catch(() => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
      this.controller.dispatch([state => state.setIn(['tasks', taskIndex], task).set('stages', stages)]);
    });

  return state
    .setIn(['tasks', taskIndex], task.set('status', status))
    .update('stages', stages => updatedStages || stages);
}

markAsSideEffect(deleteTask);
export async function deleteTask({ task, rowIndex }) {
  const taskId = task.get('id');
  const job = this.state.get('job');
  const tasks = this.state.get('tasks');
  const { _id: jobId } = job.toObject();

  if (isKey(task)) return openCannotRemoveKeyTaskDialog(this.modal);

  const { isAccept, hasPredecessors } = await openRemoveTaskDialog(task, this.modal);
  if (!isAccept) return;

  const tasksGraph = getTasksGraph(this.state);
  const { impactedTasksAndDates } = tasksGraph.deleteTask(taskId);
  const { statusData, endDate } = getJobStatusData(job, tasksGraph);
  const taskIndex = tasks.indexOf(task);
  const tasksTemp = removePredecessorFromTasks(tasks, taskId).splice(taskIndex, 1);

  this.controller.dispatch([
    state =>
      state
        .set('tasks', updateMultipleDates(setRowIndex(tasksTemp), impactedTasksAndDates))
        .set(
          'job',
          job
            .set('expectedFinishDate', formatTrx(endDate))
            .set('tasks', updateMultipleDates(setRowIndex(tasksTemp), impactedTasksAndDates))
            .set('statusData', fromJS(statusData))
        )
        .withMutations(updateStagesDuration(tasksGraph))
        .update('tasksTotalCount', tasksTotalCount => tasksTotalCount - 1),
  ]);

  if (rowIndex >= 0) this.controller.updateTable([rowIndex]);

  const message = hasPredecessors
    ? 'Task and predecessor relationship successfully removed from this Job.'
    : 'Task successfully removed from this Job.';

  return new Promise(resolve => {
    buildersService
      .deleteTask({}, { params: { jobId, taskId } })
      .then(() => {
        this.addAlert(message);
        resolve(true);
      })
      .catch(() => {
        this.addAlert('There was a problem removing this task from the Job. Please try again.', 'danger');
        this.controller.dispatch([state => state.set('job', job.set('tasks', tasks)).set('tasks', tasks)]);
        resolve(false);
      });
  });
}

markAsSync(releaseToConstruction);
export function releaseToConstruction(state) {
  const jobId = state.getIn(['job', '_id']);

  buildersService
    .releaseJob({}, { params: { jobId } })
    .then(() =>
      this.addAlert(
        'Job successfully released to construction. Trades will receive notifications of their assigned Tasks.'
      )
    )
    .catch(() => {
      this.addAlert('There was a problem releasing this Job to construction. Please try again.', 'danger');
      this.controller.dispatch([state => state.setIn(['job', 'released'], false)]);
    });

  return state.setIn(['job', 'released'], true);
}

function jobStartConfirmationModal(statusData) {
  return new Promise(async resolve => {
    const { actualStart, targetCycleTime, targetFinish } = statusData;

    const { isAccept } = await this.modal.open(CustomDialog, {
      title: (
        <>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger font-size-18" />
          Job Start Confirmation
        </>
      ),
      message: (
        <>
          <div>
            Actual Start will be set to <strong>{mongoToTrx(actualStart)}</strong>
          </div>
          <div>
            Target Cycle Time will be set to <strong>{plural.day(targetCycleTime)}</strong>
          </div>
          <div className="mb-3">
            Target Finish will be set to <strong>{mongoToTrx(targetFinish)}</strong>
          </div>
          <span>Are you sure you want to start this job?</span>
        </>
      ),
      titleAccept: 'Yes, Start Job',
      titleCancel: 'Cancel',
      centered: true,
    });
    resolve(isAccept);
  });
}

function jobCompleteConfirmationModal(statusData) {
  return new Promise(async resolve => {
    const { actualStart, actualFinish, cycleTime, targetCycleTime, targetStart, targetFinish } = statusData;
    const { isAccept } = await this.modal.open(CustomDialog, {
      maxWidth: '520px',
      title: (
        <>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger font-size-18" />
          Job Completion Confirmation
        </>
      ),
      message: (
        <>
          <div>
            Actual Start will be set to <strong>{mongoToTrx(actualStart)}</strong>
          </div>
          <div>
            Actual Finish will be set to <strong>{mongoToTrx(actualFinish)}</strong>
          </div>
          <div className="mb-3">
            Actual Cycle Time will be set to <strong>{plural.day(cycleTime)}</strong>
          </div>
          <div>
            Target Start will be set to <strong>{mongoToTrx(targetStart)}</strong>
          </div>
          <div>
            Target Finish will be set to <strong>{mongoToTrx(targetFinish)}</strong>
          </div>
          <div className="mb-3">
            Target Cycle Time will be set to <strong>{plural.day(targetCycleTime)}</strong>
          </div>
          <div className="mb-3">
            Expected Cycle Time will be set to <strong>{plural.day(cycleTime)}</strong>
          </div>
          <span>Are you sure you want to finish this job?</span>
          <div className="mt-3 text-muted font-size-14">
            <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 font-size-12 mr-2" />
            Dates will be set as specified above. If you update this Job Status back to "In Progress" those dates will
            be used to consider the job to be ahead or behind schedule.
          </div>
        </>
      ),
      titleAccept: 'Yes, Finish Job',
      titleCancel: 'Cancel',
      centered: true,
    });
    resolve(isAccept);
  });
}

markAsSideEffect(updateJobStatus);
export async function updateJobStatus({ status, targetCycleTime }) {
  const { stages, job } = this.state.toObject();
  const jobId = job.get('_id');
  const tasksGraph = getTasksGraph(this.state);
  const startDate = tasksGraph.getStartDate();
  const endDate = tasksGraph.getEndDate();
  const tempJob = job.set('startDate', startDate).set('expectedFinishDate', endDate);
  const { statusData } = JobStatus.changeStatus(tempJob.toJS(), status);
  if (targetCycleTime) statusData.targetCycleTime = targetCycleTime;
  const isJobStarted = status === 'in-progress' && job.get('status') === 'not-started';
  const iJobCompleted = status === 'completed' && job.get('status') === 'not-started';
  if (isJobStarted) {
    const isAccept = await jobStartConfirmationModal.call(this, statusData);
    if (!isAccept) return;
  } else if (iJobCompleted) {
    const isAccept = await jobCompleteConfirmationModal.call(this, statusData);
    if (!isAccept) return;
  }

  let updatedStages;
  try {
    updatedStages = getStagesWithStatusImpact({ stages: this.state.get('stages'), job, status });
  } catch (err) {
    this.addAlert('There was a problem updating the Job status. Please try again.', 'danger');
    console.error(err);
    throw err;
  }

  this.controller.dispatch([
    state =>
      state
        .set('job', tempJob.set('status', status).set('statusData', fromJS(statusData)))
        .update('tasks', tasks => checkMissedTasks(status, tasks))
        .set('stages', updatedStages),
  ]);

  return new Promise(resolve => {
    buildersService
      .updateJob({ status }, { params: { jobId } })
      .then(() => {
        if (isJobStarted) this.addAlert('This Job has been successfully started');
        resolve(true);
      })
      .catch(() => {
        if (isJobStarted) this.addAlert('There was a problem starting this Job. Please try. again.');
        this.addAlert('There was a problem updating the Job status. Please try again.', 'danger');
        this.controller.dispatch([state => state.set('job', job).set('stages', stages)]);
        resolve(false);
      });
  });
}

function checkMissedTasks(jobStatus, tasks) {
  if (jobStatus !== IN_PROGRESS) return tasks;
  return tasks.map(task => {
    if (task.get('isStageTask')) return task; // TODO: should we also check for missed start stages?
    if (task.get('status') === NOT_STARTED) {
      if (isMissedTaskDate(task.get('startDate'))) {
        return task.set('overdue', OVERDUE_START);
      }
    }
    if (task.get('status') === IN_PROGRESS) {
      if (isMissedTaskDate(task.get('expectedFinishDate'))) {
        return task.set('overdue', OVERDUE_FINISH);
      }
    }
    return task;
  });
}

/**
 *  Update Requests Actions
 */
markAsSync(cancelStartDateRequest);
export function cancelStartDateRequest(state, { task }) {
  const tasks = state.get('tasks');
  const taskId = task.get('id');
  const jobId = state.getIn(['job', '_id']);
  const index = tasks.indexOf(task);

  buildersService
    .rejectStartDateRequest({}, { params: { jobId, taskId } })
    .then(responseTask => {
      const createdByBuilder = task.getIn(['changeRequest', 'createdByType']) === 'builder';
      const userToNotify = createdByBuilder ? 'Builder' : 'Trade';
      this.addAlert(`Update request declined. ${userToNotify} user will be notified.`);
      responseTask.rowIndex = task.get('rowIndex');
      this.controller.dispatch([state => state.setIn(['tasks', index], fromJS(responseTask))]);
    })
    .catch(() => {
      this.addAlert('There was a problem declining this update request from the Trade. Please try again.', 'danger');
      this.controller.dispatch([state => state.setIn(['tasks', index, 'changeRequest', 'activeForCurrentUser'], true)]);
    });

  return state.setIn(['tasks', index, 'changeRequest', 'activeForCurrentUser'], false);
}

markAsSync(acceptStartDateRequest);
export function acceptStartDateRequest(state, { task, tasksHeld, proposedDate, isDueWeather = false, ...rest }) {
  const tasks = state.get('tasks');
  const job = state.get('job');
  const taskId = task.get('id');
  const date = task.getIn(['changeRequest', 'proposedStartDate']);
  const newStartDate = proposedDate ? formatISO(proposedDate) : formatISO(date);
  const jobId = job.get('_id');
  const tasksGraph = getTasksGraph(state);
  tasksGraph.setStartDate(taskId, newStartDate);
  const { statusData, endDate } = getJobStatusData(job, tasksGraph);
  const index = tasks.indexOf(task);
  const reasons = isDueWeather ? ['weather'] : [];
  const { taskIdRootCause, builderRootCause } = rest;
  const payload = { newStartDate, reasons, taskIdRootCause, builderRootCause };
  if (task?.getIn(['changeRequest', 'newEndDateProposed'])) {
    payload.newEndDate = formatISO(task.getIn(['changeRequest', 'proposedFinishDate']));
  }

  const query = {};
  if (tasksHeld?.size) query.tasksToModify = JSON.stringify(tasksHeld.toJS());

  buildersService
    .acceptStartDateRequest(payload, { params: { jobId, taskId }, query })
    .then(responseTask => {
      const createdByBuilder = task.getIn(['changeRequest', 'createdByType']) === 'builder';
      const userToNotify = createdByBuilder ? 'Builder' : 'Trade';
      this.addAlert(`Update request successfully accepted. ${userToNotify} user will be notified.`);
      responseTask.status = task.get('status');
      responseTask.rowIndex = task.get('rowIndex');
      this.controller.dispatch([state => state.setIn(['tasks', index], fromJS(responseTask))]);
    })
    .catch(() => {
      this.addAlert('There was a problem accepting this update request from the Trade. Please try again.', 'danger');
      this.controller.dispatch([
        state => state.set('job', job).setIn(['tasks', index, 'changeRequest', 'activeForCurrentUser'], true),
      ]);
    });

  return state
    .set('job', job.set('expectedFinishDate', formatTrx(endDate)).set('statusData', fromJS(statusData)))
    .update('tasks', tasks =>
      updateMultipleDates(tasks, tasksGraph.calculateAllSuccessorDates()).setIn(
        [index, 'changeRequest', 'activeForCurrentUser'],
        false
      )
    )
    .withMutations(updateStagesDuration(tasksGraph));
}

// TODO: updateStartDateRequest
// no debemos almacenar en el estado global este valor por que si navegamos
// hacia atras al job, los datos van a persistir en memoria pero no en el backend.
// hay que hacer un `useState` en el `TaskExpStartRequest.js`
markAsSync(updateStartDateRequest);
export function updateStartDateRequest(state, { date, task }) {
  const dateISO = formatISO(date);
  const tasks = state.get('tasks');
  const job = state.get('job');
  const taskIndex = tasks.indexOf(task);
  const tasksGraph = getTasksGraph(state);
  const affectedTasks = getTasksAffected({ newStartDate: dateISO }, task.get('id'), tasksGraph);

  tasksGraph.setStartDate(task.get('id'), dateISO);
  const newEndDate = moment.utc(tasksGraph.getEndDate());
  const days = moment.utc(newEndDate).diff(job.get('expectedFinishDate'), 'days');
  const jobDelay = days;

  return state.updateIn(['tasks', taskIndex, 'changeRequest'], changeRequest =>
    changeRequest
      .set('proposedStartDate', dateISO)
      .set('jobDelay', jobDelay)
      .set('affectedTasks', affectedTasks)
  );
}

markAsSync(cancelExpFinishDateRequest);
export function cancelExpFinishDateRequest(state, { task }) {
  const job = state.get('job');
  const jobId = job.get('_id');
  const tasks = state.get('tasks');
  const taskId = task.get('id');
  const index = tasks.indexOf(task);

  buildersService
    .rejectFinishDateRequest({}, { params: { jobId, taskId } })
    .then(responseTask => {
      this.addAlert('Update request declined. Trade user will be notified.');
      responseTask.rowIndex = task.get('rowIndex');
      this.controller.dispatch([state => state.setIn(['tasks', index], fromJS(responseTask))]);
    })
    .catch(() => {
      this.addAlert('There was a problem declining this update request from the Trade. Please try again.', 'danger');
      this.controller.dispatch([state => state.setIn(['tasks', index, 'changeRequest', 'activeForCurrentUser'], true)]);
    });

  return state.setIn(['tasks', index, 'changeRequest', 'activeForCurrentUser'], false);
}

markAsSync(acceptExpFinishDateRequest);
export function acceptExpFinishDateRequest(state, { task, tasksHeld, proposedDate, isDueWeather = false, ...rest }) {
  const tasks = state.get('tasks');
  const job = state.get('job');
  const taskId = task.get('id');
  const index = tasks.indexOf(task);
  const jobId = job.get('_id');
  const tasksGraph = getTasksGraph(state);
  const date = task.getIn(['changeRequest', 'proposedFinishDate']);
  const newEndDate = proposedDate ? formatISO(proposedDate) : mongoToISO(date);
  const taskDelayDays = tasksGraph.dateUtil.calculateDifference(task.get('expectedFinishDate'), date);
  const { diffDays } = isJobExpFinishDateImpacted({
    currentExpFinishDate: job.get('expectedFinishDate'),
    task,
    tasksGraph,
    taskEndDate: newEndDate,
  });
  const reasons = isDueWeather ? ['weather'] : [];
  const { taskIdRootCause, builderRootCause } = rest;
  const payload = { newEndDate, reasons, taskIdRootCause, builderRootCause };

  const query = {};
  if (tasksHeld?.size) query.tasksToModify = JSON.stringify(tasksHeld.toJS());

  buildersService
    .acceptFinishDateRequest(payload, { params: { jobId, taskId }, query })
    .then(responseTask => {
      if (responseTask.message) {
        throw responseTask;
      }
      responseTask.status = task.get('status');
      responseTask.rowIndex = task.get('rowIndex');
      this.addAlert('Update request successfully accepted. Trade user will be notified.');
      this.controller.dispatch([state => state.setIn(['tasks', index], fromJS(responseTask))]);
    })
    .catch(() => {
      this.addAlert('There was a problem accepting this update request from the Trade. Please try again.', 'danger');
      this.controller.dispatch([
        state => state.set('job', job).setIn(['tasks', index, 'changeRequest', 'activeForCurrentUser'], true),
      ]);
    });

  const { duration } = tasksGraph.setEndDate(task.get('id'), newEndDate);
  const { statusData, endDate } = getJobStatusData(job, tasksGraph);

  let result = state
    .update('tasks', tasks =>
      updateMultipleDates(tasks, tasksGraph.calculateAllSuccessorDates()).update(index, task =>
        task
          .set('durationDays', duration)
          .set('daysBehind', taskDelayDays)
          .setIn(['changeRequest', 'activeForCurrentUser'], false)
      )
    )
    .withMutations(updateStagesDuration(tasksGraph));

  return diffDays > 0
    ? result.set('job', job.set('expectedFinishDate', formatTrx(endDate)).set('statusData', fromJS(statusData)))
    : result;
}

markAsSync(updateExpFinishDateRequest);
export function updateExpFinishDateRequest(state, { date, task }) {
  const dateISO = formatISO(date);
  const tasks = state.get('tasks');
  const job = state.get('job');
  const taskIndex = tasks.indexOf(task);
  const tasksGraph = getTasksGraph(state);
  const affectedTasks = getTasksAffected({ newEndDate: dateISO }, task.get('id'), tasksGraph);

  tasksGraph.setEndDate(task.get('id'), moment.utc(date).format('YYYY-MM-DD'));
  const newEndDate = moment.utc(tasksGraph.getEndDate());
  const days = moment.utc(newEndDate).diff(job.get('expectedFinishDate'), 'days');

  return state.updateIn(['tasks', taskIndex, 'changeRequest'], changeRequest =>
    changeRequest
      .set('proposedFinishDate', dateISO)
      .set('jobDelay', days)
      .set('affectedTasks', affectedTasks)
  );
}

function getTasksAffected(proposedDate, taskId, tasksGraph) {
  const tasksAffectedUtils = AffectedList.createAffectedListObject(tasksGraph, taskId, proposedDate);
  return fromJS(tasksAffectedUtils.getList());
}

/**
 * Dependencies
 */
markAsSync(updateDependencies);
export function updateDependencies(state, { task, predecessors, tasksHeld, taskIdRootCause, builderRootCause }) {
  const taskId = task.get('id');
  const tasks = state.get('tasks');
  const job = state.get('job');
  const jobId = job.get('_id');
  const taskIndex = tasks.findIndex(item => item.get('id') === task.get('id'));
  const tasksGraph = getTasksGraph(state);
  const hasPredecessors = !!predecessors.size;
  const successAlertText = hasPredecessors ? 'updated' : 'removed';
  const errorAlertText = hasPredecessors ? 'updating' : 'removing';
  const predecessorList = predecessors.map(p => ({
    taskId: parseInt(p.get('taskId'), 10),
    dependencyType: p.get('dependencyType'),
    lagTime: parseInt(p.get('lagTime'), 10),
  }));

  let query = { taskIdRootCause, builderRootCause };
  if (tasksHeld?.size) {
    query.tasksToModify = JSON.stringify(tasksHeld.toJS());
    taskGraphUpdateTasksHeldPredecessors(taskId, tasksHeld.toJS(), tasksGraph);
  }

  tasksGraph.setPredecessors(taskId, predecessors.toJS());
  const { statusData, startDate, endDate } = getJobStatusData(job, tasksGraph);

  buildersService
    .updateTask({ predecessors: predecessorList.toJS(), missingReference: false }, { params: { jobId, taskId }, query })
    .then(this.refreshTask(taskIndex))
    .then(() => this.addAlert(`Predecessors for this Task successfully ${successAlertText}.`))
    .catch(() => {
      this.addAlert(
        `There was a problem ${errorAlertText} the predecessors for this Task. Please try again.`,
        'danger'
      );
      this.controller.dispatch([state => state.set('job', job).set('tasks', tasks)]);
    });

  return state
    .update('stages', stages => stages.map(removeTasksHeldFromPredecessors(taskId, tasksHeld, 'stageTaskId')))
    .set(
      'job',
      job
        .set('startDate', formatTrx(startDate))
        .set('expectedFinishDate', formatTrx(endDate))
        .set('statusData', fromJS(statusData))
    )
    .update('tasks', tasks => {
      const updated = updateMultipleDates(state.get('tasks'), tasksGraph.calculateAllSuccessorDates())
        .map(removeTasksHeldFromPredecessors(taskId, tasksHeld))
        .update(taskIndex, task => task.set('predecessors', predecessors).set('missingReference', false));
      return updated.filter(updatedTask => tasks.find(task => updatedTask.get('id') === task.get('id')));
    })
    .withMutations(updateStagesDuration(tasksGraph));
}

markAsSync(updateStageDependencies);
export function updateStageDependencies(state, { stage, predecessors }) {
  const job = state.get('job');
  const jobId = job.get('_id');
  const taskIndex = state.get('tasks').findIndex(task => task.get('id') === stage.get('stageTaskId'));
  const stageIndex = state.get('stages').findIndex(el => el.get('_id') === stage.get('_id'));
  const predecessorList = predecessors.map(p => ({
    taskId: parseInt(p.get('taskId'), 10),
    dependencyType: p.get('dependencyType'),
    lagTime: parseInt(p.get('lagTime'), 10),
  }));

  const tasks = state.get('tasks');
  const tasksGraph = getTasksGraph(state);
  tasksGraph.setPredecessors(stage.get('stageTaskId'), predecessors.toJS());
  const { statusData, startDate, endDate } = getJobStatusData(job, tasksGraph);

  const hasPredecessors = !!predecessors.size;
  const successAlertText = hasPredecessors ? 'updated' : 'removed';
  const errorAlertText = hasPredecessors ? 'updating' : 'removing';

  buildersService
    .updateJobStageTask({ predecessors: predecessorList }, { params: { jobId, taskId: stage.get('stageTaskId') } })
    .then(() => this.addAlert(`Predecessors for this Stage successfully ${successAlertText}.`))
    .catch(() => {
      this.addAlert(`There was a problem ${errorAlertText} the predecessors. Please try again.`, 'danger');
      this.controller.dispatch([
        currentState =>
          currentState
            .set('job', state.get('job'))
            .set('tasks', state.get('tasks'))
            .set('stages', state.get('stages')),
      ]);
    });

  return state
    .update('job', job =>
      job
        .setIn(['tasks', taskIndex, 'predecessors'], predecessors)
        .set('startDate', formatTrx(startDate))
        .set('expectedFinishDate', formatTrx(endDate))
        .set('statusData', fromJS(statusData))
    )
    .update('stages', stages => stages.setIn([stageIndex, 'predecessors'], predecessors))
    .set(
      'tasks',
      updateMultipleDates(tasks, tasksGraph.calculateAllSuccessorDates()).update(taskIndex, task =>
        task.set('predecessors', predecessors).set('missingReference', false)
      )
    )
    .withMutations(updateStagesDuration(tasksGraph));
}

markAsSync(removeMissingReference);
export function removeMissingReference(state, { task }) {
  const isStageTask = task.get('isStage');

  const tasks = state.get('tasks');
  const taskId = isStageTask ? task.get('stageTaskId') : task.get('id');
  const jobId = state.getIn(['job', '_id']);
  const taskIndex = tasks.indexOf(task);

  if (taskIndex < 0 && !isStageTask) return state;
  if (isStageTask) {
    const stageId = task.get('_id');
    buildersService.updateJobStage({ missingReference: false }, { params: { jobId, stageId } });
  } else {
    buildersService
      .updateTask({ missingReference: false }, { params: { jobId, taskId } })
      .then(this.refreshTask(taskIndex));
  }

  return state.setIn(['tasks', taskIndex, 'missingReference'], false).update('stages', stages => {
    if (isStageTask) {
      const index = state.get('stages').findIndex(stage => stage.get('_id') === task.get('_id'));
      return stages.setIn([index, 'missingReference'], false);
    } else return stages;
  });
}

markAsSync(updateTaskOrder);
export function updateTaskOrder(
  state,
  { task, endOrder, isBefore, stageId, isOnTopEdge, isOnBottomEdge, orderAboveStages }
) {
  const stages = state.get('stages');
  const tasksUnordered = state.get('tasks');
  const tasks = state
    .get('tasks')
    .filter(task => !task.get('isStageTask'))
    .sortBy(task => task.get('order'));
  const above = tasks.filter(task => task.get('stageId') === null && task.get('orderAboveStages'));

  if (isKey(task) && task.get('stageId') !== stageId) {
    this.addAlert(
      'This Task can not be removed from the Stage as it is used as a reference for the Stage status.',
      'warning'
    );
    return state;
  }

  if (isOnTopEdge) {
    stageId = null;
    isBefore = true;
    orderAboveStages = true;
    endOrder = tasks.first().get('order');
  } else if (isOnBottomEdge) {
    stageId = null;
    isBefore = false;
    orderAboveStages = false;
    endOrder = tasks.last().get('order');
  }

  // dropped on a stage?
  if (endOrder === -1) {
    let order = above.last()?.get('order') || 0;
    const indexes = stages
      .map(stg => stg.get('_id'))
      .reduce((prev, stageId) => {
        const stageTasks = tasks.filter(task => task.get('stageId') === stageId);
        const firstOrder = stageTasks.size ? stageTasks.first().get('order') : order;
        const lastOrder = stageTasks.size ? stageTasks.last().get('order') : order;
        const isEmpty = stageTasks.size === 0;
        order = lastOrder;

        return prev.set(stageId, Map({ firstOrder, lastOrder, isEmpty }));
      }, Map());

    if (indexes.getIn([stageId, 'isEmpty'])) isBefore = false;

    endOrder = indexes.getIn([stageId, isBefore ? 'firstOrder' : 'lastOrder']);
  }

  const newOrder = (() => {
    // is the same? last orphan above into first stage, only task in stage into next stage, etc...
    if (task.get('order') === endOrder) return endOrder;
    if (task.get('order') < endOrder) {
      // moving the task down the list?
      return isBefore ? endOrder - 1 : endOrder;
    }
    // moving the task up the list?
    return isBefore ? endOrder : endOrder + 1;
  })();
  const jobId = state.getIn(['job', '_id']);
  const taskId = task.get('id');
  const payload = { newOrder, stageId, orderAboveStages };

  buildersService
    .reorderTask(payload, { params: { jobId, taskId } })
    .then(({ tasks }) => {
      this.controller.dispatch([state => state.set('tasks', mapTasksNewAdded(tasks))]);
    })
    .catch(err => {
      this.addAlert('There was an error changing order, operation not completed!', 'danger');
      this.controller.dispatch([state => state.set('tasks', tasksUnordered)]);
    });

  return state.updateIn(['tasks'], tasks => {
    const index = tasks.indexOf(task);
    return tasks
      .remove(index)
      .insert(
        newOrder,
        task
          .set('order', newOrder)
          .set('stageId', stageId)
          .set('orderAboveStages', orderAboveStages)
      )
      .map((task, index) => task.set('rowIndex', index + 1).set('order', index));
  });
}

/**
 * Feed Actions
 */
export function loadMoreJobFeedRows(jobId, { startIndex = 0, stopIndex = 10 }) {
  const start_index = startIndex;
  const stop_index = stopIndex;
  const utcOffset = moment().format('Z');
  const currentJobFeedCount = this.state.get('feedCount');
  const isEmptyFeed = currentJobFeedCount === emptyFeedCount;

  const getFeedCount = () => {
    if (!isEmptyFeed) return Promise.resolve(currentJobFeedCount);
    return buildersService.readJobFeedCount({}, { params: { jobId }, query: { utcOffset } }).then(fromJS);
  };

  const jobFeed = buildersService
    .readJobFeed({}, { params: { jobId }, query: { utcOffset, start_index, stop_index } })
    .then(fromJS);

  return Promise.all([getFeedCount(), jobFeed]).then(([feedCount, newFeed]) => state =>
    state
      .set('feedCount', feedCount)
      .update('feed', feed => feed.splice(startIndex, stopIndex - startIndex + 1, ...fromJS(newFeed).toArray()))
  );
}

/**
 *  Helper functions (NON actions)
 */
function getJobStatusData(job, tasksGraph) {
  const startDate = tasksGraph.getStartDate();
  const endDate = tasksGraph.getEndDate();
  const statusData = JobStatus.updateJobExpectedDates(job.toJS(), {
    startDate,
    endDate,
  });

  return {
    startDate,
    endDate,
    statusData,
  };
}

function updateMultipleDates(tasks, impactedTasksAndDates) {
  return tasks.withMutations(taskList => {
    impactedTasksAndDates.forEach((value, key) => {
      const taskIndex = tasks.findIndex(t => t.get('id') === key);
      taskList.update(taskIndex, task => {
        return task
          .set('startDate', value.startDate)
          .set('expectedFinishDate', value.endDate)
          .set('durationDays', value.duration || task.get('durationDays'))
          .set('isCritical', value.isCritical)
          .set('lateStartDate', value.lateStartDate)
          .set('lateEndDate', value.lateEndDate)
          .update(isTaskReady);
      });
    });
  });
}

function getStagesWithStatusImpact({ task, stages, job, status }) {
  const stagesModel = new StagesModel({ job: job.toJS(), sortedStages: stages.toJS() });

  if (task && task.get('isKeyStart')) {
    stagesModel.updateStagesByStartTask(task.set('status', status).toJS());
  } else if (task && task.get('isKeyFinish')) {
    stagesModel.updateStagesByEndTask(task.set('status', status).toJS());
  } else {
    stagesModel.updateJobStatus(status);
  }
  const updatedStages = stagesModel.getStages();
  return fromJS(updatedStages).map(s => s.delete('dataToUpdate'));
}

function isJobExpFinishDateImpacted({ currentExpFinishDate, task, taskDate, duration, taskEndDate, tasksGraph }) {
  const taskId = task.get('id') || task.get('stageTaskId');
  let newEndDate;
  if (taskEndDate) {
    const { newEndDate: endDate } = tasksGraph.clone().setEndDate(taskId, taskEndDate);
    newEndDate = endDate;
  } else if (duration) {
    const { newEndDate: endDate } = tasksGraph.clone().setDuration(taskId, parseInt(duration, 10));
    newEndDate = endDate;
  } else if (currentExpFinishDate) {
    const { newEndDate: endDate } = tasksGraph.clone().setStartDate(taskId, trxToISO(taskDate));
    newEndDate = endDate;
  }
  const newExpFinishDate = mongoToTrx(newEndDate) || mongoToTrx(currentExpFinishDate);
  const diffDays = calculateDiffDays(currentExpFinishDate, newExpFinishDate);
  return {
    diffDays,
  };
}

const removeTasksHeldFromPredecessors = (taskId, tasksHeld, idField = 'id') => task => {
  if (tasksHeld?.find(({ taskIdToHold }) => taskIdToHold === task.get(idField))) {
    return task.update('predecessors', predecessors => predecessors.filter(p => p.get('taskId') !== taskId));
  }

  return task;
};

// TODO: change this function name (it's not an updater)
// function updateTaskOverdue(task, status) {
//   const currentOverdue = task.get('overdue');
//   if (currentOverdue === 'start') return '';
//   if (status === COMPLETED && currentOverdue === 'finish') return '';
//   return currentOverdue;
// }

function openCannotRemoveKeyTaskDialog(modal) {
  modal.open(OKDialog, {
    title: (
      <>
        <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
        Remove Task
      </>
    ),
    message: 'This Task can not be removed from the Stage as it is used as a reference for the Stage status.',
    titleAccept: 'OK',
  });
}

export async function openRemoveTaskDialog(task, modal) {
  const hasPredecessors = !!task.get('predecessors').size;

  if (task.get('isMultiFamily')) {
    const { isAccept } = await modal.open(CustomDialog, {
      maxWidth: '540px',
      title: (
        <>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
          Remove Multi-Family Task
        </>
      ),
      message: (
        <>
          Removing this Multi-Family Task will also remove assignments, update requests, notes, and attachments, and it
          will affect dependencies.
          <br />
          Are you sure you want to remove it permanently from the Building?
        </>
      ),
      titleAccept: 'Yes, Remove Multi-Family Task',
      titleCancel: 'Cancel',
    });
    return { isAccept, hasPredecessors };
  }

  if (hasPredecessors) {
    const { isAccept } = await modal.open(CustomDialog, {
      title: (
        <>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
          Removal Alert
        </>
      ),
      message:
        'By removing this Task, will result in the removal of all predecessor relationship on this row. Do you confirm the removal?',
      titleAccept: 'Yes, Remove Task',
      titleCancel: 'Cancel',
    });
    return { isAccept, hasPredecessors };
  } else {
    const { isAccept } = await modal.open(CustomDialog, {
      title: (
        <>
          <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
          Remove Task
        </>
      ),
      message:
        'Removing this Task from the Job will also remove assignments, update requests, task history events, notes and attachments. It will also affect the overall schedule and dependencies. You can add it again later.',
      titleAccept: 'Yes, Remove Task',
      titleCancel: 'Cancel',
    });
    return { isAccept };
  }
}

export function removePredecessorFromTasks(tasks, taskId) {
  return tasks.map(task => {
    let hasMissingReference = task.get('missingReference') || false;
    const newPredecessors = task.get('predecessors').filter(p => p.get('taskId') !== taskId);
    if (newPredecessors.size < task.get('predecessors').size) hasMissingReference = true;
    return task.set('predecessors', newPredecessors).set('missingReference', hasMissingReference);
  });
}

markAsSideEffect(updateJobSettings);
export function updateJobSettings(form, jobId) {
  const newSettings = {
    holidays: form.holidays.map(trxFullToISO),
    workingDays: form.workingDays,
  };
  return new Promise(resolve => {
    buildersService
      .updateJob(newSettings, { params: { jobId } })
      .then(() => {
        this.addAlert('Settings for this Job successfully updated.');
        this.controller.readJob(jobId);
        resolve(true);
      })
      .catch(() => {
        this.addAlert('These settings could not be applied. Please try again later.', 'danger');
        resolve(false);
      });
  });
}

markAsSync(updateSubTaskStatus);
export function updateSubTaskStatus(state, task, subTask) {
  const tasks = state.get('tasks');
  const taskIndex = tasks.indexOf(task);
  const children = state.getIn(['tasks', taskIndex, 'children']);
  const childIndex = children.indexOf(subTask);
  const taskId = state.getIn(['tasks', taskIndex, 'id']);
  const jobId = state.getIn(['job', '_id']);
  const status = subTask.get('status') === 'not-started' ? 'completed' : 'not-started';

  buildersService.updateTaskChild({ status }, { params: { jobId, taskId, childId: subTask.get('_id') } }).catch(() => {
    this.addAlert('There was a problem updating this Sub-Task. Please try again.', 'danger');
    this.controller.dispatch([
      state => state.setIn(['tasks', taskIndex, 'children', childIndex], children.get(childIndex)),
    ]);
  });
  return state.setIn(['tasks', taskIndex, 'children', childIndex, 'status'], status);
}

const getTasksGraph = state => {
  const { tasks, job } = state.toObject();
  const tasksGraph = new TasksGraph(tasks.toJS(), job.toJS());
  return tasksGraph;
};

markAsSync(loadAttachmentsView);
export function loadAttachmentsView(state) {
  const defaultView = localStorage.getItem(ATTACHMENTS_JOB_VIEW);
  return state.set('attachmentsView', defaultView || 'grid');
}

markAsSideEffect(openTasksAffectedModal);
export async function openTasksAffectedModal(task) {
  const tasksGraph = getTasksGraph(this.state);
  const { isCancel, isDeclined, isStartUR, ...rest } = await this.modal.open(TasksAffectedModal, {
    task,
    tasksGraph,
    isUpdateRequest: true,
  });

  if (isCancel) return;

  if (isDeclined) {
    if (isStartUR) this.controller.cancelStartDateRequest({ task });
    else this.controller.cancelExpFinishDateRequest({ task });
  } else {
    if (isStartUR) this.controller.acceptStartDateRequest({ task, ...rest });
    else this.controller.acceptExpFinishDateRequest({ task, ...rest });
  }
}
