import React from 'react';
import { Name, Item, SystemItem, status } from './common';
import { isUserInactive } from '../../utils';
import { useGeoLocationProps } from './useGeoLocationProps';

export const HistoryItem = ({ item, account, user, accountUsers, modal }) => {
  const isStartDateConfirmation = item.getIn(['data', 'startDateConfirmation']);
  const event = isStartDateConfirmation ? `accountability-${item.get('event')}` : item.get('event');
  const Component = EVENTS[event || 'sub-sent-finish-change-request'];
  const isInactive = isUserInactive(item.get('createdById'), accountUsers);

  if (Component) {
    if (Component.visibility && !Component.visibility({ item, account, user })) return null;

    return Component({ item, account, user, accountUsers, isInactive, modal });
  }

  return (
    <Item item={item} user={user}>
      [{item.get('event')}] not yet implemented
    </Item>
  );
};

export const StatusUpdate = ({ item, account, user, isInactive }) => (
  <Item item={item} user={user}>
    <Name item={item} accountId={account.get('_id')} user={user} isInactive={isInactive} /> updated status to{' '}
    {status(item)}
  </Item>
);

export const CheckIn = ({ item, account, user, isInactive, modal }) => (
  <Item item={item} user={user} geoLocationProps={useGeoLocationProps(item, account, user, isInactive, modal, 'In')}>
    <Name item={item} accountId={account.get('_id')} user={user} isInactive={isInactive} /> checked-in
  </Item>
);

export const CheckOut = ({ item, account, user, isInactive, modal }) => (
  <Item item={item} user={user} geoLocationProps={useGeoLocationProps(item, account, user, isInactive, modal, 'Out')}>
    <Name item={item} accountId={account.get('_id')} user={user} isInactive={isInactive} /> checked-out to{' '}
    {status(item)}
  </Item>
);

export const OverdueMissedStart = ({ item }) => (
  <SystemItem item={item}>
    Task Overdue <span className="text-danger">Missed Start</span>
  </SystemItem>
);

export const OverdueMissedFinish = ({ item }) => (
  <SystemItem item={item}>
    Task Overdue <span className="text-danger">Missed Finish</span>
  </SystemItem>
);

const attachmentName = item => {
  const fileType = item.getIn(['data', 'fileType']);
  // !fileType for legacy entries not having the `data` field
  if (!fileType || fileType === 'image') return 'photo';

  if (item.getIn(['data', 'fileContentType']) === 'application/pdf') return 'document';

  // default name, most likely will never happen
  return 'file';
};

export const NewAttachment = ({ item, user, isInactive }) => (
  <Item item={item} user={user}>
    <Name item={item} user={user} isInactive={isInactive} /> added {attachmentName(item)}
  </Item>
);

export const AttachmentRemoved = ({ item, user, isInactive }) => (
  <Item item={item} user={user}>
    <Name item={item} user={user} isInactive={isInactive} /> removed a {attachmentName(item)}
  </Item>
);

export const NewNote = ({ item, user, isInactive }) => {
  return (
    <Item item={item} user={user}>
      <Name item={item} user={user} isInactive={isInactive} /> added a note
    </Item>
  );
};

export const NoteRemoved = ({ item, user, isInactive }) => {
  return (
    <Item item={item} user={user}>
      <Name item={item} user={user} isInactive={isInactive} /> removed a note
    </Item>
  );
};

const EVENTS = {
  'status-update': StatusUpdate,
  'overdue-missed-start': OverdueMissedStart,
  'overdue-missed-finish': OverdueMissedFinish,
  'check-in': CheckIn,
  'check-out': CheckOut,
  'new-attachment': NewAttachment,
  'attachment-removed': AttachmentRemoved,
  'new-note': NewNote,
  'note-removed': NoteRemoved,
  'release-to-construction': require('./release-to-construction').JobRTC,
  'task-added-to-rtc': require('./release-to-construction').TaskAddedToRTC,
  'builder-response-start-change-request': require('./builder-response-start-change-request').default,
  'builder-response-end-change-request': require('./builder-response-end-change-request').default,
  'end-date-duration-update': require('./end-date-duration-update').default,
  'builder-update-start-date': require('./builder-update-start-date').default,
  'account-asignee-update': require('./account-asignee-update').default,
  'installer-asignee-update': require('./installer-asignee-update').default,
  'builder-super-asignee-update': require('./builder-super-asignee-update').default,
  'builder-scheduler-asignee-update': require('./builder-scheduler-asignee-update').default,
  'installer-new-expected-finish': require('./installer-new-expected-finish').default,
  'sub-super-asignee-update': require('./sub-super-asignee-update').default,
  'sub-scheduler-asignee-update': require('./sub-scheduler-asignee-update').default,
  'sub-response-finish-change-request': require('./sub-response-finish-change-request').default,
  'sub-sent-start-change-request': require('./sub-sent-start-change-request').default,
  'sub-sent-finish-change-request': require('./sub-sent-finish-change-request').default,
  'builder-sent-start-change-request': require('./sub-sent-start-change-request').default,
  'builder-sent-finish-change-request': require('./sub-sent-finish-change-request').default,
  'predecessors-date-update': require('./predecessors-date-update').default,
  'start-date-confirmation-request': require('./start-date-confirmation-request').default,
  'start-date-confirmed': require('./start-date-confirmed').default,
  'accountability-builder-response-start-change-request': require('./accountability-builder-response-start-change-request')
    .default,
  'accountability-sub-sent-start-change-request': require('./accountability-sub-sent-start-change-request.js').default,
};
