import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { STATUS_MAP, ChipStatusDropdown, OnTime } from '@tradetrax/web-common';
import navigate from 'app/navigate';
import { NOT_STARTED, IN_PROGRESS, COMPLETED, plural } from '@tradetrax/web-common/lib/utils';
import { PreConstructionIcon } from '@tradetrax/web-common/lib/Popover/PreConstructionIcon';
import { CriticalPathLabel } from '@tradetrax/web-common/lib/CriticalPath';
import { MultiFamilyHeaderIcon, MultiFamilyIcon } from '@tradetrax/web-common/lib/Popover/MultiFamily.icons.popover';
import { PopoverMultiFamily } from '@tradetrax/web-common/lib/Popover/MultiFamily.jobs.popover';

export const TaskHeader = ({ controller, task, job }) => {
  const handleChangeStatus = status => controller.updateStatus({ task, status });
  const taskStatus = !!task.get('checkedIn') ? 'checked-in' : task.get('status');
  const showDaysAhead = false;
  const isCritical = task.get('isCritical');
  const isMultiFamily = task.get('isMultiFamily');

  const MultiFamilyLabel = () => {
    if (!isMultiFamily) return null;
    return (
      <div className="mb-2">
        This is a Multi-Family Task <MultiFamilyHeaderIcon />
      </div>
    );
  };

  const JobName = () => {
    if (isMultiFamily) {
      const jobs = task.getIn(['multiFamily', 'jobs']);
      return (
        <PopoverMultiFamily multiFamily={task.get('multiFamily')} className="task-details">
          <span className="clickable-text">{`${jobs.size} ${plural(jobs.size, 'Job')}`}</span>
        </PopoverMultiFamily>
      );
    }

    return <Link to={navigate.to.JobDetails({ jobId: job.get('_id') }, false)}>{job.get('name')}</Link>;
  };

  return (
    <>
      <TopContainer className={cn('w-75', { 'multi-family': isMultiFamily })}>
        <MultiFamilyLabel />
        <div className="d-flex flex-row">
          <div className="d-flex flex-column">
            <TaskNameContainer className="h4 mb-0 font-weight-bold">{task.get('name')}</TaskNameContainer>
            <div className="d-flex flex-row align-items-center py-2">
              {isCritical && <CriticalPathLabel className="mt-0" />}
              <PreConstructionIcon task={task} />
              <MultiFamilyIcon isMultiFamily={isMultiFamily} />
            </div>
          </div>
          <StatusContainer>
            <ChipStatusDropdown
              {...STATUS_MAP[taskStatus]}
              className="mx-2"
              notStarted={taskStatus !== NOT_STARTED}
              progress={taskStatus !== IN_PROGRESS}
              completed={taskStatus !== COMPLETED}
              onSelect={handleChangeStatus}
            />
            {showDaysAhead && <OnTime onTime={task.get('daysBehind')} />}
          </StatusContainer>
        </div>
      </TopContainer>

      <div className="d-flex flex-row mt-4 pt-3">
        <div className="d-flex flex-column mr-5">
          <small className="text-muted">Community</small>
          <Link to={navigate.to.CommunityDetails({ communityId: job.get('communityId') }, false)}>
            {job.get('communityName')}
          </Link>
        </div>
        {isMultiFamily && (
          <div className="d-flex flex-column mr-5">
            <small className="text-muted">Building</small>
            <span className="link">{task.getIn(['multiFamily', 'name'])}</span>
          </div>
        )}
        <div className="d-flex flex-column">
          <small className="text-muted">Job</small>
          <JobName />
        </div>
      </div>
    </>
  );
};

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;

  &.multi-family {
    background: #f9f9fa;
    border-radius: 10px;
    padding: 0.5rem;
    margin: -0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  @media (max-width: 1600px) {
    width: 100% !important;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: flex-start;
  @media (min-width: 1200px) {
    margin-top: 0px;
    margin-left: 15px;
  }
`;

const TaskNameContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;
