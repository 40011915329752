import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Initials, UserNameLabel } from '../../../Tags';
import { useTimeAgo } from '../../../useTimeAgo';
import { mongoToHistory } from '../../../utils';
import {
  AttachmentAdded,
  NoteAdded,
  TaskAssigned,
  URByTrade,
  URByBuilder,
  URByInstaller,
  TaggedInNote,
  SharedFilterSet,
} from './events';

export const RecentActivityItem = ({ activity, ...props }) => {
  const Component = EVENTS[activity.get('eventKey')] || EVENTS['not-supported'];
  return <Component activity={activity} {...props} />;
};

export const Item = ({ activity, children, extra, linkToJob, linkToTask, assigneeClassName, format }) => {
  const name = activity.getIn(['createdBy', 'name']);
  const task = activity.get('sourceTask');
  const job = activity.get('sourceJob');
  const status = activity.getIn(['createdBy', 'status']);
  return (
    <li>
      <div className="d-flex flex-row">
        <Initials name={activity.getIn(['createdBy', 'initials']) || name} size={32} />
        <TextWrapper className="text-secondary d-flex">
          <div className="ml-1 font-size-14 d-flex align-items-center flex-wrap">
            <span className="truncate mr-1">
              <UserNameLabel className={`not-permission ${assigneeClassName}`} isInactive={status === 'inactive'}>
                {name}
              </UserNameLabel>
            </span>
            <span className="mr-1 text-nowrap">{children}</span>
            <Link to={linkToTask(job.get('_id'), task.get('id'))} className="d-inline-block text-truncate mr-1">
              {task.get('name')}
            </Link>
            <span className="mr-1">{` ${format ? format : 'on'} `}</span>
            <Link to={linkToJob(job.get('_id'))} className="d-inline-block text-truncate mr-1">
              {job.get('name')}
            </Link>
          </div>
        </TextWrapper>
      </div>
      {extra}
      <span className="trx-time ml-5 mb-2">{useTimeAgo(activity.get('createdOn'), mongoToHistory)}</span>
    </li>
  );
};

export const TaggedNoteItem = ({ activity, children, extra, linkToJob, linkToTask, assigneeClassName }) => {
  const name = activity.getIn(['createdBy', 'name']);
  const task = activity.get('sourceTask');
  const job = activity.get('sourceJob');
  const status = activity.getIn(['createdBy', 'status']);
  return (
    <li>
      <div className="d-flex flex-row">
        <Initials name={activity.getIn(['createdBy', 'initials']) || name} size={32} />
        <TextWrapper className="text-secondary ">
          <div className="ml-1 font-size-14 d-flex align-items-center flex-wrap">
            <span className="truncate mr-1">
              <UserNameLabel className={`not-permission ${assigneeClassName}`} isInactive={status === 'inactive'}>
                {name}
              </UserNameLabel>
            </span>
            <span className="mr-1">{children}</span>
            <Link to={linkToTask(job.get('_id'), task.get('id'))} className="d-inline-block text-truncate mr-1">
              {task.get('name')}
            </Link>
            <span className="mr-1">{` for `}</span>
            <Link to={linkToJob(job.get('_id'))} className="d-inline-block text-truncate mr-1">
              {job.get('name')}
            </Link>
          </div>
        </TextWrapper>
      </div>
      {extra}
      <span className="trx-time ml-5 mb-2">{useTimeAgo(activity.get('createdOn'), mongoToHistory)}</span>
    </li>
  );
};

export const NoLinksItem = ({ activity, children, extra, assigneeClassName }) => {
  const name = activity.getIn(['createdBy', 'name']);
  const status = activity.getIn(['createdBy', 'status']);

  return (
    <li>
      <div className="d-flex flex-row">
        <Initials name={activity.getIn(['createdBy', 'initials']) || name} size={32} />
        <TextWrapper className="mt-1 mb-2 text-secondary">
          <span className="truncate">
            <UserNameLabel className={`not-permission ${assigneeClassName}`} isInactive={status === 'inactive'}>
              {name}
            </UserNameLabel>
          </span>
          <span>{children}</span>
        </TextWrapper>
      </div>
      {extra}
      <span className="trx-time ml-4 pl-3 mb-2">{useTimeAgo(activity.get('createdOn'), mongoToHistory)}</span>
    </li>
  );
};

const TextWrapper = styled.div`
  span {
    vertical-align: top;
    &.truncate {
      max-width: 200px;
    }
  }
  a {
    max-width: 250px;
  }
`;

const NotSupported = ({ activity }) => <div>{`Recent Activity ${activity.get('eventKey')} not supported`}</div>;

const EVENTS = {
  'task-assigned': TaskAssigned,
  'note-added-to-task': NoteAdded,
  'attachment-added-to-task': AttachmentAdded,
  'installer-creates-change-request-to-task': URByInstaller,
  'sub-creates-change-request-to-task': URByTrade,
  'builder-creates-change-request-to-task': URByBuilder,
  'tagged-in-note': TaggedInNote,
  'not-supported': NotSupported,
  'filter-shared': SharedFilterSet,
};
