import { fromJS } from 'immutable';
import { AffectedList } from '@tradetrax/tasks-util';
import { markAsSync } from '@tradetrax/web-common';
import { formatISO } from '@tradetrax/web-common/lib/utils';
import { UR_VIEW, AFFECTED_VIEW, IMPACT_VIEW, CHANGE_DATE_VIEW } from './TasksAffectedContext';

markAsSync(showUpdateRequestView);
export function showUpdateRequestView(state) {
  return state.set('modalView', UR_VIEW);
}

markAsSync(showTasksAffectedView);
export function showTasksAffectedView(state) {
  return state.set('modalView', AFFECTED_VIEW);
}

markAsSync(showImpactView);
export function showImpactView(state) {
  return state.set('modalView', IMPACT_VIEW);
}

markAsSync(showChangeDateView);
export function showChangeDateView(state) {
  return state.set('modalView', CHANGE_DATE_VIEW);
}

markAsSync(goBack);
export function goBack(state, { isUpdateRequest, isOverdue, ...rest }) {
  const { task, proposedDate } = state.toObject();
  const { impactedTasksAndDates, affectedTaskMap } = rest;
  const modalView = isUpdateRequest ? UR_VIEW : isOverdue ? CHANGE_DATE_VIEW : IMPACT_VIEW;
  const tasksAffectedUtils =
    rest.isDuration || rest.isPredecessorUpdate
      ? AffectedList.createAffectedListObjectFromDateChanges(
          this.tasksGraph,
          impactedTasksAndDates,
          affectedTaskMap,
          task.get('id')
        )
      : AffectedList.createAffectedListObject(this.tasksGraph, task.get('id'), proposedDate);
  const tasksAffected = fromJS(tasksAffectedUtils.getList());
  const { cycleTimeDifference } = tasksAffectedUtils.calculateNewDates();
  this.tasksAffectedRef.current = tasksAffectedUtils;

  return state
    .set('modalView', modalView)
    .set('tasksAffected', tasksAffected)
    .set('tasksAffectedSize', tasksAffected.size)
    .set('jobDelay', cycleTimeDifference);
}

markAsSync(setPartialHoldDate);
export function setPartialHoldDate(state, taskAffected, holdDate) {
  const taskId = taskAffected.get('id');
  const tasksAffectedUtils = this.tasksAffectedRef.current;
  const { cycleTimeDifference, affectedTasksNumber } = tasksAffectedUtils.setPartialHold(taskId, holdDate);
  const updatedTasksAffected = tasksAffectedUtils.getList();

  return state
    .update('tasksAffected', tasksAffected => {
      return tasksAffected.map((task, i) => {
        const updatedTask = updatedTasksAffected[i];
        if (task === taskAffected) {
          return task.set('isPartialHold', updatedTask.isPartial).set('selectedDate', updatedTask.newDates.startDate);
        }

        return task
          .setIn(['newDates', 'startDate'], updatedTask.newDates.startDate)
          .setIn(['oldDates', 'startDate'], updatedTask.oldDates.startDate);
      });
    })
    .set('tasksAffectedSize', affectedTasksNumber)
    .set('jobDelay', cycleTimeDifference);
}

markAsSync(toggleHoldDate);
export function toggleHoldDate(state, index) {
  const { cycleTimeDifference, affectedTasksNumber } = getImpactOnHoldingSingle.call(this, state, index);
  const tasksAffectedUtils = this.tasksAffectedRef.current;
  const updatedTasksAffected = tasksAffectedUtils.getList();
  return state
    .update('tasksAffected', tasksAffected => {
      return tasksAffected.map((task, i) => {
        const updatedTask = updatedTasksAffected[i];
        if (updatedTask.isPartial) return task;

        return task
          .set('isHeld', updatedTask.onHold)
          .setIn(['newDates', 'startDate'], updatedTask.newDates.startDate)
          .setIn(['oldDates', 'startDate'], updatedTask.oldDates.startDate);
      });
    })
    .set('tasksAffectedSize', affectedTasksNumber)
    .set('jobDelay', cycleTimeDifference);
}

markAsSync(toggleHoldAllDates);
export function toggleHoldAllDates(state, areAllOnHold) {
  const { cycleTimeDifference, affectedTasksNumber } = getImpactOnBulkHolding.call(this, areAllOnHold);
  const tasksAffectedUtils = this.tasksAffectedRef.current;
  const updatedTasksAffected = tasksAffectedUtils.getList();
  return state
    .update('tasksAffected', tasksAffected => {
      return tasksAffected.map((task, i) => {
        const updatedTask = updatedTasksAffected[i];
        return task
          .set('isHeld', !areAllOnHold)
          .set('isPartialHold', undefined)
          .set('selectedDate', undefined)
          .setIn(['newDates', 'startDate'], updatedTask.newDates.startDate)
          .setIn(['oldDates', 'startDate'], updatedTask.oldDates.startDate);
      });
    })
    .set('tasksAffectedSize', affectedTasksNumber)
    .set('jobDelay', cycleTimeDifference);
}

markAsSync(updateProposedDate);
export function updateProposedDate(state, taskId, newProposedDate, isStartUR) {
  const proposedDate = {};
  if (isStartUR) proposedDate.newStartDate = newProposedDate;
  else proposedDate.newEndDate = newProposedDate;

  const tasksAffectedUtils = AffectedList.createAffectedListObject(this.tasksGraph, taskId, proposedDate);
  const tasksAffected = fromJS(tasksAffectedUtils.getList());
  const { cycleTimeDifference, affectedTasksNumber } = tasksAffectedUtils.calculateNewDates();
  this.tasksAffectedRef.current = tasksAffectedUtils;
  const datePath = isStartUR ? 'proposedStartDate' : 'proposedFinishDate';
  return state
    .setIn(['task', 'changeRequest', datePath], newProposedDate)
    .set('proposedDate', proposedDate)
    .set('tasksAffected', tasksAffected)
    .set('tasksAffectedSize', affectedTasksNumber)
    .set('jobDelay', cycleTimeDifference);
}

markAsSync(updateOverdueProposedDate);
export function updateOverdueProposedDate(state, date) {
  const { task } = state.toObject();
  const isEndDate = task.get('overdue') === 'finish';
  const dateISO = formatISO(date);

  const proposedDate = {};
  if (isEndDate) proposedDate.newEndDate = dateISO;
  else proposedDate.newStartDate = dateISO;
  const tasksAffectedUtils = AffectedList.createAffectedListObject(this.tasksGraph, task.get('id'), proposedDate);
  const tasksAffected = fromJS(tasksAffectedUtils.getList());
  const { cycleTimeDifference, affectedTasksNumber } = tasksAffectedUtils.calculateNewDates();
  this.tasksAffectedRef.current = tasksAffectedUtils;

  return state
    .set('proposedDate', proposedDate)
    .set('tasksAffected', tasksAffected)
    .set('tasksAffectedSize', affectedTasksNumber)
    .set('jobDelay', cycleTimeDifference);
}

function getImpactOnHoldingSingle(state, index) {
  const { isHeld, id } = state.getIn(['tasksAffected', index]).toObject();
  const tasksAffectedUtils = this.tasksAffectedRef.current;
  if (isHeld) return tasksAffectedUtils.unholdDate(id);
  else return tasksAffectedUtils.holdDate(id);
}

function getImpactOnBulkHolding(areAllOnHold) {
  const tasksAffectedUtils = this.tasksAffectedRef.current;
  if (areAllOnHold) return tasksAffectedUtils.unholdAllDates();
  else return tasksAffectedUtils.holdAllDates();
}
