import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../theme';
import { preventCharsInNumbers, plural } from '../utils';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

const icons = {
  min: faArrowUp,
  max: faArrowDown,
  undefined: null,
};

export const InlineInputEdit = props => {
  const { labelClassName = '', labelText, value, showDaysLabel, inputSize = 'auto', disabled, ...rest } = props;
  const [isEditing, setIsEditing] = React.useState(false);

  if (isEditing) {
    return <Editor {...rest} value={value} setIsEditing={setIsEditing} />;
  }

  return (
    <SPAN
      className={labelClassName}
      onClick={disabled ? null : () => setIsEditing(true)}
      inputSize={inputSize}
      {...rest}
    >
      {showDaysLabel && value > 0 ? plural.day(value) : labelText ? labelText(value) : value}
    </SPAN>
  );
};

const SPAN = styled.span`
  ${props => `
  white-space: nowrap;
  background-color: ${colors.pearl};
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width:${props.inputSize};
  margin-left:${props.inputSize !== 'auto' ? '-8px' : 0};
  &:hover {
    background-color: ${colors.activeState};
  }
`}
`;

export const Editor = props => {
  const inputRef = React.useRef();
  const cancelRef = React.useRef();
  const acceptRef = React.useRef();
  const {
    setIsEditing,
    value,
    field,
    type,
    schema,
    update,
    preventChars,
    title,
    onBlur,
    inputClassName = 'mr-2',
    maxWidth = '300px',
    customValidation = null,
    ...rest
  } = props;
  const { register, handleSubmit, formState, errors, setError, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      [field]: value,
    },
  });

  const onSetEdit = isEdit => setIsEditing && setIsEditing(isEdit);

  const onSubmit = form => {
    const isValueValid = inputRef.current.validity.valid;
    if (!isValueValid) return;

    const isInvalid = customValidation && customValidation(form[field], setError);
    if (isInvalid) return;

    update(field, form[field], title)
      .then(() => {
        onSetEdit(false);
        reset({ [field]: value });
      })
      .catch(error => {
        if (error.message === 'name-already-exists') {
          setIsEditing(true);
          setError('name', { type: 'notMatch', message: 'Name already exists' });
        }
      });
  };

  const onCancel = e => {
    e.preventDefault();
    onSetEdit(false);
    reset({ [field]: value });
  };

  React.useEffect(() => {
    const input = inputRef.current;
    const accept = acceptRef.current;
    const cancel = cancelRef.current;
    const onKeyPress = e => {
      if (e.key === 'Escape') {
        onSetEdit(false);
      }
      if (preventChars) preventCharsInNumbers(e);
    };
    const onMouseDown = e => {
      const { path } = e;
      const inPath = element => path.indexOf(element) >= 0;

      if (path && !inPath(input) && !inPath(accept) && !inPath(cancel)) {
        e.preventDefault();
        onSetEdit(false);
      }
    };

    input.focus();
    input.addEventListener('keydown', onKeyPress);
    global.document.body.addEventListener('mousedown', onMouseDown);

    return () => {
      input.removeEventListener('keydown', onKeyPress);
      global.document.body.removeEventListener('mousedown', onMouseDown);
    };
  }, [inputRef, preventChars, onSetEdit]);

  const icon = field !== 'lotNumber' && icons[errors[field]?.type];
  const isInvalid = !!errors[field];

  return (
    <Form
      noValidate
      validated={formState.isValid && formState.submitCount > 0}
      onSubmit={handleSubmit(onSubmit)}
      className={inputClassName}
    >
      <Form.Row style={{ maxWidth }}>
        <Form.Control
          {...rest}
          ref={e => {
            register(e);
            inputRef.current = e;
          }}
          type={type || 'text'}
          name={field}
          isInvalid={isInvalid}
          autoComplete="off"
          onBlur={({ target }) => {
            const { isDirty } = formState;
            isDirty && onBlur && onBlur(target.value, setError);
          }}
        />
        <div className={cn('d-flex w-100', { 'justify-content-end': !isInvalid })}>
          <Form.Control.Feedback className={cn({ 'd-block': isInvalid, 'd-none': !isInvalid })} type="invalid">
            <FontAwesomeIcon icon="circle-exclamation" />
            {` `}
            {icon && <FontAwesomeIcon icon={icon} />}
            {errors[field] && ` ${errors[field]?.message}`}
          </Form.Control.Feedback>
          <div className="d-flex flex-row justify-content-end">
            <IconButton disabled={!formState.isValid} type="submit" ref={acceptRef}>
              <span>
                <FontAwesomeIcon icon="check" />
              </span>
            </IconButton>
            <IconButton onClick={onCancel} ref={cancelRef}>
              <span>
                <FontAwesomeIcon icon="xmark" />
              </span>
            </IconButton>
          </div>
        </div>
      </Form.Row>
    </Form>
  );
};

const IconButton = styled.button`
  border-style: none;
  background-color: transparent;
  color: ${colors.blue300};
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 4px;

  &:disabled {
    color: ${colors.blue_30};
  }

  > span {
    width: 25px;
    height: 25px;
    display: inline-block;
    background-color: ${colors.gray};
    border-radius: 30px;
    border: solid 1px ${colors.white};
    box-shadow: 0px 1px 2px ${colors.gray700};
  }

  &:hover {
    background-color: ${colors.white};
  }
`;
