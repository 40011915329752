import React from 'react';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { EMPTY_ROW, renderCell, TableRowDropDown } from '@tradetrax/web-common';
import { EmptyState, EmptyStateCenterContainer } from '@tradetrax/web-common/lib/EmptyState';
import { useAppContext } from 'app/App.context';
import { ShowRulesModal } from '@tradetrax/web-common/lib/Modal';
import { MINIMUM_BATCH_SIZE, RULE_MAP } from './OuttakeController';
import { TASK_TYPE } from '@tradetrax/web-common/lib/OuttakeIntake/TasksBySelect';
import cn from 'classnames';
import { MultiFamilyIcon } from '@tradetrax/web-common/lib/Popover/MultiFamily.icons.popover';

const COL_WIDTHS = {
  action: 32,
  rule: 16 * 16,
  span: 32,
  tasksNumber: 10 * 16,
};
const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

export function OuttakeTasksByType({ tab, state, controller, loaderRef }) {
  const { hasPermission } = useAppContext();
  const { modal } = useAppContext();
  const { tasks, totalCount, maxCount } = state.get('tasksByType').toObject();
  const communityId = state.getIn(['community', '_id']);
  const isEmpty = totalCount === 0;

  const rowClassName = rowData => (!rowData || rowData === EMPTY_ROW ? 'loading' : '');
  const loadMoreRows = params => controller.readCommunityTasksByType({ ...params, communityId });
  const isEnableUserAssignment = tab === 'account' || hasPermission('task_user_assignment');
  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!tasks.get(index)}
        loadMoreRows={loadMoreRows}
        rowCount={Math.min(maxCount, totalCount)}
        threshold={0}
        ref={loaderRef}
        minimumBatchSize={MINIMUM_BATCH_SIZE}
      >
        {({ onRowsRendered, registerChild }) => (
          <div style={{ flex: '1 1 auto', overflowY: 'hidden' }} className={cn({ 'd-none': isEmpty })}>
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  className="trx-table stickyTable stickyColumn2"
                  estimatedRowSize={80}
                  headerHeight={40}
                  height={height}
                  onRowsRendered={onRowsRendered}
                  overscanRowCount={2}
                  ref={registerChild}
                  rowCount={Math.min(maxCount, totalCount)}
                  rowClassName={({ index }) => {
                    const className = rowClassName(tasks.get(index));
                    if (index < 0) return className;
                    return `${className} ${
                      state
                        .getIn(['selectedTasks', tab, 'taskNames'])
                        .find(taskName => taskName === tasks.getIn([index, 'name']))
                        ? 'selected'
                        : ''
                    }`;
                  }}
                  rowGetter={({ index }) => tasks.get(index) || EMPTY_ROW}
                  rowHeight={80}
                  width={width}
                >
                  <Column dataKey="" label="" width={COL_WIDTHS.span} />
                  <Column
                    label="# of Tasks"
                    dataKey="numberOfTasks"
                    width={COL_WIDTHS.tasksNumber}
                    cellRenderer={renderCell(({ cellData, rowData }) => (
                      <div className="d-flex align-items-center">
                        {isEnableUserAssignment && (
                          <input
                            className="mr-2"
                            type="checkbox"
                            checked={
                              !!state
                                .getIn(['selectedTasks', tab, 'taskNames'])
                                .find(taskName => taskName === rowData.get('name'))
                            }
                            onChange={({ target }) => {
                              controller.onSelectCheckbox({
                                checked: target.checked,
                                tasksBy: TASK_TYPE,
                                task: rowData,
                                tab,
                              });
                            }}
                          />
                        )}
                        <span className="text-truncate font-weight-bold ml-2">{cellData}</span>
                      </div>
                    ))}
                  />
                  <Column
                    label="Task Name"
                    dataKey="name"
                    width={width - WIDTHS}
                    cellRenderer={renderCell(({ cellData, rowData }) => (
                      <div className="d-flex align-items-center">
                        <span className="text-truncate font-weight-bold">{cellData}</span>
                        <MultiFamilyIcon task={rowData} />
                      </div>
                    ))}
                  />
                  <Column
                    label="Rule"
                    dataKey="rule"
                    width={width - WIDTHS}
                    cellRenderer={renderCell(({ cellData, rowData }) => {
                      const assigneePath = RULE_MAP[tab];
                      const path = assigneePath === 'assigneeAccountName' ? [assigneePath] : [assigneePath, 'name'];
                      const ruleAssignee = cellData ? cellData.getIn(path) : null;
                      return (
                        <>
                          {ruleAssignee && (
                            <span className="d-flex align-content-center text-truncate ">
                              <span className="text-muted mr-1">Rule:</span>
                              <button
                                className="btn btn-link font-size-14 p-0"
                                onClick={() =>
                                  modal.open(ShowRulesModal, {
                                    taskType: rowData,
                                    tab,
                                    communityName: state.getIn(['community', 'name']),
                                    ruleMap: RULE_MAP,
                                  })
                                }
                              >
                                {ruleAssignee}
                              </button>
                            </span>
                          )}
                          {!ruleAssignee && <span className="text-truncate text-muted">No Rule Set</span>}
                        </>
                      );
                    })}
                  />
                  <Column
                    label=""
                    dataKey=""
                    width={COL_WIDTHS.action}
                    cellRenderer={renderCell(({ rowData }) => (
                      <TableRowDropDown
                        disabled={!rowData.getIn(['rule', RULE_MAP[tab]])}
                        removeRule
                        onSelect={() => controller.removeRule({ taskType: rowData, ruleType: RULE_MAP[tab] })}
                      />
                    ))}
                  />
                  <Column dataKey="" label="" width={COL_WIDTHS.span} />
                </Table>
              )}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
      {isEmpty && (
        <EmptyStateCenterContainer>
          <EmptyState
            icon="wrench"
            title="No Tasks Added"
            body="When you add a Task to a Job on this Community it will appear here."
          />
        </EmptyStateCenterContainer>
      )}
    </>
  );
}
