import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

export const PopoverIcon = ({ icon, children, className = 'text-secondary ml-2 small' }) => {
  const popover = (
    <Popover className="trx-popover">
      <Popover.Content>{children}</Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger placement="bottom" overlay={popover} open className="cursor-pointer">
      <FontAwesomeIcon icon={icon} className={cn(className, 'cursor-pointer')} />
    </OverlayTrigger>
  );
};
