import React, { useState } from 'react';
import { Datepicker, Impact, PrimaryButton, useTimeAgo } from '@tradetrax/web-common';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TaskChangeAssignee } from './TaskChangeAssignee';
import { TaskPreConstructionCheckbox } from './Task.PreConstruction.checkbox';
import {
  datepickerFormat,
  mongoToTrx,
  mongoToTextYearComparison,
  getMinEndDate,
  hasPermissionForAssignedTask,
  isDifferentYear,
  isURDateOverdue,
} from '@tradetrax/web-common/lib/utils';
import { useAppContext } from 'app/App.context';
import WarningCriticalMsg, {
  CriticalDay,
  criticalProposedDateClass,
  showWarningCriticalMsg,
} from '@tradetrax/web-common/lib/CriticalPath/WarningCriticalMsg';
import { CycleTimeImpact } from '@tradetrax/web-common/lib/CycleTimeImpact/RootCause/CycleTime.impact';
import { UpdateRequestState } from '@tradetrax/web-common/lib/CycleTimeImpact/UpdateRequest.state';
import { OverdueUpdateMsg } from './TaskExpStartRequest';

export function TaskExpFinishRequest({ task, controller, jobContext }) {
  const { appState, hasPermission } = useAppContext();
  const urState = UpdateRequestState({ task });
  const userId = appState.getIn(['user', '_id']);
  const changeRequest = task.get('changeRequest');
  const isTaskDelayed = (task.get('daysBehind') || 0) > 0;
  const isDateOverdue = isURDateOverdue(task, task.get('expectedFinishDate'));
  const { jobDelay, proposedFinishDate, hourRange } = changeRequest.toObject();
  const isJobDelayed = (jobDelay || 0) > 0;
  const numberTaskAffected = changeRequest.get('affectedTasks').size;
  const propFinishDate = datepickerFormat(mongoToTrx(proposedFinishDate));
  const minEndDate = getMinEndDate(task.get('startDate'));
  const canManageUpdateRequest =
    hasPermission('manage_update_request') ||
    hasPermissionForAssignedTask('manage_update_request', hasPermission, task, userId);
  const [shouldDisplayYear, setShouldDisplayYear] = useState(isDifferentYear(propFinishDate));
  const endDate = task.get('expectedFinishDate') ? datepickerFormat(mongoToTrx(task.get('expectedFinishDate'))) : '';
  const lateEndDate = task.get('lateEndDate') || undefined;
  const criticalEndDate = new Date(lateEndDate);

  return (
    <div style={{ width: '32rem' }} className="mb-4">
      <TaskPreConstructionCheckbox controller={controller} task={task} canEdit={false} className="mt-3" />
      <TaskChangeAssignee
        task={task}
        controller={controller}
        hasUpdateRequest={!!changeRequest}
        jobContext={jobContext}
      />
      <span className="h5 mb-0 font-weight-bold mt-4 pt-3">Update Request</span>
      <OverdueUpdateMsg className="my-3" task={task} />
      <Impact>
        <li>
          <label>Proposed Finish</label>
          {canManageUpdateRequest && (
            <div style={{ width: '130px' }}>
              <Datepicker
                name="propFinishDate"
                selected={propFinishDate}
                minDate={minEndDate}
                dateFormat={`MMM dd${shouldDisplayYear ? ', yyyy' : ''}`}
                onChange={date => {
                  setShouldDisplayYear(isDifferentYear(date));
                  if (date) controller.updateExpFinishDateRequest({ date, task });
                }}
                onFocus={() => setShouldDisplayYear(true)}
                onBlur={({ target }) => setShouldDisplayYear(isDifferentYear(Date.parse(target.value)))}
                placeholderText=""
                dayClassName={date => criticalProposedDateClass(date, propFinishDate, endDate, criticalEndDate)}
                renderDayContents={(day, date) => CriticalDay(day, date, criticalEndDate, propFinishDate)}
                calendarClassName="react-datepicker__critical"
              >
                <WarningCriticalMsg showMsg={showWarningCriticalMsg(propFinishDate, criticalEndDate)} />
              </Datepicker>
            </div>
          )}
          {!canManageUpdateRequest && (
            <span className={cn({ 'text-danger': isTaskDelayed })}>
              {mongoToTextYearComparison(proposedFinishDate)}
            </span>
          )}
        </li>
        <li className={cn({ 'text-danger': isDateOverdue })}>
          <label>{`${isDateOverdue ? 'Missed' : 'Expected'} Finish`}</label>
          <span>{mongoToTextYearComparison(task.get('expectedFinishDate'))}</span>
        </li>
        <li className={cn({ 'd-none': !hourRange })}>
          <label>Hour Range</label>
          <span className="text-gray-400">{hourRange}</span>
        </li>
        <li
          onClick={() => numberTaskAffected && controller.openTasksAffectedModal(task)}
          className={cn({
            'text-danger clickable': numberTaskAffected,
          })}
        >
          <label>Tasks Affected</label>
          <span className={cn('d-flex align-items-center', { 'text-gray-400': !numberTaskAffected })}>
            {numberTaskAffected}{' '}
            <FontAwesomeIcon
              icon="chevron-right"
              className={cn('text-dark font-size-12 ml-1', { 'd-none': !numberTaskAffected })}
            />
          </span>
        </li>
        <CycleTimeImpact updateRequestState={urState} disabled={!canManageUpdateRequest} />
        <li className="border-top">
          <span className={cn('font-weight-bold', { 'text-gray-200': !canManageUpdateRequest })}>Due to Weather</span>
          <input
            style={{ width: '18px', height: '18px' }}
            type="checkbox"
            checked={urState.isDueWeather}
            onChange={() => urState.setValue('isDueWeather', !urState.isDueWeather)}
            disabled={!canManageUpdateRequest}
          />
        </li>
      </Impact>
      <div className="small d-flex flex-row mb-3">
        <span className="text-muted mr-1">Update request by: </span>
        <span className="text-dark">{task.getIn(['changeRequest', 'createdByName'])}</span>
        <span className="text-muted mr-3 ml-auto"> {useTimeAgo(changeRequest.get('createdOn'))}</span>
      </div>
      {isJobDelayed && (
        <span className="font-size-14 mt-3 text-gray-400">
          By confirming this Update Request will <strong>delay the finish date for the Job</strong>. Please double-check
          before confirming.
        </span>
      )}
      {canManageUpdateRequest && (
        <div className="mt-4 d-flex flex-row">
          <PrimaryButton
            variant="secondary"
            className="mr-3"
            onClick={() => controller.cancelExpFinishDateRequest({ task })}
          >
            Decline
          </PrimaryButton>
          <PrimaryButton
            className="ml-3"
            disabled={urState.hasErrors}
            onClick={() => controller.acceptExpFinishDateRequest(urState.payload)}
          >
            Accept Date Change
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}
