import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, Impact } from '@tradetrax/web-common';
import { PrimaryButton } from '@tradetrax/web-common/lib/Button';
import { PopoverStickOnHover } from '@tradetrax/web-common/lib/Popover';
import { plural, mongoToText, hasPermissionForAssignedTask } from '@tradetrax/web-common/lib/utils';
import { useAppContext } from 'app/App.context';
import { DatepickerLabel } from './DatepickerLabel';
import { MultiFamilyIcon } from '@tradetrax/web-common/lib/Popover/MultiFamily.icons.popover';

export function TasksAffectedView({ close, state, controller, ...rest }) {
  const { appState, hasPermission } = useAppContext();
  const userId = appState.getIn(['user', '_id']);
  const { task, tasksAffected, jobDelay, tasksAffectedSize, showBackButton } = state.toObject();
  const { isUpdateRequest } = rest;
  const canEditUpdateRequest = hasPermissionForAssignedTask('manage_update_request', hasPermission, task, userId);
  const canUpdateJobDependenciesAndDates = hasPermission('job_update_task_dependencies_and_dates');

  const isExceptions = (canUpdateJobDependenciesAndDates && isUpdateRequest) || !canUpdateJobDependenciesAndDates;
  const canManageTasksAffected = (canUpdateJobDependenciesAndDates && canEditUpdateRequest) || !isExceptions;
  const isForReferenceOnly =
    canUpdateJobDependenciesAndDates && appState.getIn(['user', 'permissions', 'manage_update_request']) === 'assigned';
  const isJobDelayed = (jobDelay || 0) > 0;
  const jobImpactText =
    jobDelay === 0 ? 'Cycle Time Impact' : isJobDelayed ? 'Increase Cycle Time' : 'Reduce Cycle Time';
  const areAllOnHold = tasksAffected.every(task => task.get('isHeld'));
  const areTaksAffected = tasksAffectedSize > 0;

  const toggleHoldDate = index => controller.toggleHoldDate(index);
  const setPartialHoldDate = (task, date) => controller.setPartialHoldDate(task, date);
  const toggleHoldAllDates = () => controller.toggleHoldAllDates(areAllOnHold);
  const nextView = () => {
    const { isUpdateRequest, isOverdue } = rest;
    if (isUpdateRequest) controller.showUpdateRequestView();
    else if (isOverdue) controller.showChangeDateView();
    else controller.showImpactView();
  };

  const isHoldDisabled =
    isUpdateRequest &&
    task.getIn(['changeRequest', 'newEndDateProposed']) &&
    task.getIn(['changeRequest', 'type']) === 'new-start-date-request';

  return (
    <>
      <Modal.Header closeButton>
        <div className="d-flex flex-column align-items-start ">
          <button
            onClick={() => controller.goBack(rest)}
            className={cn('btn text-muted position-relative', { 'd-none': !showBackButton })}
            style={{ bottom: '0.5rem', right: '0.5rem' }}
          >
            <FontAwesomeIcon icon="arrow-left" />
          </button>
          <Modal.Title className={cn({ 'mt-3': !showBackButton, 'mt-0': showBackButton })}>Tasks Affected</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {isHoldDisabled ? (
          <p className="mb-3">
            Due to the configuration of dependencies, go to the Job schedule to hold the dates of the Tasks affected.
          </p>
        ) : (
          <p className="mb-3">
            Click on “Hold Date” to detach the Tasks with direct dependencies on <strong>{task.get('name')}</strong> so
            their dates are not directly affected.
          </p>
        )}
        <div>
          <Impact>
            <li>
              <div
                className={cn('d-flex flex-row align-items-center', {
                  'text-danger': areTaksAffected,
                  'text-dark': !areTaksAffected,
                })}
              >
                <label className={cn('mb-0 mr-3', { 'font-weight-normal': tasksAffectedSize === 0 })}>
                  Tasks Affected
                </label>
                <span className={cn({ 'text-gray-400': tasksAffectedSize === 0 })}>{tasksAffectedSize}</span>
              </div>
              <span style={{ width: '1px', height: '2.5rem', backgroundColor: `${colors.gray100}` }} />
              <div
                className={cn('d-flex flex-row align-items-center', {
                  'text-danger': isJobDelayed,
                  'text-success': !isJobDelayed && jobDelay !== 0,
                })}
              >
                <label className={cn('mb-0 mr-3', { 'font-weight-normal': jobDelay === 0 })}>{jobImpactText}</label>
                <span className={cn({ 'text-gray-400': jobDelay === 0 })}>{plural.day(Math.abs(jobDelay))}</span>
              </div>
            </li>
          </Impact>
          <Header className="d-flex flex-row mt-4">
            <span className="text-gray-400">Task</span>
            <span className={cn('text-gray-400', { 'd-none': isHoldDisabled })}>Exp. Start</span>
            <span className={cn({ 'd-none': isHoldDisabled })}>
              <div
                onClick={toggleHoldAllDates}
                className={cn('font-weight-bold py-0 pr-2 mr-1', {
                  'd-none': !canManageTasksAffected,
                  'clickable-text pb-1 text-gray-400': areAllOnHold,
                  'btn btn-link': !areAllOnHold,
                })}
              >{`${areAllOnHold ? 'Remove Hold from all Dates' : 'Hold all Dates'}`}</div>
            </span>
          </Header>
          <Container className="d-flex flex-column">
            {tasksAffected.map((taskAffected, index) => (
              <TaskRow
                key={index}
                taskAffected={taskAffected}
                task={task}
                index={index}
                toggleHoldDate={() => toggleHoldDate(index)}
                setPartialHoldDate={date => setPartialHoldDate(taskAffected, date)}
                canManageTasksAffected={canManageTasksAffected}
                isHoldDisabled={isHoldDisabled}
              />
            ))}
          </Container>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={cn({ 'd-none': isHoldDisabled, 'd-flex': !isHoldDisabled })}>
          {canManageTasksAffected ? (
            <>
              <PrimaryButton variant="secondary" onClick={() => close()}>
                Cancel
              </PrimaryButton>
              <PrimaryButton
                onClick={() => nextView()}
                className="ml-4"
                disabled={!tasksAffected.some(task => task.get('isHeld') || task.get('isPartialHold'))}
              >
                Next
              </PrimaryButton>
            </>
          ) : (
            <div className="d-flex justify-content-between align-items-center">
              <span className="font-size-14  text-muted mr-3">
                {!isExceptions || isForReferenceOnly
                  ? 'For reference only.'
                  : "You don't have permissions to modify these dates."}
              </span>
              <PrimaryButton onClick={() => close()} className="ml-4">
                Okay
              </PrimaryButton>
            </div>
          )}
        </div>
      </Modal.Footer>
    </>
  );
}

const TaskRow = ({
  task,
  taskAffected,
  toggleHoldDate,
  setPartialHoldDate,
  canManageTasksAffected,
  isHoldDisabled,
}) => {
  const { isHeld, isPartialHold } = taskAffected.toObject();
  const field = isHeld ? 'oldDates' : 'newDates';
  const fromDate = taskAffected.getIn(['originalDates', 'startDate']);
  const toDate = taskAffected.getIn([field, 'startDate']);
  const isDirect = taskAffected.get('isDirectSuccesor');
  const isMultiFamily = !!taskAffected.get('multiFamily');
  let showToDate = !isHeld;
  if (isHeld) {
    const { oldDates, originalDates } = taskAffected.toJS();
    showToDate = oldDates.startDate !== originalDates.startDate;
  }

  return (
    <div className="d-flex flex-row align-items-center element" style={{ height: '70px' }}>
      <div className="w-58 mr-4">
        <span className={cn('text-truncate', { 'multi-family': isMultiFamily })}>{taskAffected.get('name')}</span>
        <MultiFamilyIcon isMultiFamily={isMultiFamily} className="text-secondary small cursor-pointer mt-n3" />
      </div>
      <div className="d-flex flex-row align-items-center justify-content-between pl-3 w-100">
        <div className={cn({ 'd-none': isHoldDisabled })}>
          <span className={cn({ 'd-none': isPartialHold })}>{`${mongoToText(fromDate)}`}</span>
          {showToDate && (
            <>
              <FontAwesomeIcon
                icon="arrow-right"
                className={cn('text-gray-400 ml-2 mr-2 font-size-12', { 'd-none': isPartialHold })}
              />
              <DatepickerLabel
                name="toDate"
                fromDate={fromDate}
                selected={taskAffected.get('selectedDate')}
                toDate={toDate}
                readOnly={!isDirect || isHeld}
                setPartialHoldDate={setPartialHoldDate}
              />
              {isHeld && (
                <PopoverStickOnHover placement="bottom" popover={heldTaskPopover} className="d-inline" width={248}>
                  <FontAwesomeIcon icon="square-info" className="ml-2 text-primary cursor-pointer" />
                </PopoverStickOnHover>
              )}
            </>
          )}
        </div>
        <div className={cn({ 'd-none': !canManageTasksAffected || isHoldDisabled })}>
          {isDirect ? (
            <HoldDateToggleButton
              taskAffected={taskAffected}
              toggleHoldDate={toggleHoldDate}
              setPartialHoldDate={setPartialHoldDate}
            />
          ) : (
            <>
              {isHeld ? (
                <span className="text-gray-200 font-weight-bold mr-3 py-1">Held</span>
              ) : (
                <PopoverStickOnHover placement="bottom-end" popover={indirectDepPopover(task, taskAffected)}>
                  <span className="btn btn-link">
                    <FontAwesomeIcon icon="square-info" className="mr-1 text-primary" />
                  </span>
                </PopoverStickOnHover>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const HoldDateToggleButton = ({ taskAffected, toggleHoldDate, setPartialHoldDate }) => {
  const { isHeld, isPartialHold } = taskAffected.toObject();
  const removePartialHold = () => {
    setPartialHoldDate(taskAffected.getIn(['newDates', 'startDate']));
  };
  if (isPartialHold)
    return (
      <span
        onClick={() => removePartialHold(taskAffected)}
        className="font-weight-bold text-gray-400 hover-link partial-hold py-1 px-3"
      />
    );
  if (isHeld) return <span onClick={toggleHoldDate} className="font-weight-bold text-gray-400 hover-link py-1 px-3" />;

  return (
    <span onClick={toggleHoldDate} className="btn btn-link font-weight-bold">
      Hold Date
    </span>
  );
};

const heldTaskPopover = <div className="p-3 pr-0">New Exp. Start is set based on dependencies to other Tasks.</div>;

const indirectDepPopover = task => {
  // const isDirect = taskAffected.get('isDirectSuccesor');
  // let dependencyTasks = [];
  // if (!isDirect) {
  //   const id = taskAffected.get('id');
  //   const tasksAffected = task.getIn(['changeRequest', 'affectedTasks']);
  //   dependencyTasks = tasksAffected.reduce((memo, current) => {
  //     if (current.get('affectsTasks').includes(id) && !memo.find(task => task.get('id') === id))
  //       return [...memo, current];
  //     return memo;
  //   }, []);
  // }

  // LEAVE THIS: Maybe will be used later.
  //   if (dependencyTasks.length === 1) text += `${dependencyTasks[0].get('name')} date.`;
  //   else
  //     text = (
  //       <>
  //         {`${text} following Tasks dates:`}
  //         <ul className="pl-4">
  //           {dependencyTasks.map(task => (
  //             <li>{task.get('name')}</li>
  //           ))}
  //         </ul>
  //       </>
  //     );

  return (
    <div className="p-3">
      This Task doesn’t have a direct dependency on <strong>{task.get('name')}</strong>. To keep the original date of
      this Task, hold the remaining Tasks Affected dates.
    </div>
  );
};

const Container = styled.div`
  max-height: 19.8rem;
  overflow: scroll;
  .header {
    border-bottom: 1px solid ${colors.gray100};
  }
  .element {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${colors.gray100};
    .w-58 {
      width: 58% !important;

      span.multi-family {
        width: 195px;
        display: inline-block;
      }
    }
    .hover-link {
      &:after {
        content: 'Held';
      }
      &.partial-hold:after {
        content: 'Partially Held';
      }
    }
    .hover-link:hover {
      color: ${colors.gray800};
      cursor: pointer;
      text-decoration: underline;
      &:after {
        content: 'Remove Hold';
      }
    }
  }
`;

const Header = styled.div`
  border-bottom: 1px solid ${colors.gray100};
  span:nth-child(1) {
    width: 51%;
  }
  span:nth-child(2) {
    width: 18%;
  }
  span:nth-child(3) {
    text-align: right;
    width: 54%;
    padding-top: 0;
  }
  .clickable-text:hover {
    color: ${colors.gray800};
    cursor: pointer;
    text-decoration-line: underline;
  }
`;
