import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EMPTY_ROW, renderCell, TableRowDropDown, EmptyState } from '@tradetrax/web-common';
import { SubTasksList, getRowHeightListView, TaskNameContainer } from '@tradetrax/web-common/lib/Stages';
import { emptyState, getPredecessorsString } from './TemplateDetailsContext';
import { TaskDependencies } from '../TaskDependencies';
import { TaskDurationInput } from '../../Job/TaskDurationInput';
import { MultiFamilyHeaderIcon } from '@tradetrax/web-common/lib/Popover/MultiFamily.icons.popover';
import { EventHandlers } from './EventHandlers.shared';

export const getWidth = (width, small, medium, large) => {
  if (width < 992) return small;
  if (width < 1200) return medium;
  return large;
};

const hasMultiFamilyTasks = template => {
  return template.get('tasks').some(t => t.get('isMultiFamily'));
};

export function TemplateTasks({ context }) {
  const { state, controller, modal, tableRef } = context;
  const template = state.get('template');
  const expandedRows = state.getIn(['expandedRows', 'list']);
  const [editingRow, setEditingRow] = useState(null);
  const [defaultEditField, setDefaultEditField] = useState(null);
  const tasks = template.get('tasks');
  const totalCount = template === emptyState.get('template') ? template.get('tasksTotalCount') : tasks.size;
  const isEmpty = totalCount === 0;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const durationWidth = getWidth(windowWidth, 80, 160, 200);
  const preConstructionWidth = getWidth(windowWidth, 80, 160, 200);
  const multiFamilyWidth = getWidth(windowWidth, 80, 160, 200);
  const dependencieWidth = getWidth(windowWidth, 80, 160, 250);
  const taskNameWidth = getWidth(windowWidth, 280, 380, 400);
  const onUse = template.get('onUse');
  const isLocked = onUse && hasMultiFamilyTasks(template);
  const { onPreConstructionChange, onMultiFamilyChange } = EventHandlers({ controller });

  const onClickDependencies = task => {
    controller.removeMissingReference(task, template.get('_id'));
    modal.open(TaskDependencies, { controller, taskOrStage: task, tasks, template });
  };

  useEffect(() => {
    if (editingRow !== null) {
      const onClick = e => {
        const { target } = e;
        if (target.tagName === 'INPUT') return;

        let element = target;
        while (element) {
          const { classList } = element;
          if (classList.contains('react-datepicker')) return;
          if (classList.contains('rbt-menu')) return;
          element = element.parentElement;
        }
        setEditingRow(null);
        setDefaultEditField(null);
      };

      window.addEventListener('click', onClick);
      return () => window.removeEventListener('click', onClick);
    }
  }, [setEditingRow, setDefaultEditField, editingRow]);

  const cancelEdit = () => {
    setEditingRow(null);
    setDefaultEditField(null);
  };

  const onEditFieldClick = (index, field) => e => {
    e.preventDefault();
    e.stopPropagation();
    setEditingRow(index);
    setDefaultEditField(field);
  };

  useEffect(() => {
    if (editingRow === null || defaultEditField === null) return;

    const [row] = window.document.getElementsByClassName('editing-row');
    if (!row) return;

    let [element] = row.getElementsByClassName(`edit-${defaultEditField}`);
    if (!element) return;

    if (element.tagName !== 'INPUT') {
      element = element.getElementsByTagName('input')[0];
    }

    if (element && element.tagName === 'INPUT') {
      element.focus();
    }
  }, [editingRow, defaultEditField]);

  const onToggleRow = (task, index) => controller.toggleRow('list', task, index);

  const getRowHeight = ({ index }) => getRowHeightListView(index, tasks, expandedRows);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <InfiniteLoader isRowLoaded={({ index }) => !!tasks.get(index)} loadMoreRows={() => {}} rowCount={totalCount}>
        {({ onRowsRendered, registerChild }) => (
          <div style={{ flex: '1 1 auto', overflowY: 'hidden' }} className={cn({ 'd-none': isEmpty })}>
            <FontAwesomeIcon
              id="arrow-down"
              icon="arrow-down"
              className="position-absolute d-none drag-arrow"
              style={{ zIndex: 99999, top: '-16px' }}
            />
            <FontAwesomeIcon
              id="arrow-up"
              icon="arrow-up"
              className="position-absolute d-none drag-arrow"
              style={{ zIndex: 99999, top: '-16px' }}
            />
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  ref={ref => {
                    tableRef.current = ref;
                    registerChild(ref);
                  }}
                  onRowsRendered={onRowsRendered}
                  className="trx-table ml-auto-column-4 "
                  headerHeight={40}
                  width={width}
                  height={height}
                  onRowDoubleClick={({ index }) => (isLocked ? null : setEditingRow(index))}
                  onRowClick={({ index }) => index !== editingRow && cancelEdit()}
                  overscanRowCount={2}
                  rowHeight={getRowHeight}
                  estimatedRowSize={80}
                  rowCount={totalCount}
                  rowGetter={({ index }) => tasks.get(index) || EMPTY_ROW}
                  rowClassName={({ index }) => cn({ loading: !tasks.get(index), 'editing-row': index === editingRow })}
                >
                  <Column width={32} label="" dataKey="" />

                  <Column
                    label=""
                    dataKey="rowIndex"
                    width={48}
                    className="h-100 mt-4 pt-3"
                    cellRenderer={renderCell(({ rowData }) => rowData.get('rowIndex'))}
                  />

                  <Column
                    label="Task Name"
                    dataKey="name"
                    className="h-100 mt-4 pt-3 "
                    width={taskNameWidth}
                    cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                      const subTasks = rowData.get('children');
                      const canExpand = subTasks.size > 0;
                      const isExpanded = !!expandedRows.get(String(rowData.get('id')));
                      const icon = isExpanded ? 'chevron-up' : 'chevron-down';

                      return (
                        <>
                          <div className={cn('d-flex flex-row align-items-center', { 'ml-3 pl-1': !canExpand })}>
                            {canExpand && (
                              <FontAwesomeIcon
                                icon={icon}
                                className="mr-2"
                                onClick={() => onToggleRow(rowData, rowIndex)}
                              />
                            )}
                            <TaskNameContainer
                              text={cellData}
                              width={taskNameWidth}
                              isKeyFinish={rowData.get('isKeyFinish')}
                              isKeyStart={rowData.get('isKeyStart')}
                              isPreConstruction={!!rowData.get('isPreConstruction')}
                              isMultiFamily={!!rowData.get('isMultiFamily')}
                            />
                          </div>
                          {isExpanded && (
                            <SubTasksList
                              subTasks={subTasks}
                              rowData={rowData}
                              controller={controller}
                              isTemplate
                              isLocked={isLocked}
                            />
                          )}
                        </>
                      );
                    })}
                  />

                  <Column
                    label="Duration"
                    dataKey="duration"
                    className="h-100 mt-4 pt-3"
                    width={durationWidth}
                    cellRenderer={renderCell(({ cellData, rowIndex, rowData }) => {
                      const task = rowData.set('durationDays', rowData.get('duration'));
                      if (rowIndex === editingRow) {
                        return <TaskDurationInput controller={controller} task={task} />;
                      }
                      return (
                        <span
                          onClick={isLocked ? null : onEditFieldClick(rowIndex, 'durationDays')}
                          className={cn('d-block pr-4', { editable: !isLocked, 'text-muted': isLocked })}
                        >
                          {cellData}d
                        </span>
                      );
                    })}
                  />

                  <Column
                    label="Dep."
                    dataKey="predecessors"
                    className="h-100 mt-4 pt-3"
                    width={dependencieWidth}
                    cellRenderer={renderCell(({ cellData, rowData }) => {
                      const predecessorsString = getPredecessorsString(cellData, tasks);
                      return (
                        <>
                          <span
                            className={isLocked ? 'text-muted' : 'editable'}
                            onClick={isLocked ? null : () => onClickDependencies(rowData)}
                          >
                            {predecessorsString && (
                              <>
                                {predecessorsString} <FontAwesomeIcon icon="pen" />
                              </>
                            )}
                            {!predecessorsString && <FontAwesomeIcon icon="pen-to-square" />}
                          </span>
                          {rowData?.get('missingReference') && (
                            <div className="text-danger" style={{ marginLeft: '-10px' }}>
                              Missing
                            </div>
                          )}
                        </>
                      );
                    })}
                  />

                  <Column
                    label="Pre-Construction"
                    dataKey="isPreConstruction"
                    className="h-100 mt-4 pt-3"
                    width={preConstructionWidth}
                    cellRenderer={renderCell(({ cellData, rowIndex, rowData }) => {
                      const isPreConstruction = !!rowData.get('isPreConstruction');

                      return (
                        <input
                          type="checkbox"
                          className="cursor-pointer"
                          disabled={isLocked}
                          checked={isPreConstruction}
                          onChange={onPreConstructionChange(rowData)}
                        />
                      );
                    })}
                  />
                  <Column
                    label={
                      <>
                        Multi-Family
                        <MultiFamilyHeaderIcon />
                      </>
                    }
                    dataKey="isMultiFamily"
                    className="h-100 mt-4 pt-3"
                    width={multiFamilyWidth}
                    cellRenderer={renderCell(({ cellData, rowIndex, rowData }) => {
                      const isMultiFamily = !!rowData.get('isMultiFamily');

                      return (
                        <input
                          type="checkbox"
                          className="cursor-pointer"
                          disabled={isLocked || onUse}
                          checked={isMultiFamily}
                          onChange={onMultiFamilyChange({ rowData, modal, tasks })}
                        />
                      );
                    })}
                  />
                  <Column
                    label=""
                    dataKey=""
                    className="h-100 mt-4 pt-3"
                    width={32}
                    cellRenderer={renderCell(({ rowData }) => (
                      <TableRowDropDown disabled={isLocked} remove onSelect={() => controller.deleteTask(rowData)} />
                    ))}
                  />

                  <Column width={32} label="" dataKey="" />
                </Table>
              )}
            </AutoSizer>
          </div>
        )}
      </InfiniteLoader>
      {isEmpty && (
        <EmptyState
          icon="wrench"
          title="No Tasks Added"
          body="When you add a Task to this Template it will appear here."
        />
      )}
    </>
  );
}
