import moment from 'moment';
import { fromJS } from 'immutable';
import { markAsSideEffect, markAsSync } from '../useController';
import { TaskUpdateRequestModal } from './TaskUpdateRequestModal';
import { formatISO, getUserDomain, setCheckedInStatus } from '../utils';

const BATCH_SIZE = 10;
const EMPTY_GROUP = fromJS({ isLoading: true, tasks: [], totalCount: 0 });

markAsSideEffect(readTaskCounters);
export function readTaskCounters() {
  this.appController.readTaskCounters().then(({ prev, next }) => {
    // not working anymore with new counters implementation
    if (prev.get('toDo') === next.get('toDo')) this.controller.refresh();
  });
}

const getQuery = ({ filterState, ...props }) => {
  const filter = filterState.get('values').toJS();
  const query = { sort: 'status', ...props };

  if (filter.myTasks) query.myTasks = 'true';

  if (filter.taskNames?.length) query.taskNames = filter.taskNames;
  if (filter.communityIds?.length) query.communityIds = filter.communityIds;
  if (filter.assigneeAccounts?.length) query.assigneeAccounts = filter.assigneeAccounts;

  return query;
};

markAsSync(loadStartTodayTasks);
export function loadStartTodayTasks(state, start_index = 0, stop_index = BATCH_SIZE - 1) {
  const startDate = this.filterState.getIn(['sessionValues', 'selectedDate']) || 'today';
  const status = ['not-started', 'not-ready-to-start'];
  const query = getQuery({
    filterState: this.filterState,
    status,
    start_index,
    stop_index,
    startDate,
    ...this.todoQuery,
  });
  this.todoService.readTasks({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    const todayTasks = formatTasksStatus(data);

    this.invalidateCounters({ toDoStartCount: totalCount });

    this.controller.dispatch([
      state =>
        state.update('startToday', startToday =>
          startToday
            .set('isLoading', false)
            .set('totalCount', totalCount)
            .update('tasks', tasks => tasks.splice(start_index, stop_index - start_index + 1, ...todayTasks))
        ),
    ]);
  });

  return start_index === 0 ? state.set('startToday', EMPTY_GROUP) : state;
}

markAsSync(loadFinishTodayTasks);
export function loadFinishTodayTasks(state, start_index = 0, stop_index = BATCH_SIZE - 1) {
  const endDate = this.filterState.getIn(['sessionValues', 'selectedDate']) || 'today';
  const status = ['not-started', 'not-ready-to-start', 'in-progress'];
  const query = getQuery({
    filterState: this.filterState,
    status,
    start_index,
    stop_index,
    endDate,
    ...this.todoQuery,
  });
  this.todoService.readTasks({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    const todayTasks = formatTasksStatus(data, 'finishToday');

    this.invalidateCounters({ toDoEndCount: totalCount });

    this.controller.dispatch([
      state =>
        state.update('finishToday', finishToday =>
          finishToday
            .set('isLoading', false)
            .set('totalCount', totalCount)
            .update('tasks', tasks => tasks.splice(start_index, stop_index - start_index + 1, ...todayTasks))
        ),
    ]);
  });
  return start_index === 0 ? state.set('finishToday', EMPTY_GROUP) : state;
}

markAsSideEffect(loadMoreRows);
export function loadMoreRows() {
  const loadMore = (kind, load) => {
    const group = this.state.get(kind);

    if (!group) return;

    const totalCount = group.get('totalCount');
    const tasks = group.get('tasks');

    if (tasks.size < totalCount) {
      load(tasks.size, tasks.size + BATCH_SIZE - 1);
    }
  };

  loadMore('startToday', this.controller.loadStartTodayTasks);
  loadMore('finishToday', this.controller.loadFinishTodayTasks);
  if (this.isBuilder) loadMore('updateToday', this.controller.loadUpdateTodayTasks);
  if (this.isTrade) loadMore('confirmationRequests', this.controller.loadWithConfirmationRequestTasks);
}

markAsSync(loadUpdateTodayTasks);
export function loadUpdateTodayTasks(state, start_index = 0, stop_index = BATCH_SIZE - 1) {
  const startDate = this.filterState.getIn(['sessionValues', 'selectedDate']) || 'today';
  const endDate = startDate;
  const status = ['not-started', 'not-ready-to-start', 'in-progress'];
  const query = getQuery({
    filterState: this.filterState,
    isAssignedToSelfAccount: false,
    status,
    stop_index,
    start_index,
    startDate,
    endDate,
  });

  this.todoService.readTasks({}, { query }).then(data => {
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;
    const todayTasks = formatTasksStatus(data);

    this.invalidateCounters({ toDoUpdateCount: totalCount });

    this.controller.dispatch([
      state =>
        state.update('updateToday', updateToday =>
          updateToday
            .set('isLoading', false)
            .set('totalCount', totalCount)
            .update('tasks', tasks => tasks.splice(start_index, stop_index - start_index + 1, ...todayTasks))
        ),
    ]);
  });

  return start_index === 0 ? state.set('updateToday', EMPTY_GROUP) : state;
}

markAsSync(loadWithConfirmationRequestTasks);
export function loadWithConfirmationRequestTasks(state, start_index = 0, stop_index = BATCH_SIZE - 1) {
  const startDateConfirmationRequest = true;
  const query = getQuery({
    filterState: this.filterState,
    sort: 'status',
    startDateConfirmationRequest,
    start_index,
    stop_index,
    ...this.todoQuery,
  });
  this.todoService.readTasks({}, { query }).then(data => {
    const kind = 'confirmationRequests';
    const totalCount = data.metadata.pagination ? data.metadata.pagination.totalCount : 0;

    const withRequestTasks = formatTasksStatus(data, kind);

    this.invalidateCounters({ startDateConfirmationRequestCount: totalCount });

    this.controller.dispatch([
      state =>
        state.update('confirmationRequests', confirmationRequests =>
          confirmationRequests
            .set('isLoading', false)
            .set('totalCount', totalCount)
            .update('tasks', tasks => tasks.splice(start_index, stop_index - start_index + 1, ...withRequestTasks))
        ),
    ]);
  });

  return start_index === 0 ? state.set('confirmationRequests', EMPTY_GROUP) : state;
}

markAsSideEffect(loadTasksOfInterest);
export function loadTasksOfInterest(jobId, taskId) {
  return this.todoService.getJobTasksOfInterestForTask({}, { params: { jobId, taskId } }).then(fromJS);
}

markAsSync(changeStatus);
export function changeStatus(state, task, kind, status) {
  const tasks = state.getIn([kind, 'tasks']);
  const index = tasks.indexOf(task);
  const path = [kind, 'tasks', index];
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const setDay = setDayLabel(kind);

  this.todoService
    .updateTask({ status }, { params: { jobId, taskId } })
    .then(({ ctr, ...updatedTask }) => {
      this.controller.dispatch([
        state => state.updateIn(path, task => task && setDay(task.merge(fromJS(updatedTask)))),
      ]);
      this.controller.readTaskCounters();
    })
    .catch(() => {
      this.addAlert('There was a problem updating this Task. Please try again.', 'danger');
      this.controller.dispatch([state => state.setIn(path, task)]);
    });

  return state.setIn([...path, 'status'], status);
}

markAsSideEffect(confirmDate);
export function confirmDate(task) {
  const kind = 'confirmationRequests';
  const tasks = this.state.getIn([kind, 'tasks']);
  const index = tasks.indexOf(task);
  const path = [kind, 'tasks', index];
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const startDateConfirmed = true;

  this.todoService
    .updateTask({ startDateConfirmed }, { params: { jobId, taskId } })
    .then(() => {
      this.controller.dispatch([
        state => state.deleteIn(path).updateIn([kind, 'totalCount'], totalCount => totalCount - 1),
      ]);
      this.controller.readTaskCounters();
      this.addAlert('Start Date Confirmation successfully sent.');
    })
    .catch(err => {
      this.addAlert('There was a problem sending this Start Date Confirmation. Please try again.', 'danger');
      throw err;
    });
}

markAsSideEffect(changeDate);
export async function changeDate(task, canSendUpdateRequest) {
  const { isTrade } = getUserDomain(this.appState.get('user'));
  if (isTrade && !canSendUpdateRequest(task)) {
    this.addAlert("You don't have permission to manage Update Requests. Please contact your supervisor.", 'danger');
  } else {
    const { form, isAccept } = await this.modal.open(TaskUpdateRequestModal, { task });
    const reasonsArray = form?.reasons ? ['weather'] : [];
    if (isAccept) {
      const { startDate } = form;
      this.controller.confirmDateChangeRequest(task, formatISO(startDate), reasonsArray);
    }
  }
}

markAsSideEffect(confirmDateChangeRequest);
export function confirmDateChangeRequest(task, newStartDate, reasons) {
  const taskId = task.get('id');
  const jobId = task.getIn(['job', 'id']);
  const startDateConfirmation = !!task.get('startDateConfirmationRequest');

  this.todoService
    .sendStartDateRequest({ newStartDate, startDateConfirmation, reasons }, { params: { jobId, taskId } })
    .then(() => {
      this.addAlert('Start Date Confirmation Update Request successfully sent.');
      this.controller.readTaskCounters();
    })
    .catch(err => {
      this.addAlert('There was a problem sending this Start Date Confirmation. Please try again.', 'danger');
      throw err;
    });
}

// private utility function, do not export (not an action).
function setDayLabel(kind) {
  return task => {
    const key = `${task.getIn(['job', 'id'])}_${task.get('id')}`;
    const isFinishedToday = kind === 'finishToday';
    const isConfirmationRequest = kind === 'confirmationRequests';

    const dayLabel =
      !isConfirmationRequest && (isFinishedToday || task.get('status') === 'in-progress')
        ? `Exp. Finish ${moment(task.get('expectedFinishDate'), 'YYYY-MM-DD').format('MMM D')}`
        : `Exp. Start ${moment(task.get('startDate'), 'YYYY-MM-DD').format('MMM D')}`;

    return task.merge({ key, dayLabel });
  };
}

const formatTasksStatus = (data, label) => {
  const tasks = fromJS(data);
  return tasks.map(task => setDayLabel(label)(setCheckedInStatus(task))).toArray();
};
