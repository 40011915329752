import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Datepicker, Impact, PrimaryButton, useTimeAgo, colors } from '@tradetrax/web-common';
import cn from 'classnames';
import { TaskChangeAssignee } from './TaskChangeAssignee';
import { TaskPreConstructionCheckbox } from './Task.PreConstruction.checkbox';
import {
  datepickerFormat,
  mongoToTrx,
  hasPermissionForAssignedTask,
  mongoToTextYearComparison,
  isDifferentYear,
  isURDateOverdue,
} from '@tradetrax/web-common/lib/utils';
import { useAppContext } from 'app/App.context';
import WarningCriticalMsg, {
  CriticalDay,
  criticalClass,
  showWarningCriticalMsg,
} from '@tradetrax/web-common/lib/CriticalPath/WarningCriticalMsg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CycleTimeImpact } from '@tradetrax/web-common/lib/CycleTimeImpact/RootCause/CycleTime.impact';
import { UpdateRequestState } from '@tradetrax/web-common/lib/CycleTimeImpact/UpdateRequest.state';

export function TaskExpStartRequest({ controller, task, jobContext }) {
  const { appState, hasPermission } = useAppContext();
  const urState = UpdateRequestState({ task });
  const userId = appState.getIn(['user', '_id']);
  const changeRequest = task.get('changeRequest');
  const isTaskDelayed = (task.get('daysBehind') || 0) > 0;
  const isDateOverdue = isURDateOverdue(task, task.get('startDate'));
  const { jobDelay, proposedStartDate } = changeRequest.toObject();
  const isJobDelayed = (jobDelay || 0) > 0;
  const hasPredecessors = task.get('predecessors').size > 0;
  const numberTaskAffected = changeRequest.get('affectedTasks').size;
  const propStartDate = datepickerFormat(mongoToTrx(proposedStartDate));
  const canManageUpdateRequest =
    hasPermission('manage_update_request') ||
    hasPermissionForAssignedTask('manage_update_request', hasPermission, task, userId);
  const [shouldDisplayYear, setShouldDisplayYear] = useState(isDifferentYear(propStartDate));

  const lateStartDate = task.get('lateStartDate') || undefined;
  const criticalStartDate = new Date(lateStartDate);
  const newEndDateProposed = task?.getIn(['changeRequest', 'newEndDateProposed']);

  return (
    <div style={{ width: '32rem' }} className="mb-4" data-test-id="div-task-exp-start-request">
      <TaskPreConstructionCheckbox controller={controller} task={task} canEdit={false} className="mt-3" />
      <TaskChangeAssignee
        task={task}
        controller={controller}
        hasUpdateRequest={!!changeRequest}
        jobContext={jobContext}
      />
      <span className="h5 mb-0 font-weight-bold">Update Request</span>
      <OverdueUpdateMsg className="my-3" task={task} />
      <Impact>
        <li>
          <label>Proposed Start</label>
          {canManageUpdateRequest && (
            <div style={{ width: '130px' }}>
              <Datepicker
                name="propStartDate"
                selected={propStartDate}
                dateFormat={`MMM dd${shouldDisplayYear ? ', yyyy' : ''}`}
                onChange={date => {
                  setShouldDisplayYear(isDifferentYear(date));
                  if (date) controller.updateStartDateRequest({ date, task });
                }}
                onFocus={() => setShouldDisplayYear(true)}
                onBlur={({ target }) => setShouldDisplayYear(isDifferentYear(Date.parse(target.value)))}
                placeholderText=""
                dayClassName={date => criticalClass(date, criticalStartDate, propStartDate)}
                renderDayContents={(day, date) => CriticalDay(day, date, criticalStartDate, propStartDate)}
                calendarClassName="react-datepicker__critical"
              >
                <WarningCriticalMsg showMsg={showWarningCriticalMsg(propStartDate, criticalStartDate)} />
              </Datepicker>
            </div>
          )}
          {!canManageUpdateRequest && (
            <span className={cn({ 'text-danger': isTaskDelayed })}>{mongoToTextYearComparison(proposedStartDate)}</span>
          )}
        </li>
        <li className={cn({ 'text-danger': isDateOverdue })}>
          <label>{`${isDateOverdue ? 'Missed' : 'Expected'} Start`}</label>
          <span>{mongoToTextYearComparison(task.get('startDate'))}</span>
        </li>
        {newEndDateProposed && (
          <ProposeFinishDate
            task={task}
            canManageUpdateRequest={canManageUpdateRequest}
            controller={controller}
            isDateOverdue={isDateOverdue}
            propStartDate={propStartDate}
          />
        )}
        <li
          onClick={() => numberTaskAffected && controller.openTasksAffectedModal(task)}
          className={cn({
            'text-danger clickable': numberTaskAffected,
          })}
        >
          <label>Tasks Affected</label>
          <span className={cn('d-flex align-items-center', { 'text-gray-400': !numberTaskAffected })}>
            {numberTaskAffected}{' '}
            <FontAwesomeIcon
              icon="chevron-right"
              className={cn('text-dark font-size-12 ml-1', { 'd-none': !numberTaskAffected })}
            />
          </span>
        </li>
        <CycleTimeImpact updateRequestState={urState} disabled={!canManageUpdateRequest} />
        <li className="border-top">
          <span className={cn('font-weight-bold', { 'text-gray-200': !canManageUpdateRequest })}>Due to Weather</span>
          <input
            type="checkbox"
            checked={urState.isDueWeather}
            onChange={() => urState.setValue('isDueWeather', !urState.isDueWeather)}
            disabled={!canManageUpdateRequest}
          />
        </li>
      </Impact>
      <div className="small d-flex flex-row mb-3">
        <span className="text-muted mr-1">Update request by: </span>
        <span className="text-dark">{task.getIn(['changeRequest', 'createdByName'])}</span>
        <span className="text-muted mr-3 ml-auto"> {useTimeAgo(changeRequest.get('createdOn'))}</span>
      </div>
      {isJobDelayed && !hasPredecessors && (
        <span className="font-size-14 mt-3 text-gray-400">
          By confirming this Update Request will <strong>delay the finish date for the Job</strong>. Please double-check
          before confirming.
        </span>
      )}
      {isJobDelayed && hasPredecessors && (
        <span className="font-size-14 mt-3 text-gray-400">
          By confirming this Update Request will result in <strong>all Predecessor relationships being removed</strong>.
          It will also <strong>delay the finish date for the Job</strong>. Please double-check before confirming.
        </span>
      )}
      {hasPredecessors && !isJobDelayed && (
        <span className="font-size-14 mt-3 text-gray-400">
          By confirming this Update Request will change the Exp. Start Date for the task and will also result in{' '}
          <strong>all Predecessor relationships being removed</strong>. Please double-check before confirming.
        </span>
      )}
      {canManageUpdateRequest && (
        <div className="mt-4 d-flex flex-row">
          <PrimaryButton
            variant="secondary"
            className="mr-3"
            onClick={() => controller.cancelStartDateRequest({ task })}
          >
            Decline
          </PrimaryButton>
          <PrimaryButton
            className="ml-3"
            disabled={urState.hasErrors}
            onClick={() => controller.acceptStartDateRequest(urState.payload)}
          >
            Accept Date Change
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}

const ProposeFinishDate = ({ task, canManageUpdateRequest, controller, isDateOverdue, propStartDate }) => {
  const changeRequest = task.get('changeRequest');
  const { proposedFinishDate } = changeRequest.toObject();
  const propFinishDate = datepickerFormat(mongoToTrx(proposedFinishDate));
  const [shouldDisplayYear, setShouldDisplayYear] = useState(isDifferentYear(propFinishDate));
  const lateEndDate = task.get('lateEndDate') || undefined;
  const criticalEndDate = new Date(lateEndDate);
  const isTaskDelayed = (task.get('daysBehind') || 0) > 0;

  const [currentFinishDate, setCurrentFinishDate] = useState(
    moment(propStartDate).isAfter(moment(propFinishDate)) ? propStartDate : propFinishDate
  );

  useEffect(() => {
    if (propStartDate > currentFinishDate) {
      setCurrentFinishDate(propStartDate);
    }
  }, [currentFinishDate, propStartDate]);

  return (
    <>
      <li>
        <label>Proposed Finish</label>
        {canManageUpdateRequest && (
          <div style={{ width: '130px' }}>
            <Datepicker
              name="propFinishDate"
              selected={currentFinishDate}
              minDate={propStartDate}
              dateFormat={`MMM dd${shouldDisplayYear ? ', yyyy' : ''}`}
              onChange={date => {
                setCurrentFinishDate(date);
                setShouldDisplayYear(isDifferentYear(date));
                if (date) controller.updateExpFinishDateRequest({ date, task });
              }}
              onFocus={() => setShouldDisplayYear(true)}
              onBlur={({ target }) => setShouldDisplayYear(isDifferentYear(Date.parse(target.value)))}
              placeholderText=""
              dayClassName={date => criticalClass(date, criticalEndDate, currentFinishDate)}
              renderDayContents={(day, date) => CriticalDay(day, date, criticalEndDate, currentFinishDate)}
              calendarClassName="react-datepicker__critical"
            >
              <WarningCriticalMsg showMsg={showWarningCriticalMsg(propFinishDate, criticalEndDate)} />
            </Datepicker>
          </div>
        )}
        {!canManageUpdateRequest && (
          <span className={cn({ 'text-danger': isTaskDelayed })}>{mongoToTextYearComparison(proposedFinishDate)}</span>
        )}
      </li>
      <li className={cn({ 'text-danger': isDateOverdue })}>
        <label>{`${isDateOverdue ? 'Missed' : 'Expected'} Finish`}</label>
        <span>{mongoToTextYearComparison(task.get('expectedFinishDate'))}</span>
      </li>
    </>
  );
};

export const OverdueUpdateMsg = ({ className = '', task }) => {
  const isUpdReqFromOverdueConfirm = task.getIn(['changeRequest', 'isOverdueTask']);
  if (!isUpdReqFromOverdueConfirm) return null;
  return (
    <div className={cn('font-size-14 text-gray-400', className)}>
      <IconWrapper>
        <FontAwesomeIcon icon="calendar-exclamation" />
      </IconWrapper>
      <span>Update Request from an Overdue Task Confirmation</span>
    </div>
  );
};

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray};
  padding: 6px;
  border-radius: 50%;
  margin-right: 6px;
`;
