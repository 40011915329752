import React, { useState, useEffect, useRef } from 'react';
import { fromJS, Map } from 'immutable';
import { InfiniteLoader, AutoSizer, Table, Column } from 'react-virtualized';
import cn from 'classnames';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableRowDropDown,
  STATUS_MAP,
  ChipStatusDropdown,
  OnTime,
  EMPTY_ROW,
  renderCell,
  Typeahead,
} from '@tradetrax/web-common';
import {
  SubTasksList,
  SubTaskStatusList,
  InnerTasksList,
  DropDownActionList,
  mapStagesViewModel,
  TaskNameContainer,
  getRowHeightStagesView,
  calculateInnerCellHeight,
} from '@tradetrax/web-common/lib/Stages';
import {
  DragNDropController,
  DraggableArea,
  rowOrderMemo,
  defaultRowRenderer,
} from '@tradetrax/web-common/lib/Stages/DragNDrop';
import {
  mongoToTrx,
  isoToTrxFull,
  datepickerFormat,
  isTaskOverdue,
  getAssigneeBadgeClassName,
  getMinEndDate,
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  handleTableScroll,
  scrollbarStyles,
} from '@tradetrax/web-common/lib/utils';
import WarningCriticalMsg, {
  CriticalDay,
  criticalClass,
  showWarningCriticalMsg,
} from '@tradetrax/web-common/lib/CriticalPath/WarningCriticalMsg';
import { TaskDurationInput } from '../TaskDurationInput';
import TaskDependencies from '../TaskDependencies';
import { AssigneeList, ConfirmedList, StartDateList, EndDateList, InnerDurationList, StatusList } from './JobStages';
import { DatepickerWithPortal, readOnlyClick } from './DatepickerWithPortal';
import { PredecessorsList, AssigneeBadge } from './JobStages';
import { noRowsRenderer } from './JobDetails';
import navigate from 'app/navigate';
import { PopoverStickOnHover } from '@tradetrax/web-common/lib/Popover';

const COL_WIDTHS = {
  span: 32,
  rowNo: 2 * 16,
  duration: 5 * 16,
  confirmed: 8 * 16,
  expStart: 8 * 16,
  expFinish: 8 * 16,
  dep: 3 * 16,
  status: 160,
  action: 32,
};

const WIDTHS = Object.values(COL_WIDTHS).reduce((sum, width) => sum + width, 0);

const getTaskRowIndex = tasks => rowData => {
  const task = tasks.find(task => task.get('id') === rowData.get('id'));
  const rowIndex = task ? task.get('rowIndex') : 0;
  return rowIndex;
};

export const ScheduleStagesView = ({ jobContext, isFilterSet }) => {
  const [editingRow, setEditingRow] = useState(null);
  const [defaultEditField, setDefaultEditField] = useState(null);
  const scrollDivRef = useRef(null);
  const { state, controller, account, modal, companies, schedulerRef, hasPermission } = jobContext;
  const { job, filteredTasks: tasks, stages, expandedRows, isLoading } = state.toObject();
  const viewModel = mapStagesViewModel(fromJS({ tasks, stages }));
  const totalCount = viewModel.size;
  const canEditDatesAndPredecessors = hasPermission('job_update_task_dependencies_and_dates');
  const { controller: dndController } = DragNDropController(
    schedulerRef,
    controller.updateTaskOrder,
    controller,
    viewModel
  );
  const showDaysBehind = false;
  const fixRows = React.useMemo(() => rowOrderMemo(controller), [controller]);

  const openAddTaskModal = (stage, rowIndex) => controller.openAddTaskModal({ rowIndex, stage, jobContext });
  const changeDependencies = task => modal.open(TaskDependencies, { controller, task, tasks, job });
  const calendarRef = useRef();
  useEffect(() => {
    const calendarPortal = document.createElement('div');
    document.body.appendChild(calendarPortal);
    calendarPortal.id = 'calendar-portal';
    calendarPortal.ref = calendarRef;
    return () => {
      document.body.removeChild(calendarPortal);
    };
  }, [calendarRef]);

  useEffect(() => {
    if (editingRow !== null) {
      const onClick = e => {
        const { target } = e;
        if (target.tagName === 'INPUT') return;

        let element = target;
        while (element) {
          const { classList } = element;
          if (classList.contains('react-datepicker__navigation')) return;
          if (classList.contains('react-datepicker')) return;
          if (classList.contains('rbt-menu')) return;
          element = element.parentElement;
        }
        setEditingRow(null);
        setDefaultEditField(null);
      };

      window.addEventListener('click', onClick);
      return () => window.removeEventListener('click', onClick);
    }
  }, [setEditingRow, setDefaultEditField, editingRow]);

  const onRowClick = ({ index }) => {
    const isStage = viewModel.getIn([index, 'isStage']);
    if (viewModel.getIn([index, 'id']) !== editingRow && !isStage) {
      setEditingRow(null);
      setDefaultEditField(null);
    }
  };

  const onEditFieldClick = (taskId, field) => e => {
    e.preventDefault();
    e.stopPropagation();
    setEditingRow(taskId);
    setDefaultEditField(field);
  };
  const isEditing = taskId => taskId === editingRow && canEditDatesAndPredecessors;

  const onToggleRow = (rowData, index) => controller.toggleRow('stages', rowData, index);
  const getIsExpanded = data => {
    const isStage = data.get('isStage');
    const id = isStage ? data.get('_id') : String(data.get('id'));
    return !!expandedRows.getIn(['stages', id]);
  };

  const getRowIndex = getTaskRowIndex(tasks);

  const getRowHeight = ({ index }) => getRowHeightStagesView(index, viewModel, expandedRows);

  const getInnerCellHeight = task => calculateInnerCellHeight(task, expandedRows);

  useEffect(() => {
    if (editingRow === null || defaultEditField === null) return;

    // Not working for inner tasks
    const [row] = window.document.getElementsByClassName('editing-row');
    if (!row) return;

    let [element] = row.getElementsByClassName(`edit-${defaultEditField}`);
    if (!element) return;

    if (element.tagName !== 'INPUT') {
      element = element.getElementsByTagName('input')[0];
    }

    if (element && element.tagName === 'INPUT') {
      element.focus();
    }
  }, [editingRow, defaultEditField]);

  fixRows(tasks, viewModel);

  useEffect(() => {
    const element = scrollDivRef.current;
    if (element) {
      element.addEventListener('scroll', handleTableScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleTableScroll);
      }
    };
  }, []);

  return (
    <>
      <InfiniteLoader
        isRowLoaded={({ index }) => !!viewModel.get(index)}
        loadMoreRows={() => {}}
        rowCount={isLoading ? 7 : totalCount}
        style={{ position: 'relative' }}
      >
        {({ onRowsRendered, registerChild }) => (
          <TableContainer id="table-container" ref={scrollDivRef}>
            <AutoSizer>
              {({ width, height }) => {
                const minWidth = width > 1250 ? width : 1250;
                return (
                  <Table
                    ref={ref => {
                      registerChild(ref);
                      schedulerRef.current = ref;
                    }}
                    rowRenderer={defaultRowRenderer}
                    onRowsRendered={onRowsRendered}
                    className="trx-table  "
                    headerHeight={40}
                    width={minWidth}
                    height={height}
                    // onRowDoubleClick={({ index }) => setEditingRow(index)}
                    onRowClick={onRowClick}
                    overscanRowCount={2}
                    rowHeight={getRowHeight}
                    estimatedRowSize={80}
                    rowCount={isLoading ? 7 : totalCount}
                    rowGetter={({ index }) => viewModel.get(index) || EMPTY_ROW}
                    noRowsRenderer={noRowsRenderer(isFilterSet, isLoading)}
                    rowClassName={({ index }) => {
                      // TODO fix this for inner tasks
                      return cn({
                        loading: !viewModel.get(index),
                        'editing-row': viewModel.getIn([index, 'id']) === editingRow,
                        'is-stage': viewModel.getIn([index, 'isStage']),
                        'is-collapsed': !getIsExpanded(viewModel.get(index) || Map({ id: -1 })),
                      });
                    }}
                    onScroll={event => handleTableScroll(event, scrollDivRef)}
                  >
                    <Column width={COL_WIDTHS.span} label="" dataKey="" />
                    <Column
                      label=""
                      dataKey="rowIndex"
                      width={COL_WIDTHS.rowNo}
                      className="drag-icon h-100 mt-4 pt-3"
                      cellRenderer={renderCell(({ rowData }) => {
                        const rowIndex = getRowIndex(rowData);
                        return <DraggableArea controller={dndController} task={rowData} rowIndex={rowIndex} />;
                      })}
                    />
                    <Column
                      label="Name"
                      dataKey="name"
                      className="h-100 pt-3"
                      minWidth={280}
                      width={(minWidth - WIDTHS) / 2}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        const subTasks = rowData.get('children');
                        const isStage = rowData.get('isStage');
                        const canExpand = subTasks.size > 0 || isStage;
                        const isExpanded = getIsExpanded(rowData);
                        const icon = isExpanded ? 'chevron-up' : 'chevron-down';
                        const cellWidth = (minWidth - WIDTHS) / 2;
                        const isCritical = rowData.get('isCritical');
                        return (
                          <>
                            <div
                              className={cn('d-flex flex-row align-items-center', {
                                'ml-3 pl-1': !canExpand,
                                'mb-3': isStage,
                              })}
                            >
                              {canExpand && (
                                <span className="arrowContainer">
                                  <FontAwesomeIcon
                                    icon={icon}
                                    className="mr-2"
                                    onClick={() => onToggleRow(rowData, rowIndex)}
                                  />
                                </span>
                              )}
                              {isStage ? (
                                <div className="d-flex">
                                  <TaskNameContainer text={cellData} width={cellWidth} isCritical={false} />
                                </div>
                              ) : (
                                <TaskNameContainer
                                  to={{
                                    pathname: navigate.from.JobDetails.to.JobTaskDetails(
                                      { taskId: viewModel.getIn([rowIndex, 'id']) },
                                      false
                                    ),
                                    state: { referrer: 'schedule' },
                                  }}
                                  text={cellData}
                                  width={cellWidth}
                                  isCritical={isCritical}
                                  isPreConstruction={rowData.get('isPreConstruction')}
                                  isMultiFamily={rowData.get('isMultiFamily')}
                                />
                              )}
                            </div>
                            {isExpanded && (
                              <>
                                {isStage ? (
                                  <InnerTasksList
                                    stage={rowData}
                                    controller={controller}
                                    dndController={dndController}
                                    tableRef={schedulerRef}
                                    rowIndex={rowIndex}
                                    expandedRows={expandedRows?.get('stages')}
                                    openAddTaskModal={stage => openAddTaskModal(stage, rowIndex)}
                                    getCellHeight={getInnerCellHeight}
                                    width={cellWidth}
                                    getRowIndex={getRowIndex}
                                  />
                                ) : (
                                  <SubTasksList subTasks={subTasks} rowData={rowData} controller={controller} />
                                )}
                              </>
                            )}
                          </>
                        );
                      })}
                      style={{ marginTop: '25px' }}
                    />
                    <Column
                      label="Account Assignee"
                      dataKey="assigneeAccount"
                      className="h-100 mt-4 pt-3"
                      width={(minWidth - WIDTHS) / 2}
                      cellRenderer={renderCell(({ cellData, rowData }) => {
                        const companyId = cellData.get('companyId');
                        const isAssigned = !!companyId;
                        const isStage = rowData.get('isStage');
                        const isExpanded = isStage && getIsExpanded(rowData);
                        const taskOrStageId = isStage ? rowData.get('_id') : rowData.get('id');
                        const isAssigneeAccountEditing =
                          !isStage && taskOrStageId === editingRow && defaultEditField === 'assigneeAccount';

                        if (isAssigneeAccountEditing) {
                          const company = companies.find(c => c.get('subAccountId') === companyId);
                          const selected = company ? [company] : [];

                          return (
                            <Typeahead
                              id="account-assignee"
                              defaultValue={cellData.get('company')}
                              placeholder="Needs Assignment"
                              options={companies}
                              labelKey={option => option.get('name')}
                              selected={selected}
                              flip={true}
                              defaultOpen={defaultEditField === 'assigneeAccount'}
                              isMyId={option => account.get('_id') === option.get('subAccountId')}
                              filterSelected={(option, selected) =>
                                selected.get('subAccountId') !== option.get('subAccountId')
                              }
                              onChange={([company]) =>
                                controller.assignTask({
                                  task: rowData,
                                  subId: company ? company.get('subAccountId') : null,
                                })
                              }
                            />
                          );
                        }
                        return (
                          <>
                            {!isStage && (
                              <AssigneeBadge
                                onClick={onEditFieldClick(rowData.get('id'), 'assigneeAccount')}
                                maxWidth={(minWidth - WIDTHS) / 2}
                                className={`text-truncate ${getAssigneeBadgeClassName(
                                  cellData.get('companyId'),
                                  account.get('_id')
                                )}`}
                              >
                                {isAssigned ? cellData.get('company') : 'Needs Assignment'}
                                <FontAwesomeIcon icon="chevron-down" className="ml-1" />
                              </AssigneeBadge>
                            )}
                            {isExpanded && (
                              <AssigneeList
                                tasks={rowData.get('tasks')}
                                accountId={account.get('_id')}
                                getCellHeight={getInnerCellHeight}
                                onEditFieldClick={onEditFieldClick}
                                editingTaskId={editingRow}
                                companies={companies}
                                defaultOpen={defaultEditField === 'assigneeAccount'}
                                controller={controller}
                                maxWidth={(minWidth - WIDTHS) / 2}
                              />
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Confirmed"
                      dataKey="startDateConfirmed"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.confirmed}
                      cellRenderer={renderCell(({ cellData, rowData }) => {
                        const isStage = rowData.get('isStage');
                        const isExpanded = isStage && getIsExpanded(rowData);
                        return (
                          <>
                            {!isStage && (
                              <div
                                className={cn('text-right mr-5 pr-2', {
                                  'text-success': !!cellData,
                                  'text-gray-200': !cellData,
                                })}
                              >
                                <FontAwesomeIcon icon="check" />
                              </div>
                            )}
                            {isExpanded && (
                              <ConfirmedList tasks={rowData.get('tasks')} getCellHeight={getInnerCellHeight} />
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Exp. Start"
                      dataKey="startDate"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.expStart}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        const { isOverdueStart } = isTaskOverdue(rowData);
                        const startDate = cellData ? datepickerFormat(mongoToTrx(cellData)) : '';
                        const textStartDate = startDate ? isoToTrxFull(cellData) : 'MM/DD/YY';
                        const isStage = rowData.get('isStage');
                        const isExpanded = isStage && getIsExpanded(rowData);
                        const taskOrStageId = isStage ? rowData.get('_id') : rowData.get('id');
                        const lateStartDate = rowData.get('lateStartDate') || undefined;
                        const criticalStartDate = new Date(lateStartDate);
                        return (
                          <>
                            <DatepickerWithPortal
                              name="startDate"
                              date={startDate}
                              isDefaultOpen={defaultEditField === 'startDate'}
                              onChange={date => controller.updateTaskOrStageStartDate({ date, rowData, isStage })}
                              onChangeRaw={({ target }) =>
                                controller.updateRawStartDate({ date: target.value, rowData, isStage })
                              }
                              isOverdue={isOverdueStart}
                              dateText={textStartDate}
                              isEditing={isEditing(taskOrStageId)}
                              canEdit={!isStage && canEditDatesAndPredecessors}
                              portalRoot={calendarRef}
                              onClick={onEditFieldClick(taskOrStageId, 'startDate')}
                              dayClassName={date => criticalClass(date, criticalStartDate, startDate)}
                              renderDayContents={(day, date) => CriticalDay(day, date, criticalStartDate, startDate)}
                              calendarClassName="react-datepicker__critical"
                            >
                              <WarningCriticalMsg showMsg={showWarningCriticalMsg(startDate, criticalStartDate)} />
                            </DatepickerWithPortal>
                            {isExpanded && (
                              <StartDateList
                                tasks={rowData.get('tasks')}
                                canEdit={canEditDatesAndPredecessors}
                                calendarRef={calendarRef}
                                controller={controller}
                                getCellHeight={getInnerCellHeight}
                                onEditFieldClick={onEditFieldClick}
                                editingTaskId={editingRow}
                                defaultEditField={defaultEditField}
                              />
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Exp. Finish"
                      dataKey="expectedFinishDate"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.expFinish}
                      cellRenderer={renderCell(({ cellData, rowData, rowIndex }) => {
                        const minEndDate = getMinEndDate(rowData.get('startDate'));
                        const { isOverdueFinish } = isTaskOverdue(rowData);
                        const endDate = cellData ? datepickerFormat(mongoToTrx(cellData)) : '';
                        const textEndDate = endDate ? isoToTrxFull(endDate) : 'MM/DD/YY';
                        const isStage = rowData.get('isStage');
                        const isExpanded = isStage && getIsExpanded(rowData);
                        const taskOrStageId = isStage ? rowData.get('_id') : rowData.get('id');
                        const lateEndDate = rowData.get('lateEndDate') || undefined;
                        const criticalEndDate = new Date(lateEndDate);
                        return (
                          <>
                            <DatepickerWithPortal
                              name="endDate"
                              date={endDate}
                              minDate={minEndDate}
                              isDefaultOpen={defaultEditField === 'endDate'}
                              onChange={date => controller.updateTaskOrStageEndDate({ date, rowData, isStage })}
                              onChangeRaw={({ target }) =>
                                controller.updateRawEndDate({ date: target.value, rowData, isStage })
                              }
                              isOverdue={isOverdueFinish}
                              dateText={textEndDate}
                              isEditing={isEditing(taskOrStageId)}
                              canEdit={!isStage && canEditDatesAndPredecessors}
                              portalRoot={calendarRef}
                              onClick={onEditFieldClick(taskOrStageId, 'endDate')}
                              dayClassName={date => criticalClass(date, criticalEndDate, endDate)}
                              renderDayContents={(day, date) => CriticalDay(day, date, criticalEndDate, endDate)}
                              calendarClassName="react-datepicker__critical"
                            >
                              <WarningCriticalMsg showMsg={showWarningCriticalMsg(endDate, criticalEndDate)} />
                            </DatepickerWithPortal>
                            {isExpanded && (
                              <EndDateList
                                tasks={rowData.get('tasks')}
                                canEdit={canEditDatesAndPredecessors}
                                calendarRef={calendarRef}
                                controller={controller}
                                getCellHeight={getInnerCellHeight}
                                onEditFieldClick={onEditFieldClick}
                                editingTaskId={editingRow}
                                defaultEditField={defaultEditField}
                              />
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Duration"
                      dataKey="durationDays"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.duration}
                      cellRenderer={renderCell(({ rowData, cellData }) => {
                        const isStage = rowData.get('isStage');
                        const isExpanded = isStage && getIsExpanded(rowData);
                        const taskOrStageId = isStage ? rowData.get('_id') : rowData.get('id');
                        const duration = isStage ? rowData.getIn(['statusData', 'durationWorking']) : cellData;
                        const isNegative = duration < 0;

                        if (isEditing(taskOrStageId)) {
                          return <TaskDurationInput controller={controller} task={rowData} />;
                        }

                        return (
                          <>
                            {isNegative ? (
                              <PopoverStickOnHover
                                popover={
                                  <div className="d-flex py-3 px-4">
                                    <span className="text-gray-400">
                                      Since the Key Finish Task is scheduled to start before the Key Start Task, the
                                      Stage Duration can't be calculated.
                                    </span>
                                  </div>
                                }
                                width={284}
                                delay={600}
                                placement="bottom"
                                className="d-inline w-75"
                              >
                                <FontAwesomeIcon
                                  icon="triangle-exclamation"
                                  className="text-yellow-100 cursor-pointer"
                                />
                              </PopoverStickOnHover>
                            ) : (
                              <div
                                onClick={isStage ? readOnlyClick : onEditFieldClick(taskOrStageId, 'durationDays')}
                                className={cn({ editable: !isStage && canEditDatesAndPredecessors })}
                              >
                                {`${duration !== null ? `${duration}d` : '-'}`}
                              </div>
                            )}
                            {isExpanded && (
                              <InnerDurationList
                                tasks={rowData.get('tasks')}
                                getCellHeight={getInnerCellHeight}
                                editingTaskId={editingRow}
                                onEditFieldClick={onEditFieldClick}
                                canEdit={canEditDatesAndPredecessors}
                                controller={controller}
                              />
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Dep."
                      dataKey="predecessors"
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.dep}
                      cellRenderer={renderCell(({ rowData, cellData }) => {
                        const isStage = rowData.get('isStage');
                        const isExpanded = isStage && getIsExpanded(rowData);
                        const predecessorsString = cellData
                          .map(p => {
                            const taskAux = tasks.find(t => t.get('id') === p.get('taskId'));
                            const depType = taskAux && p.get('dependencyType') !== 'FS' ? p.get('dependencyType') : '';
                            return taskAux ? depType + taskAux.get('rowIndex') : '';
                          })
                          .toJS()
                          .join();
                        return (
                          <>
                            {isStage ? (
                              <span>&nbsp;</span>
                            ) : (
                              <span
                                className={cn({ editable: canEditDatesAndPredecessors })}
                                onClick={() => canEditDatesAndPredecessors && changeDependencies(rowData)}
                              >
                                {predecessorsString && (
                                  <div className="d-flex align-items-center">
                                    <span className="d-inline-block text-truncate mr-1" style={{ maxWidth: '40px' }}>
                                      {predecessorsString}
                                    </span>
                                    {canEditDatesAndPredecessors && <FontAwesomeIcon icon="pen" />}
                                  </div>
                                )}
                                {!predecessorsString && canEditDatesAndPredecessors && (
                                  <FontAwesomeIcon icon="pen-to-square" />
                                )}
                                {rowData.get('missingReference') && (
                                  <div className="text-danger position-absolute" style={{ marginLeft: '-10px' }}>
                                    Missing
                                  </div>
                                )}
                              </span>
                            )}
                            {isExpanded && (
                              <PredecessorsList
                                tasks={state.get('tasks')}
                                innerTasks={rowData.get('tasks')}
                                getCellHeight={getInnerCellHeight}
                                changeDependencies={changeDependencies}
                                canEdit={canEditDatesAndPredecessors}
                              />
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label="Status"
                      dataKey="status"
                      className="h-100 mt-2 pt-3"
                      width={COL_WIDTHS.status}
                      cellRenderer={renderCell(({ rowData }) => {
                        const taskStatus = !!rowData.get('checkedIn') ? 'checked-in' : rowData.get('status');
                        const isStage = rowData.get('isStage');
                        const isExpanded = getIsExpanded(rowData);
                        return (
                          <>
                            <div className="d-flex flex-column align-items-center">
                              <ChipStatusDropdown
                                {...STATUS_MAP[taskStatus]}
                                notStarted={taskStatus !== NOT_STARTED}
                                progress={taskStatus !== IN_PROGRESS}
                                completed={taskStatus !== COMPLETED}
                                onSelect={status => controller.updateStatus({ task: rowData, status })}
                                disabled={isStage}
                                data-testid="dropdown-task-status"
                              />
                              {showDaysBehind && (
                                <span className="position-absolute mt-4 pt-2">
                                  <OnTime onTime={rowData.get('daysBehind')} hideOnSchedule />
                                </span>
                              )}
                            </div>
                            {isExpanded && (
                              <>
                                {isStage ? (
                                  <StatusList
                                    tasks={rowData.get('tasks')}
                                    getCellHeight={getInnerCellHeight}
                                    controller={controller}
                                    expandedRows={expandedRows.get('stages')}
                                  />
                                ) : (
                                  <SubTaskStatusList
                                    task={rowData}
                                    subTasks={rowData.get('children')}
                                    controller={controller}
                                  />
                                )}
                              </>
                            )}
                          </>
                        );
                      })}
                    />
                    <Column
                      label=""
                      dataKey=""
                      className="h-100 mt-4 pt-3"
                      width={COL_WIDTHS.action}
                      cellRenderer={renderCell(({ rowData, rowIndex }) => {
                        const isStage = rowData.get('isStage');
                        const isExpanded = isStage && getIsExpanded(rowData);
                        return (
                          <>
                            {!isStage && (
                              <TableRowDropDown remove onSelect={() => controller.deleteTask({ task: rowData })} />
                            )}
                            {isExpanded && (
                              <DropDownActionList
                                tasks={rowData.get('tasks')}
                                onSelect={task => controller.deleteTask({ task, rowIndex })}
                                getCellHeight={getInnerCellHeight}
                              />
                            )}
                          </>
                        );
                      })}
                    />
                  </Table>
                );
              }}
            </AutoSizer>
          </TableContainer>
        )}
      </InfiniteLoader>
    </>
  );
};

const TableContainer = styled.div`
  flex: 1 1 auto;
  border-bottom: solid 20px transparent;
  overflow-x: auto;
  ${scrollbarStyles}
`;
