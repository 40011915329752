import React from 'react';
import { buildersService } from 'services';

const emptyState = [{ _id: 'aaaaabbbbbcccccddddd', name: '' }];

export const useTemplates = (isMultiFamily = false) => {
  const [templates, setTemplates] = React.useState(emptyState);

  React.useEffect(() => {
    buildersService
      .getTemplates({}, { query: { complete: true } })
      .then(templates => {
        if (typeof isMultiFamily === undefined) return templates;
        return templates.filter(template => template.tasks.some(task => task.isMultiFamily === isMultiFamily));
      })
      .then(templates => templates.map(({ _id, name, stages }) => ({ _id, name, stages })))
      .then(setTemplates);
  }, [isMultiFamily]);

  return templates;
};
