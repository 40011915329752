import React from 'react';
import moment from 'moment';
import { Modal, Form, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { PrimaryButton } from '@tradetrax/web-common/lib/Button';
import Datepicker from '@tradetrax/web-common/lib/Datepicker';
import { datepickerFormat, mongoToTrx } from '@tradetrax/web-common/lib/utils';
import WarningCriticalMsg, {
  CriticalDay,
  criticalClass,
  showWarningCriticalMsg,
} from '@tradetrax/web-common/lib/CriticalPath/WarningCriticalMsg';

const getTaskDates = task => {
  const initialStartDate = datepickerFormat(mongoToTrx(task.get('startDate')));
  const initialFinishDate = datepickerFormat(mongoToTrx(task.get('expectedFinishDate')));

  return { initialStartDate, initialFinishDate };
};

export const OverdueStatusConfimationModal = ({ status, task, isTrade = false, close, accept }) => {
  const { initialStartDate, initialFinishDate } = getTaskDates(task);
  const isInProgress = status === 'in-progress';
  const title = isInProgress ? 'Confirm Start Date' : 'Confirm Dates';

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      startDate: initialStartDate,
      endDate: initialFinishDate,
    },
  });

  const watchStartDate = watch('startDate', initialStartDate);
  const watchFinishDate = watch('endDate', initialFinishDate);

  const isStartDateChanged = !moment(watchStartDate).isSame(moment(initialStartDate), 'day');
  const isFinishDateChanged = !moment(watchFinishDate).isSame(moment(initialFinishDate), 'day');

  if (!isInProgress && moment(watchStartDate).isAfter(moment(watchFinishDate))) {
    setValue('endDate', watchStartDate);
  }
  const isDateChanged = {
    ...(isStartDateChanged && { start: true }),
    ...(isFinishDateChanged && { finish: true }),
  };

  return (
    <Modal show={true} onHide={close} id="overdue-status-confirmation" dialogClassName="width-600">
      <OverdueStatusModalHeader title={title} taskName={task.get('name')} />
      <Form onSubmit={handleSubmit(form => accept({ form, isDateChanged }))}>
        <Modal.Body>
          <FormDates
            control={control}
            date={initialStartDate}
            name="startDate"
            label="Actual Start"
            task={task}
            isStartDate={true}
          />
          {!isInProgress && (
            <FormDates
              control={control}
              date={initialFinishDate}
              name="endDate"
              label="Actual Finish"
              task={task}
              isStartDate={false}
              minDate={watchStartDate}
            />
          )}
        </Modal.Body>
        <ModalActions
          close={close}
          isInProgress={isInProgress}
          isDateChanged={isStartDateChanged || isFinishDateChanged}
          isTrade={isTrade}
        />
      </Form>
    </Modal>
  );
};

const OverdueStatusModalHeader = ({ title, taskName }) => (
  <Modal.Header closeButton>
    <Modal.Title>
      {title}
      <h6 className="font-weight-bold">{`Task: ${taskName}`}</h6>
    </Modal.Title>
  </Modal.Header>
);

const ModalActions = ({ close, isInProgress, isDateChanged, isTrade }) => {
  let buttonText = 'Confirm Start Date';
  if (isTrade) {
    if (isInProgress) buttonText = isDateChanged ? 'Send to Builder' : 'Confirm Start Date';
    else buttonText = isDateChanged ? 'Send to Builder' : 'Confirm Dates';
  } else {
    if (isInProgress) buttonText = 'Confirm Start Date';
    else buttonText = 'Confirm Dates';
  }
  return (
    <Modal.Footer className="mt-3">
      <PrimaryButton variant="secondary" onClick={close}>
        Cancel
      </PrimaryButton>
      <PrimaryButton type="submit" className="ml-3">
        {buttonText}
      </PrimaryButton>
    </Modal.Footer>
  );
};

const FormDates = ({ control, date, name, label, minDate = null, task, isStartDate }) => {
  let taskDate, lastTaskDate;
  if (isStartDate) {
    taskDate = task.get('startDate') ? datepickerFormat(mongoToTrx(task.get('startDate'))) : '';
    lastTaskDate = task.get('lateStartDate') || undefined;
  } else {
    taskDate = task.get('expectedFinishDate') ? datepickerFormat(mongoToTrx(task.get('expectedFinishDate'))) : '';
    lastTaskDate = task.get('lateEndDate') || undefined;
  }
  const criticalDate = new Date(lastTaskDate);

  return (
    <Form.Group as={Col} md="6" controlId="formDate" className="d-flex flex-column px-0">
      <Form.Label>{label}</Form.Label>
      <Controller
        control={control}
        name={name}
        defaultValue={date}
        render={({ onChange, value, name }) => (
          <Datepicker
            name={name}
            selected={value}
            onChange={onChange}
            placeholderText=""
            minDate={minDate}
            dayClassName={date => criticalClass(date, criticalDate, value)}
            renderDayContents={(day, date) => CriticalDay(day, date, criticalDate, value)}
            calendarClassName="react-datepicker__critical"
          >
            <WarningCriticalMsg showMsg={showWarningCriticalMsg(value, criticalDate)} />
          </Datepicker>
        )}
      />
    </Form.Group>
  );
};
