import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OKDialog } from '@tradetrax/web-common/lib/Dialog/Dialog';

export const EventHandlers = ({ controller }) => {
  const onMultiFamilyChange = ({ rowData, modal, tasks }) => e => {
    const hasPredecessors = rowData.get('predecessors').size > 0;
    const descendings = tasks.filter(t => t.get('predecessors').find(p => p.get('taskId') === rowData.get('id')));
    const hasDescendings = descendings.size > 0;

    e.stopPropagation();

    const { checked } = e.target;
    if (hasPredecessors || hasDescendings) {
      e.preventDefault();
      const message = checked
        ? 'Remove the dependencies related to this Task so you can make it a Multi-Family Task.'
        : 'Remove the dependencies related to this Task so you can make it a Non-Multi-Family Task.';

      modal
        .open(OKDialog, {
          message,
          title: (
            <>
              <FontAwesomeIcon icon="circle-exclamation" className="text-danger" />
              Dependencies Removal
            </>
          ),
        })
        .then(({ isAccept }) => {
          if (isAccept) {
            // TODO: future version, remove all the dependencies and then modify the field value
            // controller.updateTaskField(rowData, 'isMultiFamily', checked);
          }
        });
    } else {
      controller.updateTaskField(rowData, 'isMultiFamily', checked);
    }
  };

  const onPreConstructionChange = rowData => e => {
    e.stopPropagation();
    const { checked } = e.target;
    controller.updateTaskField(rowData, 'isPreConstruction', checked);
  };

  return {
    onMultiFamilyChange,
    onPreConstructionChange,
  };
};
