import React from 'react';
import moment from 'moment';
import { Datepicker } from '@tradetrax/web-common';
import { datepickerFormat, mongoToTrx } from '@tradetrax/web-common/lib/utils';

const LabelInput = React.forwardRef(({ value, onClick, className }, ref) => (
  <span className={className} onClick={onClick} ref={ref}>
    {value}
  </span>
));

export function DatepickerLabel({ setPartialHoldDate, readOnly, name, ...props }) {
  const { diff, minDate, maxDate, selected } = getFormatedDates(props);
  const isReadOnly = readOnly || diff < 2;
  return (
    <Datepicker
      name={name}
      selected={selected || maxDate}
      maxDate={maxDate}
      minDate={minDate}
      dateFormat="MMM dd"
      onChange={date => setPartialHoldDate(date)}
      customInput={<LabelInput className={isReadOnly ? '' : 'clickable-text'} />}
      readOnly={isReadOnly}
    />
  );
}

const getFormatedDates = ({ fromDate, toDate, selected }) => {
  const minDate = datepickerFormat(mongoToTrx(moment(fromDate).add(1, 'd')));
  const maxDate = datepickerFormat(mongoToTrx(toDate));
  const date = datepickerFormat(mongoToTrx(selected));
  const diff = moment(toDate).diff(moment(fromDate), 'days');
  return {
    minDate,
    maxDate,
    selected: date,
    diff,
  };
};
