import { mongoToISO } from '../utils';
export const emptyActions = [
  {
    label: 'Action 1',
    action: () => {},
  },
  {
    label: 'Action 2',
    action: () => {},
  },
];

export function getOverdueActions({ controller }, getPermission) {
  const actions = [];
  if (getPermission('manage_update_request') !== 'no')
    actions.push({
      label: 'Send Update Request',
      action: controller.openBulkUpdateRequestModal,
    });
  if (getPermission('task_update_status') !== 'no')
    actions.push({
      label: 'Change Status',
      action: controller.openBulkUpdateStatusModal,
    });
  return actions;
}

export function getUpdateRequestActions({ controller }) {
  return [
    {
      label: 'Decline',
      action: controller.declineUpdateRequests,
    },
    {
      label: 'Send',
      action: controller.sendUpdateRequests,
    },
  ];
}

export function getOverdueActionsBuilders({ controller }, hasPermission) {
  const actions = [
    {
      label: 'Change Status',
      action: controller.openBulkUpdateStatusModal,
    },
  ];
  if (hasPermission('job_update_task_dependencies_and_dates'))
    actions.unshift({
      label: 'Change Date',
      action: controller.openBulkUpdateRequestModal,
    });

  return actions;
}

export function getUpdateRequestActionsBuilders({ controller }) {
  return [
    {
      label: 'Decline',
      action: tasks => controller.handleUpdateRequest(tasks, 'reject'),
    },
    {
      label: 'Accept',
      action: (tasks, checked) => controller.handleUpdateRequest(tasks, 'accept', checked),
    },
  ];
}

export function getUpcomingActions({ controller }) {
  return [
    {
      label: 'Change Date',
      action: controller.changeDate,
    },
  ];
}

export const formatTasks = (tasks, bulkChangeStatus = false) => {
  return tasks
    .map(task => {
      const formattedTask = {
        taskId: task.get('id'),
        jobId: task.getIn(['job', 'id']),
      };

      if (bulkChangeStatus) {
        formattedTask.startDate = mongoToISO(task.get('startDate'));

        if (bulkChangeStatus === 'completed') {
          formattedTask.endDate = mongoToISO(task.get('expectedFinishDate'));
        }
      }

      return formattedTask;
    })
    .toJS();
};
