import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { fromJS, List, Map } from 'immutable';
import * as yup from 'yup';
import { useAppContext } from 'app/App.context';
import { TasksGraph } from '@tradetrax/tasks-util';
import { mongoToTrx, IN_PROGRESS, calculateDiffDays } from '@tradetrax/web-common/lib/utils';
import { TasksAffectedModal } from 'app/workflows/TasksAffected';
import { StageScheduleImpactModal } from './ScheduleImpactModal';

const fieldSchema = yup.object().shape({
  taskId: yup.string().required(),
  dependencyType: yup.string(),
  lagTime: yup
    .number()
    .typeError('#')
    .required()
    .min(-99, '-99')
    .max(99, '99'),
});

const emptyPredecessor = Map({
  taskId: '',
  task: null,
  dependencyType: 'FS',
  lagTime: 0,
});

const emptyState = List([emptyPredecessor]);

export default function TaskDependenciesController({ controller, task, tasks, job }) {
  const { modal } = useAppContext();
  const taskId = task.get('id');
  const isStage = task.get('isStage');
  const isJobInProgress = job.get('status') === IN_PROGRESS;
  const predecessors = React.useMemo(() => {
    const isMultiFamily = task.get('isMultiFamilyFromTemplate');
    const filteredTasks = tasks.filter(task => task.get('isMultiFamilyFromTemplate') === isMultiFamily);
    const dependencies = task
      .get('predecessors')
      .map(dep =>
        dep.set(
          'task',
          filteredTasks.find(t => t.get('id') === dep.get('taskId'))
        )
      )
      .filter(d => d.get('task'));
    return dependencies.size === 0 ? emptyState.toJS() : dependencies.toJS();
  }, [task, tasks]);

  React.useEffect(() => {
    if (task.get('missingReference')) controller.removeMissingReference({ task });
  }, [task, taskId, controller]);

  const { register, handleSubmit, errors, control, setError, clearErrors } = useForm({
    mode: 'onChange',
    defaultValues: { predecessors },
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'predecessors' });
  const tasksGraph = new TasksGraph(tasks.toJS(), job.toJS());

  return {
    register,
    control,
    errors,
    clearErrors,
    onSubmit: handleSubmit(submitForm),
    fields,
    addRow,
    remove,
    validateCircularDependency,
    validateLagTime,
  };

  function addRow() {
    append(emptyPredecessor.toJS());
  }

  function submitForm(form) {
    if (isStage) {
      updateStagePredecessors(form);
    } else updateTaskPredecessors(form);
  }

  async function updateTaskPredecessors(form) {
    const predecessors = getPredecessors(form);
    const currentExpFinishDate = job.get('expectedFinishDate');
    const diffDays = isJobExpFinishDateImpacted(currentExpFinishDate, predecessors);
    if (diffDays !== 0 && isJobInProgress) {
      const { impactedTasksAndDates, affectedTaskMap } = tasksGraph.clone().setPredecessors(taskId, predecessors);
      const props = { isPredecessorUpdate: true, diffDays, impactedTasksAndDates, affectedTaskMap };
      const { isAccept, tasksHeld, taskIdRootCause, builderRootCause } = await modal.open(TasksAffectedModal, {
        task,
        tasksGraph,
        ...props,
      });
      if (isAccept) {
        controller.updateDependencies({
          task,
          predecessors: fromJS(predecessors),
          tasksHeld,
          taskIdRootCause,
          builderRootCause,
        });
        modal.close();
      }
    } else {
      controller.updateDependencies({ task, predecessors: fromJS(predecessors) });
      modal.close();
    }
  }

  async function updateStagePredecessors(form) {
    const predecessors = getPredecessors(form);
    const hasPredecessors = !!predecessors.length;
    const currentExpFinishDate = job.get('expectedFinishDate');
    const diffDays = isJobExpFinishDateImpacted(currentExpFinishDate, predecessors);
    if (diffDays !== 0 && isJobInProgress) {
      const { isAccept } = await modal.open(StageScheduleImpactModal, {
        jobDelay: diffDays,
        hasPredecessors,
        stage: task,
      });
      if (!isAccept) return;
    }
    controller.updateStageDependencies({ stage: task, predecessors: fromJS(predecessors) });
    modal.close();
  }

  function getPredecessors(form) {
    let predecessors = form.predecessors?.map(predecessor => {
      return {
        ...predecessor,
        taskId: parseInt(predecessor.taskId, 10),
        lagTime: parseInt(predecessor.lagTime, 10),
      };
    });
    if (!predecessors) predecessors = [];
    return predecessors;
  }

  function validateCircularDependency(predecessorId) {
    return isNaN(parseInt(predecessorId, 10))
      ? true
      : !tasksGraph.hasCycleNewPredecessor(task.get('id'), parseInt(predecessorId, 10));
  }

  async function validateLagTime(lagTime, index) {
    fieldSchema
      .validateAt('lagTime', { lagTime })
      .then(() => {
        clearErrors(`predecessors[${index}].lagTime`);
      })
      .catch(response => {
        const { type, errors } = response;
        const error = errors && errors[0];
        setError(`predecessors[${index}].lagTime]`, {
          type,
          message: error,
        });
      });
  }

  function isJobExpFinishDateImpacted(currentExpFinishDate, predecessors = []) {
    const taskId = task.get('id') || task.get('stageTaskId');
    const { newEndDate } = tasksGraph.clone().setPredecessors(taskId, predecessors);

    const newExpFinishDate = mongoToTrx(newEndDate) || currentExpFinishDate;
    const diffDays = calculateDiffDays(currentExpFinishDate, newExpFinishDate);

    return diffDays;
  }
}
