import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { List, Map } from 'immutable';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useAppContext } from 'app/App.context';

const rowSchema = yup.object().shape({
  taskId: yup
    .string()
    .required()
    .nullable(false),
  dependencyType: yup.string(),
  lagTime: yup
    .number()
    .typeError('#')
    .required()
    .min(-99, '-99')
    .max(99, '99'),
});

const schema = yup.object().shape({
  predecessors: yup.array().of(rowSchema),
});

const emptyPredecessor = Map({
  taskId: null,
  task: null,
  dependencyType: 'FS',
  lagTime: 0,
});

const emptyState = List([emptyPredecessor]);

export default function TaskDependenciesController({ controller, taskOrStage, tasks, template }) {
  const { modal } = useAppContext();
  const predecessors = React.useMemo(() => {
    const isMultiFamily = taskOrStage.get('isMultiFamily');
    const filteredTasks = tasks.filter(task => task.get('isMultiFamily') === isMultiFamily);
    const dependencies = taskOrStage
      .get('predecessors')
      .map(dep =>
        dep.set(
          'task',
          filteredTasks.find(t => t.get('id') === dep.get('taskId'))
        )
      )
      .filter(d => d.get('task'));
    return dependencies.size === 0 ? emptyState.toJS() : dependencies.toJS();
  }, [taskOrStage, tasks]);

  const { register, handleSubmit, errors, control, setError, clearErrors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { predecessors },
  });

  React.useEffect(() => {
    if (taskOrStage.get('missingReference')) controller.removeMissingReference(taskOrStage, template.get('_id'));
  }, [taskOrStage, template, controller]);

  const { fields, append, remove } = useFieldArray({ control, name: 'predecessors' });

  return {
    register,
    onSubmit: handleSubmit(updateTemplate),
    errors,
    setError,
    clearErrors,
    control,
    fields,
    append,
    remove,
    addRow,
    validateLagTime,
    validateTask,
  };

  function addRow() {
    append(emptyPredecessor.toJS());
  }

  function updateTemplate(form) {
    let predecessors = form.predecessors?.map(predecessor => {
      return {
        ...predecessor,
        taskId: parseInt(predecessor.taskId, 10),
        lagTime: parseInt(predecessor.lagTime, 10),
      };
    });

    if (!predecessors) predecessors = [];

    controller
      .updateDependencies(predecessors, taskOrStage)
      .then(() => modal.close())
      .catch(error => {
        const { path } = error;
        if (error.type === 'dependency_cycle') {
          const taskId = path[path.indexOf(taskOrStage.get('id').toString()) + 1];
          const index = predecessors.indexOf(predecessors.find(p => p.taskId === parseInt(taskId, 10)));
          setError(`predecessors[${index}].taskId`, { type: 'validate', message: 'Circular Dependency' });
        }
      });
  }

  function validateTask(taskId, index) {
    rowSchema
      .validateAt('taskId', { taskId })
      .then(() => {
        clearErrors(`predecessors[${index}].taskId`);
      })
      .catch(response => {
        const { type, errors } = response;
        const error = errors && errors[0];
        setError(`predecessors[${index}].taskId]`, {
          type,
          message: error,
        });
      });
  }

  async function validateLagTime(lagTime, index) {
    rowSchema
      .validateAt('lagTime', { lagTime })
      .then(() => {
        clearErrors(`predecessors[${index}].lagTime`);
      })
      .catch(response => {
        const { type, errors } = response;
        const error = errors && errors[0];
        setError(`predecessors[${index}].lagTime]`, {
          type,
          message: error,
        });
      });
  }
}
