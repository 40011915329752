import React from 'react';
import { PopoverIcon } from './Icon.popover';

export const PreConstructionIcon = ({ task, isPreConstruction }) => {
  if (task) {
    isPreConstruction = task.get('isPreConstruction');
  }

  if (isPreConstruction !== true) return null;

  return (
    <PopoverIcon icon="helmet-safety">
      Pre-Construction Task. It will
      <br />
      not affect Job Exp. Start date.
    </PopoverIcon>
  );
};
