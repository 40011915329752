import React, { useState } from 'react';
import cn from 'classnames';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { List } from 'immutable';
import { UsersTags, IconButton } from '@tradetrax/web-common';
import { mongoToTrx, plural, COMPLETED } from '@tradetrax/web-common/lib/utils';
import { TargetDate, EditableField, emptyStringToNull } from '@tradetrax/web-common/lib/EditableField';
import { JobHeaderDataContainer } from '@tradetrax/web-common/lib/Jobs/index';

const MAPS = {
  'not-started': [
    ['es', 'ef', 'ect'],
    ['ts', 'tf', 'tct'],
    ['as', 'af', 'act'],
  ],
  'in-progress': [
    ['as', 'tf', 'ect'],
    ['es', 'ef', 'tct'],
    ['ts', 'af', 'act'],
  ],
  completed: [
    ['as', 'af', 'act'],
    ['ts', 'tf', 'tct'],
    ['es', 'ef', 'ect'],
  ],
};

const lotNumberSchema = yup.object().shape({
  lotNumber: yup
    .string()
    .matches(/^[a-zA-Z0-9\s-]+$/, 'Character not allowed')
    .max('50', 'Max 50 characters')
    .notRequired()
    .transform(emptyStringToNull)
    .nullable(),
});

const squareFootageSchema = yup.object().shape({
  squareFootage: yup
    .number()
    .integer()
    .min('0', '0')
    .max(9999999999, '9999999999')
    .transform(emptyStringToNull)
    .nullable(),
});

const targetCycleTimeSchema = yup.object().shape({
  targetCycleTime: yup
    .number()
    .integer()
    .min('0', '0')
    .max(9999, '9999')
    .transform(emptyStringToNull)
    .nullable(),
});

const TargetCycleTimeInput = ({ jobStatus, job, update }) => {
  if (jobStatus === 'not-started')
    return (
      <EditableField
        title="Target Cycle Time"
        editableProps={{
          schema: targetCycleTimeSchema,
          value: job.getIn(['statusData', 'targetCycleTime']) || null,
          update: update,
          field: 'targetCycleTime',
          type: 'number',
          preventChars: true,
          showDaysLabel: true,
        }}
      />
    );

  return <TargetDate title="Target Cycle Time" date={plural.day(job.getIn(['statusData', 'targetCycleTime']))} />;
};

export const JobDatesHeader = ({ job, update, ...props }) => {
  const [collapsed, setCollapsed] = useState(true);
  const date = (...path) => {
    const value = job.getIn(path);
    return value ? mongoToTrx(value) : null;
  };
  const days = (...path) => {
    const value = parseInt(job.getIn(path));
    return isNaN(value) ? null : plural.day(value);
  };
  const templateName = job.get('templateName');
  const supers = job.get('supers') || List();
  const schedulers = job.get('schedulers') || List();
  const jobStatus = job.get('status');
  const map = MAPS[jobStatus || 'not-started'];
  const dates = {
    es: ['Expected Start', date('startDate')],
    ef: ['Expected Finish', date('expectedFinishDate')],
    as: ['Actual Start', date('statusData', 'actualStart')],
    af: ['Actual Finish', date('statusData', 'actualFinish')],
    tf: ['Target Finish', date('statusData', 'targetFinish')],
    ts: ['Target Start', date('statusData', 'targetStart')],
    ect: ['Exp. Cycle Time', days('statusData', 'cycleTime')],
    tct: ['Original Exp. Cycle Time', days('statusData', 'originalExpectedCycleTime')],
    act: ['Actual Cycle Time', jobStatus === COMPLETED ? days('statusData', 'cycleTime') : null],
  };
  const Dates = ({ row }) =>
    row.map(key => {
      const [title, date] = dates[key];
      return <TargetDate title={title} date={date} key={key} />;
    });

  const MultiFamilyData = () => {
    const building = job.get('building');
    if (!building) return null;

    return (
      <div className="d-flex flex-column mr-5">
        <small className="text-muted">Building</small>
        <span className="w-auto text-truncate">{building.get('name')}</span>
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-between ">
      <JobHeaderDataContainer className="d-flex flex-wrap d-flex  mt-2 " collapsed={collapsed} isBuilder>
        <Dates row={map[0]} />
        <TargetCycleTimeInput jobStatus={jobStatus} job={job} update={update} />
        <EditableField
          title="LOT #"
          truncate
          editableProps={{
            schema: lotNumberSchema,
            value: job.get('lotNumber') || null,
            update: update,
            field: 'lotNumber',
          }}
          data-testid="lotNumber"
          collapsed={collapsed}
        />
        <Dates row={map[1]} />

        <UsersTags
          className="mr-5 w-9rem"
          maxTags={2}
          title="Super Assigned"
          users={supers.toArray().slice(0, 3)}
          onShowMore={props.onShowMoreUsers}
        />
        <EditableField
          title="Square Ft."
          editableProps={{
            schema: squareFootageSchema,
            value: job.get('squareFootage') || null,
            update: update,
            field: 'squareFootage',
            type: 'number',
            preventChars: true,
          }}
          data-testid="squareFootage"
        />
        <Dates row={map[2]} />

        <UsersTags
          className="mr-5"
          maxTags={2}
          title="Scheduler Assigned"
          users={schedulers.toArray().slice(0, 3)}
          onShowMore={props.onShowMoreUsers}
        />
        <UsersTags
          className="mr-5"
          title="Created by"
          users={[{ name: job.get('createdByUserName'), status: job.get('createdByUserStatus') }]}
        />
        <div className="d-flex flex-column mr-5">
          <small className="text-muted">Template</small>
          {props.isTemplateActive ? (
            <Link className="" to={props.templateLink}>
              {templateName || '- - -'}
            </Link>
          ) : (
            <span className="text-gray-200 w-auto text-truncate">{templateName || '- - -'}</span>
          )}
        </div>
        <MultiFamilyData />
      </JobHeaderDataContainer>
      <div>
        <div className="d-flex flex-row ml-auto">
          <IconButton
            icon={`angles-${collapsed ? 'down' : 'up'}`}
            className={cn({ active: !collapsed })}
            onClick={() => setCollapsed(!collapsed)}
          />
          <IconButton
            icon="gear"
            className={cn('ml-3', { active: props.isSettingsOpen })}
            onClick={() => props.setOpenSettings(!props.isSettingsOpen)}
          />
        </div>
      </div>
    </div>
  );
};
