import { fromJS } from 'immutable';
import { useController, markAsSync } from '../../../useController';
import { COMPLETED, IN_PROGRESS } from '../../../utils';

export const NO_PERMISSION = 'NO_PERMISSION';
export const MISSED_START = 'Start';
export const MISSED_FINISH = 'Finish';

const actions = { updateStatus, setFinishView, setStartView, warningNext, updateTaskDate };
const statusTasks = {
  tasks: [],
  status: null,
};
const emptyState = fromJS({
  [MISSED_START]: statusTasks,
  [MISSED_FINISH]: statusTasks,
  withoutPermission: [],
});

export function UpdateStatusModalContext(tasks, isTaskAssigned) {
  const initState = mapTasks(tasks, isTaskAssigned);
  const { isTwoSteps, initView, isAllWithoutPermission } = getInitView(initState);
  const [state, controller] = useController(
    actions,
    initState
      .set('view', initView)
      .set('isTwoSteps', isTwoSteps)
      .set('isAllWithoutPermission', isAllWithoutPermission)
  );

  return { state, controller };
}

const mapTasks = (tasks, isTaskAssigned) => {
  return tasks
    .reduce((acc, task) => {
      const isCheckedIn = !!task.get('checkedIn');
      const status = isCheckedIn ? task.get('originalStatus') : task.get('status');
      const field = status === IN_PROGRESS ? MISSED_FINISH : MISSED_START;
      if (!isTaskAssigned(task))
        return acc.update('withoutPermission', withoutPermission => withoutPermission.push(task));
      return acc.updateIn([field, 'tasks'], tasks => tasks.push(task));
    }, emptyState)
    .setIn([MISSED_START, 'status'], IN_PROGRESS)
    .setIn([MISSED_FINISH, 'status'], COMPLETED);
};

const getInitView = state => {
  const startTasks = state.getIn([MISSED_START, 'tasks']);
  const finishTasks = state.getIn([MISSED_FINISH, 'tasks']);
  const hasTaskNoPermission = state.get('withoutPermission').size;
  const hasOnlyFinish = finishTasks.size && !startTasks.size;
  const isTwoSteps = !!startTasks.size && !!finishTasks.size;
  const initView = hasTaskNoPermission ? NO_PERMISSION : hasOnlyFinish ? MISSED_FINISH : MISSED_START;
  const isAllWithoutPermission = hasTaskNoPermission && !startTasks.size && !finishTasks.size;

  return { isTwoSteps, initView, isAllWithoutPermission };
};

markAsSync(updateStatus);
function updateStatus(state, status) {
  const view = state.get('view');
  return state.setIn([view, 'status'], status);
}

markAsSync(setFinishView);
function setFinishView(state) {
  return state.set('view', MISSED_FINISH);
}

markAsSync(setStartView);
function setStartView(state) {
  return state.set('view', MISSED_START);
}

markAsSync(warningNext);
function warningNext(state) {
  const hasMissedStart = !!state.getIn([MISSED_START, 'tasks']).size;
  if (hasMissedStart) return state.set('view', MISSED_START);
  return state.set('view', MISSED_FINISH);
}

markAsSync(updateTaskDate);
function updateTaskDate(state, newDate, index, typeDate) {
  const view = state.get('view');
  return state.setIn([view, 'tasks', index, typeDate], newDate);
}
