import { List } from 'immutable';
import { markAsSideEffect, markAsSync } from '../useController';
import { formatISO, getUserDomain, hasPermissionForAssignedTask } from '../utils';
import { UpdateRequestModal } from './Modals/Overdue/UpdateRequestModal';
import { UpdateStatusModal } from './Modals/Overdue/UpdateStatusModal';
import { formatTasks } from './common';

markAsSync(toggleCheckRequest);
export function toggleCheckRequest(state, task, checked) {
  const isFirstChecked = checked && state.get('selectedTasks').size === 0;
  if (isFirstChecked) this.events.emit('bulkActions:disableOvedue');
  const isAllUnchecked = !checked && state.get('selectedTasks').size === 1;
  if (isAllUnchecked) this.events.emit('bulkActions:enableSections');

  return state.update('selectedTasks', selectedTasks => {
    if (checked) return selectedTasks.push(task);
    return selectedTasks.filter(
      selected =>
        !(task.get('id') === selected.get('id') && task.getIn(['job', 'id']) === selected.getIn(['job', 'id']))
    );
  });
}

markAsSync(toggleCheckOverdue);
export function toggleCheckOverdue(state, task, checked) {
  const isFirstChecked = checked && state.get('selectedTasks').size === 0;
  if (isFirstChecked) this.events.emit('bulkActions:disableRequests');
  const isAllUnchecked = !checked && state.get('selectedTasks').size === 1;
  if (isAllUnchecked) this.events.emit('bulkActions:enableSections');

  return state.update('selectedTasks', selectedTasks => {
    if (checked) return selectedTasks.push(task);
    return selectedTasks.filter(
      selected =>
        !(task.get('id') === selected.get('id') && task.getIn(['job', 'id']) === selected.getIn(['job', 'id']))
    );
  });
}

markAsSync(clearSelectedTasks);
export function clearSelectedTasks(state) {
  this.events.emit('bulkActions:enableSections');
  return state.set('selectedTasks', List());
}

markAsSideEffect(openBulkUpdateRequestModal);
export async function openBulkUpdateRequestModal(tasks) {
  const { user } = this.appState.toObject();
  const { isBuilder } = getUserDomain(user);
  const isTaskAssigned = task =>
    hasPermissionForAssignedTask('manage_update_request', this.hasPermission, task, user.get('_id'));
  const { isAccept, form } = await this.modal.open(UpdateRequestModal, {
    tasks,
    isBuilder,
    isTaskAssigned,
  });
  if (!isAccept) return;

  const { start, finish } = form;
  const promises = [];

  if (isBuilder) {
    if (start.newStartDate) promises.push(bulkOverdueTaskDate.call(this, start, 'startDate', start.dueToWeather));
    if (finish.newEndDate) promises.push(bulkOverdueTaskDate.call(this, finish, 'endDate', finish.dueToWeather));
  } else {
    if (start.newStartDate) {
      const { tasks, newStartDate } = start;
      const sdcr = tasks.filter(task => task.get('startDateConfirmationRequest'));
      const rest = tasks.filter(task => !task.get('startDateConfirmationRequest'));
      if (sdcr.size)
        promises.push(
          bulkSendUpdateRquest.call(
            this,
            { newStartDate, tasks: sdcr },
            'new-start-date-request',
            start.isDueToWeather,
            true
          )
        );
      if (rest.size)
        promises.push(
          bulkSendUpdateRquest.call(this, { newStartDate, tasks: rest }, 'new-start-date-request', start.isDueToWeather)
        );
    }
    if (finish.newEndDate)
      promises.push(bulkSendUpdateRquest.call(this, finish, 'new-expected-end-date', finish.isDueToWeather));
  }

  Promise.all(promises)
    .then(() =>
      this.parentContext.controller.readTasksOverdue().then(data => {
        if (isBuilder) {
          const tasksOverdue = data.get('tasksOverdue');
          return tasksOverdue.filter(overdueTask => tasks.some(task => task.get('key') === overdueTask.get('key')));
        }
      })
    )
    .then(coincidentTasks => {
      this.alert.success({ message: isBuilder ? 'Tasks successfully updated.' : 'Update request successfully sent.' });
      if (isBuilder) {
        this.controller.dispatch([state => state.set('selectedTasks', coincidentTasks)]);
      } else {
        this.controller.clearSelectedTasks();
      }
    })
    .catch(error =>
      this.alert.error({
        message: isBuilder
          ? 'There was a problem updating this Task. Please try again.'
          : 'There was a problem sending this update request. Please try again.',
      })
    );
}

markAsSideEffect(handleUpdateRequest);
export async function handleUpdateRequest(tasks, bulkServiceAction, checked) {
  const startDateReqArray = filterTasksByRequestType(tasks, 'new-start-date-request');
  const endDateReqArray = filterTasksByRequestType(tasks, 'new-expected-end-date');
  const promises = [];

  if (startDateReqArray.size) {
    promises.push(
      this.bulkService[`${bulkServiceAction}StartDateRequestMany`]({
        tasks: formatTasks(startDateReqArray),
        ...(checked && { body: { reasons: ['weather'] } }),
      })
    );
  }

  if (endDateReqArray.size) {
    promises.push(
      this.bulkService[`${bulkServiceAction}FinishDateRequestMany`]({
        tasks: formatTasks(endDateReqArray),
        ...(checked && { body: { reasons: ['weather'] } }),
      })
    );
  }

  Promise.all(promises)
    .then(() => {
      const parentTasks = this.parentContext.state.get('tasksUpdateRequest');
      const filteredTasksArrayAfterSend = filterUniqueTasks(parentTasks, tasks);

      this.parentContext.controller.dispatch([state => state.set('tasksUpdateRequest', filteredTasksArrayAfterSend)]);

      const successMessage =
        bulkServiceAction === 'reject'
          ? 'Update request declined. Trade user will be notified.'
          : 'Update request successfully accepted. Trade user will be notified.';

      this.alert.success({ message: successMessage });
      this.controller.clearSelectedTasks();
    })
    .catch(() => {
      const errorMessage =
        bulkServiceAction === 'reject'
          ? 'There was a problem declining this update request from the Trade. Please try again.'
          : 'There was a problem accepting this update request from the Trade. Please try again.';
      this.alert.error({ message: errorMessage });
    });
}

markAsSideEffect(bulkSendUpdateRquest);
function bulkSendUpdateRquest({ newStartDate, newEndDate, tasks }, requestType, isDueToWeather, isSDCR = false) {
  const payload = { query: { requestType } };
  payload.tasks = formatTasks(tasks);
  if (newStartDate) payload.body = { newStartDate: formatISO(newStartDate) };
  else if (newEndDate) payload.body = { newEndDate: formatISO(newEndDate) };
  if (isSDCR) payload.body.startDateConfirmation = true;
  if (isDueToWeather) payload.body.reasons = ['weather'];

  return this.bulkService.sendMultipleRequests(payload, { query: { requestType } }).catch(error => {
    throw error;
  });
}

const isSameTask = (task1, task2) => {
  return task1.get('id') === task2.get('id') && task1.getIn(['job', 'id']) === task2.getIn(['job', 'id']);
};
const filterUniqueTasks = (parentTasks, tasks) => {
  return parentTasks.filter(task => !tasks.some(rTask => isSameTask(rTask, task)));
};
const filterTasksByRequestType = (tasks, requestType) =>
  tasks.filter(task => task.getIn(['changeRequest', 'type']) === requestType);

markAsSideEffect(openBulkUpdateStatusModal);
export async function openBulkUpdateStatusModal(tasks) {
  const { user } = this.appState.toObject();
  const { isBuilder } = getUserDomain(user);
  const isTaskAssigned = task =>
    hasPermissionForAssignedTask('task_update_status', this.hasPermission, task, user.get('_id'));
  const canEditUpdateRequest = task =>
    hasPermissionForAssignedTask('manage_update_request', this.hasPermission, task, user.get('_id'));

  const { isAccept, form } = await this.modal.open(UpdateStatusModal, {
    tasks,
    isTaskAssigned,
    canEditUpdateRequest,
    isBuilder,
  });
  if (!isAccept) return;

  const missedStartTasks = form.getIn(['Start', 'tasks']);
  const missedFinishTasks = form.getIn(['Finish', 'tasks']);
  const promises = [];

  const missedStartTasksWithPermissions = missedStartTasks.filter(task => canEditUpdateRequest(task));
  const missedStartTasksWithoutPermissions = missedStartTasks.filter(task => !canEditUpdateRequest(task));

  const missedFinishTasksWithPermissions = missedFinishTasks.filter(task => canEditUpdateRequest(task));
  const missedFinishTasksWithoutPermissions = missedFinishTasks.filter(task => !canEditUpdateRequest(task));

  if (missedStartTasksWithPermissions.size)
    promises.push(bulkUpdateTaskStatus.call(this, missedStartTasksWithPermissions, form.getIn(['Start', 'status'])));
  if (missedFinishTasksWithPermissions.size)
    promises.push(bulkUpdateTaskStatus.call(this, missedFinishTasksWithPermissions, form.getIn(['Finish', 'status'])));

  if (missedStartTasksWithoutPermissions.size)
    promises.push(bulkUpdateTaskOnlyStatus.call(this, form.getIn(['Start', 'status'])));
  if (missedFinishTasksWithoutPermissions.size)
    promises.push(bulkUpdateTaskOnlyStatus.call(this, form.getIn(['Finish', 'status'])));

  Promise.all(promises)
    .then(() =>
      this.parentContext.controller.readTasksOverdue().then(data => {
        if (isBuilder) {
          const tasksOverdue = data.get('tasksOverdue');
          return tasksOverdue.filter(overdueTask => tasks.some(task => task.get('key') === overdueTask.get('key')));
        }
      })
    )
    .then(coincidentTasks => {
      this.alert.success({ message: 'Tasks successfully updated.' });
      if (isBuilder) {
        this.controller.dispatch([state => state.set('selectedTasks', coincidentTasks)]);
      } else {
        this.controller.clearSelectedTasks();
      }
    })
    .catch(error => this.alert.error({ message: 'There was a problem updating these Tasks. Please try again.' }));
}

function bulkUpdateTaskStatus(tasks, status) {
  const { user } = this.appState.toObject();
  const { isBuilder } = getUserDomain(user);
  const payload = { body: { status } };
  payload.tasks = formatTasks(tasks, !isBuilder ? status : null);

  const bulkUpdateFunction = isBuilder ? this.bulkService.bulkUpdateTasks : this.bulkService.bulkUpdateOverdueTask;
  return bulkUpdateFunction(payload).catch(error => {
    throw error;
  });
}

function bulkUpdateTaskOnlyStatus(status) {
  const payload = { body: { status } };

  return this.bulkService.bulkUpdateTask(payload).catch(error => {
    throw error;
  });
}

function bulkOverdueTaskDate({ newStartDate, newEndDate, tasks, isDueToWeather }, requestType) {
  const payload = {};
  payload.tasks = formatTasks(tasks);
  if (newStartDate) payload.body = { [requestType]: formatISO(newStartDate) };
  else if (newEndDate) payload.body = { [requestType]: formatISO(newEndDate) };

  const query = isDueToWeather ? { reasons: ['weather'] } : {};

  return this.bulkService.bulkUpdateTasks(payload, { query }).catch(error => {
    throw error;
  });
}
