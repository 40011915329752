import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { colors } from '../../../theme';
import { datepickerFormat, mongoToTrx } from '../../../utils';
import WarningCriticalMsg, {
  CriticalDay,
  criticalClass,
  showWarningCriticalMsg,
} from '../../../CriticalPath/WarningCriticalMsg';
import Datepicker from '../../../Datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function TaskListChangeStatus({
  tasks,
  isCompletedChecked,
  controller,
  canEditUpdateRequest,
  isBuilder = false,
}) {
  const hasTaskWithoutPermissions = tasks.some(task => !canEditUpdateRequest(task));

  return (
    <>
      {hasTaskWithoutPermissions && (
        <div className="d-flex font-size-14">
          <FontAwesomeIcon icon="triangle-exclamation" className="text-yellow-100 mt-1 mr-1" />
          <div className="d-flex flex-column">
            <span>You may not have permissions to change the dates of some Tasks.</span>
            <span>Please, contact your Super.</span>
          </div>
        </div>
      )}
      <div className="text-gray-400 font-size-14 mt-3 mb-2 d-flex">
        <div
          className="d-flex flex-row justify-content-between w-100 pb-2"
          style={{ borderBottom: `1px solid ${colors.gray500}` }}
        >
          <div className="d-flex flex-row justify-content-between w-100">
            <div>Tasks</div>
          </div>
          {!isBuilder && (
            <div className="d-flex flex-row justify-content-end w-100">
              <div>Actual Start</div>
              {isCompletedChecked && <div className="ml-5">Actual Finish</div>}
            </div>
          )}
        </div>
      </div>
      <Container>
        {tasks.map((task, index) => (
          <TaskRow
            task={task}
            taskIndex={index}
            canEditUpdateRequest={canEditUpdateRequest}
            controller={controller}
            isCompletedChecked={isCompletedChecked}
            isBuilder={isBuilder}
            key={index}
          />
        ))}
      </Container>
    </>
  );
}

const TaskRow = ({ task, taskIndex, canEditUpdateRequest, controller, isCompletedChecked, isBuilder = false }) => {
  const startDate = task.get('startDate') ? datepickerFormat(mongoToTrx(task.get('startDate'))) : '';
  const lateStartDate = task.get('lateStartDate') || undefined;
  const criticalStartDate = new Date(lateStartDate);
  const isStartMissed = task.get('overdue') === 'start';

  const endDate = task.get('expectedFinishDate') ? datepickerFormat(mongoToTrx(task.get('expectedFinishDate'))) : '';
  const isFinishMissed = task.get('overdue') === 'finish';
  const lateEndDate = task.get('lateEndDate') || undefined;

  const criticalEndDate = new Date(lateEndDate);
  const canEditUpdateRequestPermission = canEditUpdateRequest(task);

  return (
    <TaskRowContainer key={task.get('key')} className="py-1" style={{ borderBottom: `1px solid ${colors.gray500}` }}>
      <div className="font-weight-bold w-50">
        <div className="d-inline-block text-truncate w-100">{task.get('name') || 'Unnamed Task'}</div>
      </div>
      <div className={cn('flex-row', { 'd-none': isBuilder, 'd-flex ': !isBuilder })}>
        <Datepicker
          name="startDate"
          selected={startDate}
          onChange={date => {
            const inputDate = new Date(date);
            const formattedDate = new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60000).toISOString();
            controller.updateTaskDate(formattedDate, taskIndex, 'startDate');
            if (date > endDate) {
              controller.updateTaskDate(formattedDate, taskIndex, 'expectedFinishDate');
            }
          }}
          placeholderText="MM/DD/YY"
          isInvalid={isStartMissed}
          dayClassName={date => criticalClass(date, criticalStartDate, startDate)}
          renderDayContents={(day, date) => CriticalDay(day, date, criticalStartDate, startDate)}
          calendarClassName="react-datepicker__critical"
          customInput={<DateText canEditUpdateRequest={canEditUpdateRequestPermission} />}
        >
          <WarningCriticalMsg showMsg={showWarningCriticalMsg(startDate, criticalStartDate)} />
        </Datepicker>

        {isCompletedChecked && (
          <div className="ml-5">
            <Datepicker
              name="endDate"
              selected={endDate}
              onChange={date => {
                const inputDate = new Date(date);
                const formattedDate = new Date(
                  inputDate.getTime() - inputDate.getTimezoneOffset() * 60000
                ).toISOString();
                controller.updateTaskDate(formattedDate, taskIndex, 'expectedFinishDate');
              }}
              placeholderText="MM/DD/YY"
              isInvalid={isFinishMissed}
              minDate={startDate}
              dayClassName={date => criticalClass(date, criticalEndDate, endDate)}
              renderDayContents={(day, date) => CriticalDay(day, date, criticalEndDate, endDate)}
              calendarClassName="react-datepicker__critical"
              customInput={<DateText canEditUpdateRequest={canEditUpdateRequestPermission} />}
            >
              <WarningCriticalMsg showMsg={showWarningCriticalMsg(endDate, criticalEndDate)} />
            </Datepicker>
          </div>
        )}
      </div>
    </TaskRowContainer>
  );
};

const DateText = ({ value, onClick, canEditUpdateRequest }) => {
  return (
    <DateTextContainer
      onClick={canEditUpdateRequest ? onClick : e => e.preventDefault()}
      canEditUpdateRequest={canEditUpdateRequest}
    >
      {value || 'MM/DD/YY'}
    </DateTextContainer>
  );
};

const Container = styled.div`
  overflow-y: scroll;
  max-height: 260px;
`;

const TaskRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DateTextContainer = styled.span`
  ${props => `
    color: ${props.canEditUpdateRequest ? colors.blue300 : 'black'};
    &:hover {
      text-decoration: ${props.canEditUpdateRequest ? 'underline' : 'auto'};
      cursor: ${props.canEditUpdateRequest ? 'pointer' : 'auto'};;
    }
  `}
  display: inline-block;
`;
